import React, { useState, useRef, useEffect } from "react";
import { Rnd } from "react-rnd";
import * as pdfjsLib from "pdfjs-dist";
import Draggable from "react-draggable";
import ReactToPrint from "react-to-print";
import { debounce } from "lodash";
import { FiUploadCloud } from "react-icons/fi";
import { CgFormatItalic } from "react-icons/cg";
import { AiOutlineDelete } from "react-icons/ai";
import { BsPlusCircleFill } from "react-icons/bs";
import { MdOutlineFormatUnderlined } from "react-icons/md";
import { RxCrossCircled, RxFontBold } from "react-icons/rx";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { IoDuplicateOutline, IoText } from "react-icons/io5";
import { MdCloudUpload, MdRotateLeft } from "react-icons/md";
import { CiTextAlignLeft, CiTextAlignRight, CiTextAlignCenter, CiTextAlignJustify } from "react-icons/ci";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";
import { useParams } from "react-router";
import Swal from "sweetalert2";
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const ACRF = () => {
  const { studyId } = useParams()
  const pdfDownloadRef = useRef();
  const fileInputRef = useRef(null);
  const pdfContainerRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [textBoxes, setTextBoxes] = useState([]);
  const [currentPdf, setCurrentPdf] = useState(null);
  const [isAddingTextBox, setIsAddingTextBox] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pdfPreviews, setPdfPreviews] = useState({});

  const getStudyCDISCAnnotation = async () => {
    setLoader(true)
    let data = await cdiscServices.getStudyCDISCAnnotation(studyId)
    setFiles(data?.data?.files || [])
    setTextBoxes(data?.data?.textBoxes || [])
    setPdfPreviews(data?.data?.pdfPreviews || {})
    setLoader(false)
  }

  const deleteStudyCDISCAnnotation = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Annotation?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await cdiscServices.deleteStudyCDISCAnnotation(studyId)
        getStudyCDISCAnnotation()
      }
    });
  };

  const addUpdateStudyCDISCAnnotation = async (files, pdfPreviews, textBoxes) => {
    let userData = {}
    userData.studyId = studyId
    if (files?.length > 0) {
      userData.files = files || []
    }
    if (textBoxes?.length > 0) {
      userData.textBoxes = textBoxes || []
    }
    if (pdfPreviews) {
      userData.pdfPreviews = pdfPreviews || {}
    }
    let data = await cdiscServices.addUpdateStudyCDISCAnnotation(userData)
    if (data?.stusCode === 200) {
      getStudyCDISCAnnotation()
    }
  }

  const handleFileChange = async (event) => {
    setLoading(true)
    const uploadedFiles = Array.from(event.target.files);
    const pdfFiles = [];
    const filesData = [];
    const previews = { ...pdfPreviews };
    for (const file of uploadedFiles) {
      if (file.type === "application/pdf") {
        pdfFiles.push(file);
        const previewUrls = await generatePdfPreviews(file);
        previews[file.name] = previewUrls;
        filesData.push({ name: file.name, type: "pdf" })
      }
    }
    let fileArray = [...files]
    let finalArrayFile = [...fileArray, ...pdfFiles]
    setFiles(finalArrayFile);
    setPdfPreviews(previews);

    addUpdateStudyCDISCAnnotation(filesData, previews, [])
    setLoading(false)
    if (pdfFiles.length > 0) {
      const fileUrl = URL.createObjectURL(pdfFiles[0]);
      setCurrentPdf(fileUrl);
    }
  };

  const generatePdfPreviews = async (file) => {
    const fileUrl = URL.createObjectURL(file);
    const pdf = await pdfjsLib.getDocument(fileUrl).promise;
    const pageCount = pdf.numPages;
    const previews = [];

    for (let i = 1; i <= pageCount; i++) {
      const canvas = document.createElement("canvas");
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: 2 });

      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const context = canvas.getContext("2d");
      const renderContext = { canvasContext: context, viewport };
      await page.render(renderContext).promise;

      previews.push({
        previewUrl: canvas.toDataURL(),
        width: viewport.width,
        height: viewport.height
      });
    }

    return previews;
  };


  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const handlePdfClick = (e, pageIndex) => {
    if (isAddingTextBox) {
      const rect = pdfContainerRef.current.getBoundingClientRect();
      const id = Math.random().toString().substr(2, 10);
      const x = e.clientX - rect.left || "";
      const y = getRandomNumber(20, 500);
      const width = 200;
      const height = 30;
      const fontSize = 12;
      const newTextBox = { id, pageIndex, x, y, width, height, fontSize };
      let newTextBoxData = [...textBoxes, newTextBox]
      setTextBoxes(newTextBoxData);
      addUpdateStudyCDISCAnnotation(null, null, newTextBoxData)
      setIsAddingTextBox(false);
    }
  };

  const handleBoxDataChange = (id, type, value) => {
    let newTextBoxData = [...textBoxes]
    let finalTextBoxData = newTextBoxData.map(box => (box.id === id ? { ...box, [type]: value } : box))
    setTextBoxes(finalTextBoxData);
    addUpdateStudyCDISCAnnotation(null, null, finalTextBoxData)
  };

  const handleResize = (id, width, height) => {
    let newTextBoxData = [...textBoxes]
    let finalTextBoxData = newTextBoxData.map(box => (box.id === id ? { ...box, width, height } : box))
    setTextBoxes(finalTextBoxData);
    addUpdateStudyCDISCAnnotation(null, null, finalTextBoxData)
  };

  const handleDrag = debounce((id, data) => {
    let newTextBoxData = [...textBoxes]
    let finalTextBoxData = newTextBoxData.map(box => (box.id === id ? { ...box, x: data.x, y: data.y } : box))
    setTextBoxes(finalTextBoxData);
    addUpdateStudyCDISCAnnotation(null, null, finalTextBoxData)
  }, 16);

  const handlePageNumberBoxChange = (id, value, fileIndex) => {
    const file = files[fileIndex];
    const totalPageIndexs = [
      ...pdfPreviews[file.name].map((_, i) => i)
    ];

    let valueArray = value?.split(",")?.map(item => item.trim());
    let textBoxesData = [...textBoxes]
    let checkData = textBoxesData?.find(item => item.id === id)
    let newArrayData = []
    for (const element of valueArray) {
      let newData = { ...checkData, id: Math.random().toString().substr(2, 10), pageIndex: Number(element) };
      newArrayData.push(newData);
    }
    const uniqueData = Array.from(new Map([...textBoxesData, ...newArrayData].map(item => [item.pageIndex, item])).values());
    const filteredDatasets = uniqueData.filter(item => totalPageIndexs.includes(item.pageIndex));
    setTextBoxes(filteredDatasets)
    addUpdateStudyCDISCAnnotation(null, null, filteredDatasets)
  };

  const handleRemoveBox = (e, id) => {
    let newTextBoxData = [...textBoxes]
    let finalTextBoxData = newTextBoxData.filter(box => box.id !== id)
    setTextBoxes(finalTextBoxData);
    addUpdateStudyCDISCAnnotation(null, null, finalTextBoxData)
  };

  const handleDuplicatePage = (fileIndex, pageIndex) => {
    const updatedFiles = [...files];
    const file = updatedFiles[fileIndex];
    const newPreviewUrls = [...pdfPreviews[file.name]];
    newPreviewUrls.push(pdfPreviews[file.name][pageIndex]);
    let pdfPreviewData = [...pdfPreviews]
    pdfPreviewData[file.name] = newPreviewUrls
    setPdfPreviews(pdfPreviewData);
    setFiles(updatedFiles);
    addUpdateStudyCDISCAnnotation(updatedFiles, pdfPreviewData, [])
  };

  const handleRotatePage = (fileIndex, pageIndex) => {
    const file = files[fileIndex];
    const rotatedPreviewUrls = [
      ...pdfPreviews[file.name].map(item => item.previewUrl)
    ];
    const img = new Image();
    img.src = rotatedPreviewUrls[pageIndex];

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const newWidth = img.height;
      const newHeight = img.width;
      canvas.width = newWidth;
      canvas.height = newHeight;

      context.save();
      context.translate(newWidth / 2, newHeight / 2);
      context.rotate((90 * Math.PI) / 180);
      context.drawImage(img, -img.width / 2, -img.height / 2);
      context.restore();

      rotatedPreviewUrls[pageIndex] = canvas.toDataURL();

      const updatedArray = pdfPreviews[file.name]?.map((item, index) => ({
        ...item,
        previewUrl: rotatedPreviewUrls[index],
        width: index === pageIndex ? newWidth : item.width,
        height: index === pageIndex ? newHeight : item.height,
      }));

      const previews = { ...pdfPreviews };
      previews[file.name] = updatedArray;
      setPdfPreviews(previews);
      addUpdateStudyCDISCAnnotation(files, previews, [])
    };
  };

  const handleDeletePage = (fileIndex, pageIndex) => {
    const updatedFiles = [...files];
    const file = updatedFiles[fileIndex];
    const updatedPreviews = [...pdfPreviews[file.name]];
    updatedPreviews.splice(pageIndex, 1);
    let pdfPreviewData = [...pdfPreviews]
    pdfPreviewData[file.name] = updatedPreviews
    setPdfPreviews(pdfPreviewData);
    setFiles(updatedFiles);
    addUpdateStudyCDISCAnnotation(updatedFiles, pdfPreviewData, [])
  };

  const combinedRef = (element) => {
    pdfDownloadRef.current = element;
    pdfContainerRef.current = element;
  };

  useEffect(() => {
    getStudyCDISCAnnotation();
    // eslint-disable-next-line
  }, [studyId]);

  return (
    <Container fluid>
      <Row>
        {!loader && files?.length > 0 &&
          <Form.Group
            as={Col}
            md={2}
            className="p-0 border-end overflow-auto text-center"
            style={{ height: "calc(100vh - 147px)" }}
          >
            {files?.map((file, fileIndex) => (
              <div key={fileIndex}>
                {file.type.includes("pdf") &&
                  pdfPreviews[file.name] &&
                  pdfPreviews[file.name].map((item, pageIndex) => (
                    <>
                      <div className="position-relative my-3">
                        <div
                          className="rounded-1 border hstack justify-content-center bg-white m-auto p-2"
                          style={{
                            width: 150,
                            height: 200,
                            overflow: "hidden",
                            marginBottom: 15,
                          }}
                        >
                          <img
                            key={pageIndex}
                            src={item?.previewUrl}
                            title={file.name}
                            alt={`preview-${file.name}-page-${pageIndex}`}
                            style={{
                              width: "100%",
                              maxHeight: 400,
                              objectFit: "contain",
                              marginBottom: 10,
                            }}
                          />
                          <div
                            className="hstack gap-2 p-2 border shadow-sm position-absolute bg-white rounded-1"
                            style={{ bottom: -18 }}
                          >
                            <IoDuplicateOutline
                              className="text-info cursor-pointer"
                              title="Duplicate"
                              onClick={() =>
                                handleDuplicatePage(fileIndex, pageIndex)
                              }
                            />
                            <MdRotateLeft
                              style={{ fontSize: 18 }}
                              className="text-primary cursor-pointer"
                              title="Rotate"
                              onClick={() =>
                                handleRotatePage(fileIndex, pageIndex)
                              }
                            />
                            <AiOutlineDelete
                              className="text-danger cursor-pointer"
                              title="Delete Page"
                              onClick={() =>
                                handleDeletePage(fileIndex, pageIndex)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 fs-12">{`${pageIndex + 1}`}</div>
                      <div className="mt-2">
                        <BsPlusCircleFill
                          className="uploadIcon cursor-pointer"
                          title="Add File"
                          onClick={() => fileInputRef.current?.click()}
                        />
                        <Form.Control
                          type="file"
                          className="d-none"
                          multiple
                          ref={fileInputRef}
                          onChange={handleFileChange}
                        />
                      </div>
                    </>
                  ))}
              </div>
            ))}
            <div
              onClick={() => fileInputRef?.current?.click()}
              className="rounded-1 hstack justify-content-center cursor-pointer m-auto my-3"
              style={{
                border: "1px dashed #4993DE",
                background: "#F1F9FF",
                width: 150,
                height: 200,
              }}
            >
              <div className="lh-1">
                <FiUploadCloud
                  style={{ fontSize: 25 }}
                  className="text-muted"
                />
                <Form.Label className="m-0 d-block mt-3 fs-11">
                  Upload PDF, Image,
                </Form.Label>
                <Form.Label className="m-0 fs-11">Word, Excel and</Form.Label>
                <Form.Label className="m-0 fs-11">
                  PowerPoint Files
                </Form.Label>
              </div>
            </div>
            <Form.Control
              type="file"
              className="d-none"
              multiple
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </Form.Group>
        }
        <Form.Group
          as={Col}
          md={!loader && files?.length > 0 ? 8 : 10}
          className="p-0 overflow-auto border-end"
          style={{ height: "calc(100vh - 147px)" }}
        >
          {loader ?
            <div
              className="hstack justify-content-center"
              style={{ height: "calc(100vh - 147px)" }}
            >
              <Spinner
                style={{ width: 25, height: 25 }}
              />
            </div>
            :
            files?.length === 0 ? (
              <div
                className="hstack justify-content-center"
                style={{ height: "calc(100vh - 147px)" }}
              >
                No files uploaded.
              </div>
            ) : (
              files?.map((file, fileIndex) => (
                <div ref={combinedRef} key={fileIndex}>
                  {file.type.includes("pdf") &&
                    pdfPreviews[file.name] &&
                    pdfPreviews[file.name].map((item, pageIndex) => (
                      <>
                        <div className="position-relative my-3">
                          <img
                            onClick={(e) => handlePdfClick(e, pageIndex)}
                            className="hstack justify-content-center m-auto mb-1"
                            key={pageIndex}
                            src={item?.previewUrl}
                            title={file.name}
                            alt={`preview-${file.name}-page-${pageIndex}`}
                            style={{
                              // width: item?.width > item?.height ? 1123 : 734,
                              // maxHeight: item?.width > item?.height ? 734 : 1123,
                              width: 734,
                              maxHeight: 1123,
                              objectFit: "contain",
                              marginBottom: 10,
                            }}
                          />
                          {textBoxes?.map((box, boxKey) => (
                            box?.pageIndex === pageIndex ?
                              <Draggable
                                key={boxKey}
                                position={{ x: box?.x, y: box?.y }}
                                onDrag={(e, data) => handleDrag(box?.id, data)}>
                                <div
                                  className="position-absolute showtextBox"
                                  style={{ zIndex: 998, top: 0, left: 167.5 }}
                                >
                                  <div className="absolute showToolbar border p-1 bg-white" style={{ marginTop: -34 }}>
                                    <Form.Group className="w-25 hstack gap-2">
                                      <div
                                        className="cursor-pointer"
                                        title="Remove"
                                        onClick={(e) => handleRemoveBox(e, box.id)}
                                        style={{ width: 25, height: 22, borderRadius: 3, marginRight: -11 }}
                                      >
                                        <RxCrossCircled className="mb-1" style={{ fontSize: 16, marginTop: -2 }} />
                                      </div>
                                      <Form.Control
                                        type="number"
                                        title="Font Size"
                                        className="border ms-2"
                                        value={box?.fontSize}
                                        style={{ width: 50, height: 22 }}
                                        onChange={(e) => handleBoxDataChange(box.id, "fontSize", Number(e.target.value))}
                                        min="8"
                                        max="72"
                                      />
                                      <div>
                                        <Form.Control
                                          type="color"
                                          title="Color"
                                          defaultValue="#ff0000"
                                          value={box?.textRgbColor}
                                          style={{ width: 25, height: 22 }}
                                          onChange={(e) => handleBoxDataChange(box.id, "textRgbColor", e.target.value)}
                                        />
                                      </div>
                                      <div>
                                        <Form.Control
                                          type="color"
                                          title="Background Color"
                                          defaultValue="#cfe2ff"
                                          value={box?.fillingRgbColor}
                                          style={{ width: 25, height: 22 }}
                                          onChange={(e) => handleBoxDataChange(box.id, "fillingRgbColor", e.target.value)}
                                        />
                                      </div>
                                      <div
                                        className={`border px-1 cursor-pointer ${box?.bold ? "active" : ""}`}
                                        title="Bold"
                                        style={{ width: 25, height: 22, borderRadius: 3 }}
                                        onClick={(e) => handleBoxDataChange(box.id, "bold", !box?.bold)}
                                      >
                                        <RxFontBold className="mb-1" />
                                      </div>
                                      <div
                                        className={`border px-1 cursor-pointer ${box?.italic ? "active" : ""}`}
                                        title="Italic"
                                        style={{ width: 25, height: 22, borderRadius: 3 }}
                                        onClick={(e) => handleBoxDataChange(box.id, "italic", !box?.italic)}
                                      >
                                        <CgFormatItalic className="mb-1" />
                                      </div>
                                      <div
                                        className={`border px-1 cursor-pointer ${box?.underline ? "active" : ""}`}
                                        title="Underline"
                                        style={{ width: 25, height: 22, borderRadius: 3 }}
                                        onClick={(e) => handleBoxDataChange(box.id, "underline", !box?.underline)}
                                      >
                                        <MdOutlineFormatUnderlined className="mb-1" />
                                      </div>
                                      <div
                                        className={`border px-1 cursor-pointer ${box?.alignment === "left" ? "active" : ""}`}
                                        title="left"
                                        style={{ width: 25, height: 22, borderRadius: 3 }}
                                        onClick={(e) => handleBoxDataChange(box.id, "alignment", "left")}
                                      >
                                        <CiTextAlignLeft className="mb-1" />
                                      </div>
                                      <div
                                        className={`border px-1 cursor-pointer ${box?.alignment === "center" ? "active" : ""}`}
                                        title="center"
                                        style={{ width: 25, height: 22, borderRadius: 3 }}
                                        onClick={(e) => handleBoxDataChange(box.id, "alignment", "center")}
                                      >
                                        <CiTextAlignCenter className="mb-1" />
                                      </div>
                                      <div
                                        className={`border px-1 cursor-pointer ${box?.alignment === "right" ? "active" : ""}`}
                                        title="right"
                                        style={{ width: 25, height: 22, borderRadius: 3 }}
                                        onClick={(e) => handleBoxDataChange(box.id, "alignment", "right")}
                                      >
                                        <CiTextAlignRight className="mb-1" />
                                      </div>
                                      <div
                                        className={`border px-1 cursor-pointer${box?.alignment === "justify" ? "active" : ""}`}
                                        title="justify"
                                        style={{ width: 25, height: 22, borderRadius: 3 }}
                                        onClick={(e) => handleBoxDataChange(box.id, "alignment", "justify")}
                                      >
                                        <CiTextAlignJustify className="mb-1" />
                                      </div>
                                      <div className="px-0">
                                        <Form.Control
                                          type="text"
                                          onChange={(e) => handlePageNumberBoxChange(box.id, e.target.value, fileIndex)}
                                          style={{ width: 100, height: 22, borderRadius: 3, marginRight: -8 }}
                                          placeholder="Enter Page no."
                                        />
                                      </div>
                                    </Form.Group>
                                  </div>
                                  <Rnd
                                    key={boxKey}
                                    default={{
                                      width: box?.width,
                                      height: box?.height,
                                    }}
                                    enableResizing={{
                                      top: true,
                                      right: true,
                                      bottom: true,
                                      left: true,
                                      topRight: true,
                                      bottomRight: true,
                                      bottomLeft: true,
                                      topLeft: true,
                                    }}
                                    onResizeStop={(e, direction, ref, delta, position) => {
                                      handleResize(box?.id, ref.offsetWidth, ref.offsetHeight);
                                    }}
                                  >
                                    <Form.Control
                                      type="text"
                                      className="border"
                                      value={box?.text}
                                      onChange={(e) =>
                                        handleBoxDataChange(box?.id, "text", e.target.value)
                                      }
                                      placeholder="Add text here..."
                                      style={{
                                        backgroundColor: box?.fillingRgbColor || "#fff8db",
                                        color: box?.textRgbColor || "#000",
                                        fontSize: `${box?.fontSize || 12}px`,
                                        textAlign: box?.alignment || "left",
                                        fontWeight: box?.bold ? "bold" : "normal",
                                        fontStyle: box?.italic ? "italic" : "normal",
                                        textDecoration: box?.underline ? "underline" : "none",
                                        width: "100%",
                                        height: "100%",
                                      }}
                                    />
                                  </Rnd>
                                </div>
                              </Draggable>
                              : ""
                          ))}
                        </div>
                      </>
                    ))}
                </div>
              ))
            )}
        </Form.Group>
        <Form.Group
          as={Col}
          md={2}
          className="pt-2 overflow-auto"
          style={{ height: "calc(100vh - 156px)", backgroundColor: "#f4f4f4" }}
        >
          <button
            className="w-100 selectedMenu border p-1 fontSize12 position-relative mb-2"
            style={{ background: "#0d82dc57", fontWeight: 500 }}
            onClick={() => fileInputRef.current.click()}
          >
            {!loading ?
              <MdCloudUpload style={{ fontSize: 13, marginTop: -3, marginRight: 4 }} />
              :
              <Spinner
                style={{ width: 15, height: 15 }}
                className="me-2"
              />
            } Upload {files && "New"} Document
          </button>

          <Form.Control
            type="file"
            accept=".pdf"
            ref={fileInputRef}
            className="d-none"
            onChange={handleFileChange}
          />

          {files?.length > 0 && (
            <>
              <Form.Group className="my-2">
                <Form.Label className="mb-1 fw-bold">Tools</Form.Label>
                <div className="toolbar">
                  <button
                    className={`border py-2 px-3 ${isAddingTextBox && "activeMenu"
                      }`}
                    title="Add Text Field"
                    onClick={() => setIsAddingTextBox(true)}
                  >
                    <IoText className="mb-1 me-2 fs-13" />
                    Add Text Box
                  </button>
                </div>
              </Form.Group>
              <div className="w-100 bottom-0 py-3">
                <ReactToPrint
                  trigger={() => (
                    <button
                      className="w-100 selectedMenu border p-1 fontSize12"
                      title="Print Annotate Documnet"
                      style={{ fontWeight: 500, background: "#0d82dc57" }}
                    >
                      <i className="fa fa-print" title="Print" style={{ marginRight: 4 }}></i> Print
                    </button>
                  )}
                  content={() => pdfDownloadRef.current}
                />
              </div>
              <div className="w-100 bottom-0 py-3">
                <button
                  onClick={deleteStudyCDISCAnnotation}
                  className="w-100 selectedMenu border p-1 fontSize12"
                  title="Delete Annotation"
                  style={{ fontWeight: 500, background: "#dc0d0d57" }}
                >
                  <i className="fa fa-trash-can" title="Print" style={{ marginRight: 4 }}></i> Delete Annotation
                </button>
              </div>
            </>
          )}
        </Form.Group>
      </Row>
    </Container>
  );
};

export default ACRF;
