import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { cdiscServices } from "../../../../../../../Services/CDISC/cdiscServices";
import Multiselect from "multiselect-react-dropdown";
import SplitPane from "react-split-pane";

const KeepDropColumnTools = ({
  studyId,
  derivationId,
  getDomainDerivationList,
}) => {
  let [uniqueColumnKey, setUniqueColumnKey] = useState([]);
  let [dataType, setDataType] = useState("");
  let [dataColumns, setDataColumns] = useState([]);
  let [dataCustoms, setDataCustoms] = useState("");
  let [templateIds, setTemplateIds] = useState([]);
  let [columns, setColumns] = useState([]);
  let [primaryDataType, setPrimaryDataType] = useState("");

  const findDomainDerivationData = async () => {
    let result = await cdiscServices.findDomainDerivationData(
      studyId,
      derivationId
    );
    let derivationData = result?.data;
    if (derivationData?.primaryDataset || derivationData?.secondaryDataType || derivationData?.cellDataset) {
      setTemplateIds([
        { templateId: derivationData?.primaryDataset || "" },
        { templateId: derivationData?.secondaryDataset || "" },
        { templateId: derivationData?.cellDataset || "" },
      ])
    }
    if (
      derivationData?.keepColumns?.length > 0 ||
      derivationData?.keepCustoms
    ) {
      setDataColumns(derivationData?.keepColumns || []);
      setDataCustoms(derivationData?.keepCustoms || "");
      setDataType("keep");
    }else if (
      derivationData?.dropColumns?.length > 0 ||
      derivationData?.dropCustoms
    ) {
      setDataColumns(derivationData?.dropColumns || []);
      setDataCustoms(derivationData?.dropCustoms || "");
      setDataType("drop");
    } else {
      setDataColumns([]);
      setDataCustoms("");
      setDataType("");
    }
    setPrimaryDataType(derivationData?.primaryDataType);
    if (derivationData?.primaryDataset || derivationData?.primaryDataType) {
      getDatasetColumList(derivationData?.primaryDataset, derivationData?.primaryDataType)
    }
  };

  console.log(">>>", dataType);
  

  useEffect(() => {
    findDomainDerivationData();
  }, [derivationId]);

  const derivationCRFData = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.derivationId = derivationId;
    if (dataType === "keep") {
      userData.keepColumns = dataColumns;
      userData.keepCustoms = dataCustoms;
    } else if (dataType === "drop") {
      userData.dropColumns = dataColumns;
      userData.dropCustoms = dataCustoms;
    } else {
      userData.keepColumns = [];
      userData.keepCustoms = "";
      userData.dropColumns = [];
      userData.dropCustoms = "";
    }
    await cdiscServices.derivationSaveCRFData(userData);
    getDomainDerivationList();
  };

  const getMultiDataEntryColumList = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.templateIds = templateIds || [];
    let data = await cdiscServices.getMultiDataEntryColumList(userData);
    setUniqueColumnKey(data?.uniqueColumnKey || []);
  };

  const getDatasetColumList = async (primaryDataset, type) => {
    let data = await cdiscServices.getDatasetColumList(
      studyId,
      type,
      primaryDataset
    );
    setColumns(data?.data);
  };

  useEffect(() => {
    if (templateIds?.length > 0) {
      getMultiDataEntryColumList();
    }
  }, [templateIds?.length]);

  return (
    <>
      <SplitPane
        split="horizontal"
        className="position-relative"
        defaultSize="100%"
      >
        <div className="py-2 px-3">
          <div className="p-3 rounded" style={{ border: "1px dashed #dee2e6" }}>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">Dataset Column</Form.Label>
              <Form.Select
                onChange={(e) => setDataType(e.target.value)}
              >
                <option value={""}>Select Type</option>
                <option selected={dataType === "keep"} value={"keep"}>Keep Column</option>
                <option selected={dataType === "drop"} value={"drop"}>Drop Column</option>
              </Form.Select>
            </Form.Group>
            {dataType &&
              <>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1 fw-bold">Dataset Column</Form.Label>
                  <Multiselect
                    displayValue="label"
                    options={((primaryDataType === "Merge" || primaryDataType === "Append") ? uniqueColumnKey : columns)?.map((keys, key) => ({
                      label: keys?.name,
                      value: keys?.name,
                    }))}
                    placeholder="Select Dataset Column"
                    selectedValues={dataColumns || []}
                    onSelect={(selectedList) => setDataColumns(selectedList)}
                    onRemove={(removedList) => setDataColumns(removedList)}
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1 fw-bold">
                    Custom Column Name
                  </Form.Label>
                  <Form.Control
                    value={dataCustoms}
                    as="textarea"
                    onChange={(e) => setDataCustoms(e.target.value)}
                    placeholder="Enter Custom Column Name Use Seperator ||"
                  />
                </Form.Group>
              </>
            }
          </div>
          <div className="text-end p-2">
            <button onClick={derivationCRFData} className="updateProfile">
              Save
            </button>
          </div>
        </div>
      </SplitPane>
    </>
  );
};

export default KeepDropColumnTools;
