import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { studyOnboardingService } from "../../../../../../Services/studyServices/studyVisitsServices";
import Moment from "react-moment";
import { SpinerDataLoader } from "../../../../../NoDataFoundTable/NoDataFoundTable";
import SplitPane from "react-split-pane";

const StudyEventAttributeTable = ({ visitUniqueId, tableRef, onMouseDown }) => {
  const [attributeDetails, setAttributeDetails] = useState("");
  const [loader, setLoader] = useState(false);
  let StudyMode = JSON.parse(localStorage.getItem("StudyMode"));
  const getStudyVisitAttributeByID = async (visitUniqueId) => {
    setLoader(true);
    let data = await studyOnboardingService.getStudyVisitAttributeByID(
      visitUniqueId,
      StudyMode
    );
    setAttributeDetails(data?.data);
    setLoader(false);
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "verified":
        return "green";
      case "pending":
        return "gray";
      default:
        return "inherit";
    }
  };

  useEffect(() => {
    getStudyVisitAttributeByID(visitUniqueId);
  }, [visitUniqueId]);
  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        style={{ height: "92%" }}
      >
        {loader ? (
          <SpinerDataLoader />
        ) : (
          <div className="table-container tableLibrarySection">
            <Table
              id="resizable-table"
              striped
              className="m-0 custom-table"
              ref={tableRef}
              bordered
            >
              <thead className="thead-sticky" style={{ top: -1 }}>
                <tr>
                  <th onMouseDown={(e) => onMouseDown(e, 0)}>Attribute</th>
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {attributeDetails?.updatedRow
                      ? "Visit Updated By"
                      : "Visit Created By"}
                  </td>
                  <td>{attributeDetails?.uploadedBy}</td>
                </tr>

                <tr>
                  <td>
                    {attributeDetails?.updatedRow
                      ? "Visit Updated Date & Time"
                      : "Visit Created Date & Time"}
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {attributeDetails?.createdAt}
                    </Moment>
                  </td>
                </tr>

                <tr>
                  <td>Visit Version</td>
                  <td>{attributeDetails?.version}</td>
                </tr>
                <tr>
                  <td>Quality Checked By</td>
                  <td>{attributeDetails?.reviewedBy}</td>
                </tr>

                <tr>
                  <td>Quality Checked Status</td>
                  <td
                    className="text-capitalize"
                    style={{ color: getStatusColor(attributeDetails?.status) }}
                  >
                    {attributeDetails?.status}
                  </td>
                </tr>

                <tr>
                  <td>Quality Checked Date & Time</td>
                  <td>
                    {attributeDetails?.reviewedBy ? (
                      <Moment format="DD MMM YYYY | HH:mm" withTitle>
                        {attributeDetails?.updatedAt}
                      </Moment>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        )}
      </SplitPane>
    </>
  );
};

export default StudyEventAttributeTable;
