import React, { useRef, useState } from "react";
import Swal from "sweetalert2";
import { Table } from "react-bootstrap";
import Pagination from "../../../../ETMF/Pagination/Pagination";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";

const StudySupplementaryTableData = ({
  tableRef,
  studyId,
  onMouseDown,
  setIsFormOpen,
  setSupplimentaryData,
  getSupplimentalsList,
  addNewStudySupplementarydata,
}) => {
  const [highlightedRow, setHighlightedRow] = useState(null);
  const ref = useRef(null);
  const deleteClientCodeList = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete Suppliment!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let results = await cdiscServices.deleteStudySupplimentals(id, studyId);
        if (results?.statusCode === 200) {
          getSupplimentalsList();
        }
      }
    });
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = addNewStudySupplementarydata?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(
    addNewStudySupplementarydata?.length / itemsPerPage
  );

  return (
    <>
      <div className="overflow-auto">
        <Table
          striped
          hover
          id="resizable-table"
          className="custom-table tableLibrary m-0 overflow-auto"
          ref={tableRef}
        >
          <thead>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)}>Order</th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Version</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Class</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Domain</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Veriable Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)}>Veriable Label</th>
              <th onMouseDown={(e) => onMouseDown(e, 6)}>Data Type</th>
              <th onMouseDown={(e) => onMouseDown(e, 7)}>Length</th>
              <th onMouseDown={(e) => onMouseDown(e, 8)}>Significant</th>
              <th onMouseDown={(e) => onMouseDown(e, 9)}>Mandatory</th>
              <th onMouseDown={(e) => onMouseDown(e, 10)}>Origin</th>
              <th onMouseDown={(e) => onMouseDown(e, 11)}>Method</th>
              <th onMouseDown={(e) => onMouseDown(e, 12)}>Comment</th>
              <th onMouseDown={(e) => onMouseDown(e, 13)}>CDISC Note</th>
              <th onMouseDown={(e) => onMouseDown(e, 14)}>Code List</th>
              <th onMouseDown={(e) => onMouseDown(e, 15)}>Update</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 16)}
                style={{ width: 70, right: -1 }}
                className="text-center position-sticky"
              >
                Delete
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {result?.length > 0 &&
              result?.map((item, index) => (
                <tr
                  key={index}
                  className={`position-relative ${
                    highlightedRow === index && "activeMenu"
                  }`}
                  onClick={() => setHighlightedRow(index)}
                >
                  <td>{item.order}</td>
                  <td>{item.version}</td>
                  <td>{item.class}</td>
                  <td>{item.domain}</td>
                  <td>{item.name}</td>
                  <td>{item.label}</td>
                  <td className="text-capitalize">{item.dataType}</td>
                  <td>
                    {!item.dataType ? "" : item.dataType === "text" ? 200 : 8}
                  </td>
                  <td>{item.dataType === "float" ? item.significant : ""}</td>
                  <td className="text-capitalize">{item.mandatory}</td>
                  <td>{item.origin}</td>
                  <td>{item.mathod}</td>
                  <td>{item.comment}</td>
                  <td>{item.note}</td>
                  <td>{item.codeList}</td>
                  <td className="text-center">
                    <button
                      onClick={() => {
                        setSupplimentaryData(item);
                        setIsFormOpen(true);
                      }}
                      title="Update"
                      className="text-white border-success bg-success border-0"
                      style={{ fontSize: 10, borderRadius: 3 }}
                    >
                      Update
                    </button>
                  </td>
                  <td
                    className="position-sticky bg-light text-center"
                    style={{ right: -1 }}
                  >
                    <button
                      className="text-white border-danger bg-danger border-0"
                      style={{ fontSize: 10, borderRadius: 3 }}
                      title="Delete Supplementary"
                      onClick={() => deleteClientCodeList(item?._id)}
                    >
                      Delete
                    </button>
                  </td>
                  <td></td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={result?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default StudySupplementaryTableData;
