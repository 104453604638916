import React, { useState } from "react";
import SplitPane from "react-split-pane";
import AddColumnToolsWithValue from "./AddColumnToolsWithValue";
import AddValueManipulation from "./AddValueManipulation";

const ManipulationsTools = ({
  studyId,
  derivationId,
  getDomainDerivationList,
}) => {
  let [activeTab, setActiveTab] = useState("addColumn");
  return (
    <>
      <SplitPane
        split="vertical"
        className="position-relative"
        defaultSize="15%"
      >
        <div>
          <span
            onClick={() => setActiveTab("addColumn")}
            className={`CP ps-3 small linkClass ${
              activeTab === "addColumn" && "selectedMenu"
            }`}
          >
            Add Column
          </span>
          <span
            onClick={() => setActiveTab("manipulation")}
            className={`CP ps-3 small linkClass ${
              activeTab === "manipulation" && "selectedMenu"
            }`}
          >
            Update Column
          </span>
        </div>
        {activeTab === "addColumn" ? (
          <AddColumnToolsWithValue
            studyId={studyId}
            derivationId={derivationId}
            getDomainDerivationList={getDomainDerivationList}
          />
        ) : activeTab === "manipulation" ? (
          <AddValueManipulation
            studyId={studyId}
            derivationId={derivationId}
            getDomainDerivationList={getDomainDerivationList}
          />
        ) : (
          ""
        )}
      </SplitPane>
    </>
  );
};

export default ManipulationsTools;
