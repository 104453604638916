import React, { useEffect, useState } from "react";
import SplitPane from "react-split-pane";
import { Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { rbqmStudyServices } from "../../../../Services/rbqmServices/rbqmStudyServices";
import Moment from "react-moment";
import Swal from "sweetalert2";
import RiskAreaFilterData from "./RiskAreaFilterData";
import UseToggle from "../../../../Hooks/UseToggle";
import ViewFormule from "./ViewFormule";
import ViewNotification from "./ViewIndicators";
import Pagination from "../../../ETMF/Pagination/Pagination";
import { useParams } from "react-router";

const RiskArea = () => {
  let [id, setId] = useState("");
  let { toggle, setToggle, toggle1, setToggle1, toggle2, setToggle2 } =
    UseToggle();
  let [loader, setLoader] = useState(false);
  let [openForm, setOpenForm] = useState("");
  let [riskArea, setRiskArea] = useState("");
  let [areaLabel, setAreaLabel] = useState("");
  let [riskAreaId, setRiskAreaId] = useState("");
  let [riskAreaList, setRiskAreaList] = useState([]);
  let [riskCategoryList, setRiskCategoryList] = useState([]);
  let [indicatorList, setIndicatorList] = useState([]);
  let [indicators, setIndicators] = useState([{ name: "", label: "" }]);
  let [formuleValue, setFormuleValue] = useState("");
  let [notification, setNotification] = useState([]);
  let { studyId } = useParams("");

  const addNewRiskArea = async () => {
    setLoader(true);
    let userData = {};
    userData.studyId = studyId;
    userData.categoryId = riskAreaId;
    userData.areaName = riskArea;
    userData.areaLabel = areaLabel;
    userData.indicator = indicators;
    let data = await rbqmStudyServices.addNewRiskArea(userData);
    if (data?.statusCode === 200) {
      getRiskAreaList();
      setRiskArea("");
      setAreaLabel("");
      setIndicators([{ name: "", label: "" }]);
    }
    setLoader(false);
  };

  const getRiskCategoryList = async () => {
    let data = await rbqmStudyServices.getRiskCategoryList(studyId);
    setRiskCategoryList(data?.data);
  };

  const getRiskAreaList = async () => {
    setLoader(true);
    let data = await rbqmStudyServices.getRiskAreaList(studyId);
    setRiskAreaList(data?.data);
    setLoader(false);
  };

  const updateRiskAreaData = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this Risk Area?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoader(true);
        let userData = {};
        userData.studyId = studyId;
        userData.id = id;
        userData.categoryId = riskAreaId;
        userData.areaName = riskArea;
        userData.areaLabel = areaLabel;
        userData.indicator = indicators;
        let data = await rbqmStudyServices.updateRiskArea(userData);
        if (data?.statusCode === 200) {
          getRiskAreaList();
        }
        setLoader(false);
      }
    });
  };

  const handleUpdateComment = (e, item) => {
    setId(item?._id);
    setRiskAreaId(item?.categoryId);
    setRiskArea(item?.name);
    setAreaLabel(item?.label);
    setIndicators(item?.indicator);
    setOpenForm(true);
  };

  const handleViewNotifications = (e, item) => {
    setId(item?._id);
    setNotification(item?.notification || []);
    setIndicatorList(item?.indicator);
    setToggle1();
  };

  const handleViewFormule = (e, item) => {
    setId(item?._id);
    setIndicatorList(item?.indicator);
    setFormuleValue(item?.formula || "");
    setToggle2();
  };

  const clearForm = () => {
    setId("");
    setRiskArea("");
    setAreaLabel("");
    setRiskAreaId("");
    setIndicators([{ name: "", label: "" }]);
  };

  const deleteRiskArea = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Risk Area?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await rbqmStudyServices.deleteRiskArea(studyId, id);
        if (data?.statusCode === 200) {
          getRiskAreaList();
        }
      }
    });
  };

  useEffect(() => {
    getRiskCategoryList();
    getRiskAreaList();
  }, [studyId]);

  const handleIndicatorChange = (index, field, value) => {
    const updatedIndicators = [...indicators];
    updatedIndicators[index][field] = value;
    setIndicators(updatedIndicators);
  };

  // Add new indicator row
  const handleAddIndicator = () => {
    setIndicators([...indicators, { name: "", label: "" }]);
  };

  // Remove indicator row
  const handleRemoveIndicators = (index) => {
    const updatedIndicators = indicators.filter((_, i) => i !== index);
    setIndicators(updatedIndicators);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = riskAreaList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(riskAreaList?.length / itemsPerPage);

  return (
    <>
      <div className="hstack justify-content-between border-bottom px-1">
        <div className="hstack gap-2 toolbar" style={{ padding: "5px" }}>
          <i
            title="Add"
            class="fa-solid fa-square-plus CP p-1 fontSize12"
            onClick={() => setOpenForm(!openForm)}
            style={{ borderRadius: 3 }}
          ></i>
          <div className="vr"></div>
          <i
            title="Filter"
            class="fa-solid fa-filter CP p-1 fontSize12"
            onClick={setToggle}
            style={{ borderRadius: 3 }}
          ></i>
        </div>
      </div>
      <SplitPane
        split="horizontal"
        style={{ height: "calc(100vh - 185px)" }}
        className="position-relative"
        defaultSize={"100%"}
      >
        <SplitPane minSize={20} defaultSize={openForm ? "30%" : "100%"}>
          {openForm && (
            <div className="p-2">
              <Form.Group className="mb-2">
                <div className="hstack justify-content-between">
                  <Form.Label className="fw-bold mb-1">
                    Risk Categories <span className="text-danger">*</span>
                  </Form.Label>
                  {id && (
                    <button
                      title="Add New Metadata"
                      className="text-white border-success bg-success border-0"
                      style={{ fontSize: 10, borderRadius: 3 }}
                      onClick={() => clearForm()}
                    >
                      <i className="fa-solid fa-square-plus"></i> Add New
                    </button>
                  )}
                </div>
                <Form.Select
                  className="rounded-1"
                  disabled={id}
                  onChange={(e) => setRiskAreaId(e.target.value)}
                  value={riskAreaId}
                >
                  <option value="">Select Risk Categories</option>
                  {riskCategoryList?.map((item, index) => (
                    <option
                      selected={item.riskAreaId === item.categoryId}
                      key={index}
                      value={item.categoryId}
                    >
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Row>
                <Form.Group as={Col} md={6} className="mb-2">
                  <Form.Label className="fw-bold mb-1">
                    Risk Area Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={riskArea}
                    placeholder="Enter Risk Area Name"
                    onChange={(e) => setRiskArea(e.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} md={6} className="mb-2">
                  <Form.Label className="fw-bold mb-1">
                    Risk Area Label <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={areaLabel}
                    placeholder="Enter Risk Area Label"
                    onChange={(e) => setAreaLabel(e.target.value)}
                  />
                </Form.Group>
              </Row>
              <Form.Group className="mb-2">
                <div className="hstack justify-content-between">
                  <Form.Label className="mb-1 fw-bold">Indicators</Form.Label>
                  <div
                    title="Click to Add More Indocators value"
                    className="w-auto p-0 cursor-pointer"
                    onClick={handleAddIndicator}
                    style={{ fontSize: 11 }}
                  >
                    <i className="fa-solid fa-square-plus"></i> Add More
                  </div>
                </div>
                {id ? (
                  <>
                    {indicators.map((item, index) => (
                      <div className="hstack gap-2 mb-1" key={index}>
                        <Form.Control
                          required
                          type="text"
                          className="w-100"
                          value={item?.name}
                          onChange={(e) =>
                            handleIndicatorChange(index, "name", e.target.value)
                          }
                          placeholder="Enter Name"
                        />
                        <Form.Control
                          required
                          type="text"
                          className="w-100"
                          value={item?.label}
                          onChange={(e) =>
                            handleIndicatorChange(
                              index,
                              "label",
                              e.target.value
                            )
                          }
                          placeholder="Enter Label"
                        />
                        {indicators.length > 1 && (
                          <div
                            title="Delete this Row"
                            className="text-danger w-auto p-0 ms-2"
                            style={{ fontSize: 12 }}
                            onClick={() => handleRemoveIndicators(index)}
                          >
                            <i className="fa-solid fa-trash-alt"></i>
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {indicators.map((item, index) => (
                      <div className="hstack gap-2 mb-1" key={index}>
                        <Form.Control
                          required
                          type="text"
                          className="w-100"
                          value={item?.name}
                          onChange={(e) =>
                            handleIndicatorChange(index, "name", e.target.value)
                          }
                          placeholder="Enter Name"
                        />
                        <Form.Control
                          required
                          type="text"
                          className="w-100"
                          value={item?.label}
                          onChange={(e) =>
                            handleIndicatorChange(
                              index,
                              "label",
                              e.target.value
                            )
                          }
                          placeholder="Enter Label"
                        />
                        {indicators.length > 1 && (
                          <div
                            title="Delete this Row"
                            className="text-danger w-auto p-0 ms-2"
                            style={{ fontSize: 12, cursor: "pointer" }}
                            onClick={() => handleRemoveIndicators(index)}
                          >
                            <i className="fa-solid fa-trash-alt"></i>
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </Form.Group>
              <div className="text-end">
                <button
                  disabled={!riskArea || !riskAreaId}
                  className="submitbutton px-2 fontSize12"
                  onClick={() => (id ? updateRiskAreaData() : addNewRiskArea())}
                >
                  {loader ? (
                    <Spinner
                      animation="border"
                      style={{ width: 15, height: 15 }}
                      className="me-2"
                    />
                  ) : null}
                  {id ? "Update" : "Add"} Risk Area
                </button>
              </div>
            </div>
          )}
          {loader ? (
            <div className="hstack justify-content-center h-100">
              <Spinner />
            </div>
          ) : result?.length > 0 ? (
            <div>
              <Table id="resizable-table" striped className="m-0 custom-table">
                <thead className="thead-sticky">
                  <tr>
                    <th style={{ width: 55 }}>S No.</th>
                    <th>Risk category Name</th>
                    <th>Risk Area Name</th>
                    <th>Risk Area Label</th>
                    <th>Created Date | Time</th>
                    <th>Updated Date | Time</th>
                    <th className="text-center" style={{ width: 150 }}>
                      Indicators & Formule
                    </th>
                    <th className="text-center" style={{ width: 100 }}>
                      Notification
                    </th>
                    <th className="text-center" style={{ width: 70 }}>
                      Update
                    </th>
                    <th className="text-center" style={{ width: 70 }}>
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {result?.length > 0 &&
                    result?.map((item, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.categoryName}</td>
                        <td>{item.name}</td>
                        <td>{item.label}</td>
                        <td>
                          <Moment format="DD MMM YYYY | HH:mm" withTitle>
                            {item.createdAt}
                          </Moment>
                        </td>
                        <td>
                          <Moment format="DD MMM YYYY | HH:mm" withTitle>
                            {item.updatedAt}
                          </Moment>
                        </td>
                        <td className="text-center" title="View Formule">
                          <button
                            className="text-white border-primary bg-primary border-0 fs-10"
                            onClick={(e) => handleViewFormule(e, item)}
                          >
                            View
                          </button>
                        </td>
                        <td className="text-center" title="View Indicators">
                          <button
                            className="border-warning bg-warning border-0 fs-10"
                            onClick={(e) => handleViewNotifications(e, item)}
                          >
                            Settings
                          </button>
                        </td>
                        <td className=" text-center" title="Update Risk Area">
                          <button
                            className="text-white border-success bg-success border-0 fs-10"
                            onClick={(e) => handleUpdateComment(e, item)}
                          >
                            Update
                          </button>
                        </td>
                        <td className="text-center" title="Delete Risk Area">
                          <button
                            className="text-white border-danger bg-danger border-0 fs-10"
                            onClick={(e) => deleteRiskArea(e, item._id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <div className="position-sticky bottom-0">
                <Pagination
                  totalPage={totalPage}
                  pageNumber={pageNumber}
                  itemsPerPage={itemsPerPage}
                  totalItems={riskCategoryList?.length}
                  setPageNumber={setPageNumber}
                  setItemsPerPage={setItemsPerPage}
                />
              </div>
            </div>
          ) : (
            <div className="hstack justify-content-center h-100">
              <div className="noRecordFound">Risk Area List not found!</div>
            </div>
          )}
        </SplitPane>
      </SplitPane>
      <RiskAreaFilterData
        studyId={studyId}
        Show={!toggle}
        Hide={setToggle}
        Title="Select Risk Area"
      />
      <ViewNotification
        Show={!toggle1}
        Hide={setToggle1}
        Title="Notification"
        id={id}
        studyId={studyId}
        notificationData={notification || []}
        setNotificationData={setNotification}
        setLoader={setLoader}
        getRiskAreaList={getRiskAreaList}
        indicatorLength={indicatorList?.length || 0}
      />
      <ViewFormule
        Show={!toggle2}
        Hide={setToggle2}
        getRiskAreaList={getRiskAreaList}
        setLoader={setLoader}
        setFormuleValue={setFormuleValue}
        formuleValue={formuleValue}
        studyId={studyId}
        id={id}
        Title="View Formule"
        indicatorList={indicatorList}
      />
    </>
  );
};

export default RiskArea;
