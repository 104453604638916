import React, { useEffect, useState } from "react";
import { Alert, Form, Spinner } from "react-bootstrap";
import { LuDownload, LuImport } from "react-icons/lu";
import Swal from "sweetalert2";
import { FaPlusSquare } from "react-icons/fa";
import { cdiscServices } from "../../../../../../Services/CDISC/cdiscServices";
import { studyOnboardingService } from "../../../../../../Services/studyServices/studyOnboarding";

const ImportLegacy = ({
  studyId,
  getStudyTrailDataTemplates,
  crfDataId,
  setCRFDataId,
  domainId
}) => {
  const [selectFormat, setSelectFormat] = useState("");
  const [fileDatasetName, setFileDatasetName] = useState("");
  const [fileDatasetLabel, setFileDatasetLabel] = useState("");
  const [dataTemplate, setDataTemplate] = useState([]);
  const [dataTemplateId, setDataTemplateId] = useState("");
  const [uploadFile, setUploadFile] = useState(null);
  const [crfData, setCRFData] = useState("");
  const [loader, setLoader] = useState(false);
  const [statusCode, setStatusCode] = useState(0);
  const [customMessage, setCustomMessage] = useState("");

  const getTrailDesignDatasetData = async () => {
    let data = await cdiscServices.getTrailDesignDatasetData();
    setDataTemplate(data?.data);
  };

  const importTrailDataTemplateinStudy = async () => {
    setLoader(true);
    let userData = {};
    userData.studyId = studyId;
    userData.templateId = dataTemplateId;
    userData.domainId = domainId;
    userData.datasetName = fileDatasetName;
    userData.datasetLabel = fileDatasetLabel;
    let data = await cdiscServices.importTrailDataTemplateinStudy(userData);
    if (data?.statusCode === 200) {
      setLoader(false);
      getStudyTrailDataTemplates();
      setFileDatasetName("");
      setFileDatasetLabel("");
      setDataTemplateId("");
    } else {
      setLoader(false);
    }
    setStatusCode(data?.statusCode);
    setCustomMessage(data?.customMessage);
  };

  setTimeout(() => {
    setStatusCode(0);
    setCustomMessage("");
  }, 5000);

  const updateStudyTrailDataTemplates = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this CRF Data?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoader(true);
        let userData = {};
        userData.studyId = studyId;
        userData.id = crfDataId._id;
        userData.datasetName = fileDatasetName;
        userData.datasetLabel = fileDatasetLabel;
        let data = await cdiscServices.updateStudyTrailDataTemplates(userData);
        if (data?.statusCode === 200) {
          setLoader(false);
          getStudyTrailDataTemplates();
        } else {
          setLoader(false);
        }
        setStatusCode(data?.statusCode);
        setCustomMessage(data?.customMessage);
      }
    });
  };

  useEffect(() => {
    setFileDatasetName(crfDataId?.datasetName);
    setFileDatasetLabel(crfDataId?.datasetLabel);
  }, [crfDataId]);

  const clearFormData = () => {
    setCRFDataId("");
    setDataTemplateId("");
    setFileDatasetName("");
    setFileDatasetLabel("");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const csvType = file.type === "text/csv";
    const excelType =
      file.type === "application/vnd.ms-excel" ||
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (
      (selectFormat === "csv" && csvType) ||
      (selectFormat === "excel" && excelType)
    )
      setUploadFile(file);
    else {
      setUploadFile(null);
      alert(`Please select a ${selectFormat.toUpperCase()} file.`);
    }
  };

  const handleUploadCRFFile = async () => {
    if (uploadFile) {
      const formData = new FormData();
      formData.append("file", uploadFile);
      formData.append("name", fileDatasetName);
      formData.append("label", fileDatasetLabel);
      formData.append("studyId", studyId);
      let result = await cdiscServices.uploadFileinTrailData(formData);
      if (result?.statusCode === 200) {
        clearFormData();
        getStudyTrailDataTemplates();
      }
    }
  };

  const handleFormatChange = (format) => {
    setSelectFormat(format);
    setUploadFile(null);
  };

  const getSingleStudies = async () => {
    let data = await studyOnboardingService.getSingleStudies(studyId);
    setCRFData(data?.data?.[0]?.crfType || "");
  };

  useEffect(() => {
    getTrailDesignDatasetData();
    getSingleStudies();
  }, []);

  return (
    <div className="p-2">
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Template <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select

          aria-label="Default select example"
          onChange={(e) => setDataTemplateId(e.target.value)}
          required
        >
          <option value="">Select Template</option>
          {dataTemplate?.map((item, index) => (
            <option
              key={index}
              value={item._id}
              selected={item.dataTemplateId === item._id}
            >
              {item.title}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-2">
        <div className="hstack justify-content-between">
          <Form.Label className="mb-1 fw-bold">
            Dataset Name <span className="text-danger">*</span>
          </Form.Label>
          {crfDataId && (
            <button
              className=" text-white border-success bg-success border-0"
              style={{ fontSize: 10, borderRadius: 3 }}
              title="Add new Supplimentary"
              onClick={() => clearFormData()}
            >
              <FaPlusSquare style={{ marginBottom: 2 }} /> Add New
            </button>
          )}
        </div>
        <Form.Control

          type="text"
          placeholder="Enter File Dataset Name"
          onChange={(e) => {
            setFileDatasetName(e.target.value);
          }}
          value={fileDatasetName}
          required
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Dataset Label <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control

          type="text"
          placeholder="Enter File Dataset Label"
          onChange={(e) => {
            setFileDatasetLabel(e.target.value);
          }}
          value={fileDatasetLabel}
          required
        />
      </Form.Group>
      <Form.Group className="text-end mb-2">
        <button
          onClick={() =>
            crfDataId
              ? updateStudyTrailDataTemplates()
              : importTrailDataTemplateinStudy()
          }
          className="updateProfile"
        >
          {loader ? (
            <Spinner style={{ width: 12, height: 12 }} />
          ) : (
            <LuImport style={{ marginBottom: 2 }} />
          )}{" "}
          {crfDataId ? "Update" : "Import"}
        </button>
      </Form.Group>
      {customMessage && (
        <Alert variant={statusCode === 200 ? "success" : "danger"}>
          {customMessage}
        </Alert>
      )}
    </div>
  );
};

export default ImportLegacy;
