import React, { useEffect, useRef, useState } from "react";
import { HiMiniChevronDown } from "react-icons/hi2";
import { TiHomeOutline } from "react-icons/ti";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import Swal from "sweetalert2";
import DraggableTab from "./DraggableTab/DraggableTab.js";
import DnDContext from "./DraggableTab/DnDContext";
import TabHistory from "./DraggableTab/TabHistory.js";
import { RiMenuFoldFill, RiMenuUnfoldFill } from "react-icons/ri";

const EDCMainPanel = ({
  setStudyTabs,
  studyTabs,
  activeStudyTab,
  setCollpaseSidebar,
  collapseSidebar,
}) => {
  const [open, setOpen] = useState(false);
  const toggleRef = useRef(null);
  const tabContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(studyTabs?.length > 1);
  const handleActiveTab = (studyTabId) => {
    const updatedStudyTabs = studyTabs?.map((studyTab) => ({
      ...studyTab,
      status: "inactive",
    }));

    updatedStudyTabs.forEach((element) => {
      if (element.studyTabId === studyTabId) {
        element.status = "active";
      }
    });
    setStudyTabs(updatedStudyTabs);
  };

  const handleInactiveAllTab = () => {
    const updatedStudyTabs = studyTabs?.map((studyTab) => ({
      ...studyTab,
      status: "inactive",
    }));
    setStudyTabs(updatedStudyTabs);
  };

  const handleDeleteTab = (studyTabId) => {
    const updatedStudyTabs = studyTabs?.filter(
      (studyTab) => studyTab.studyTabId !== studyTabId
    );
    setStudyTabs(updatedStudyTabs);
  };

  const handleDeleteAllTab = () => {
    Swal.fire({
      title: "You want to Close all Tab",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setStudyTabs([]);
      }
    });
  };

  const handleCloseOtherTab = (item) => {
    Swal.fire({
      title: `You want to Close other Tab`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let updatedStudyTabs = { ...item, status: "active" };
        setStudyTabs([updatedStudyTabs]);
      }
    });
  };

  const handleCloseRightTabs = (index) => {
    Swal.fire({
      title: `You want to Close all Right Tab`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updatedStudyTabs = studyTabs?.slice(0, index + 1);
        const deletedArray = studyTabs?.slice(index + 1);
        const checkStatus = deletedArray?.some(
          (item) => item.status === "active"
        );
        if (checkStatus) {
          updatedStudyTabs[updatedStudyTabs?.length - 1].status = "active";
          setStudyTabs(updatedStudyTabs);
        } else {
          setStudyTabs(updatedStudyTabs);
        }
      }
    });
  };

  const handleCloseLeftTabs = (index) => {
    Swal.fire({
      title: `You want to Close all left Tab`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updatedStudyTabs = studyTabs?.slice(index);
        const deletedArray = studyTabs?.slice(0, index);
        const checkStatus = deletedArray?.some(
          (item) => item.status === "active"
        );
        if (checkStatus) {
          updatedStudyTabs[0].status = "active";
          setStudyTabs(updatedStudyTabs);
        } else {
          setStudyTabs(updatedStudyTabs);
        }
      }
    });
  };

  const handleMoveTab = (fromIndex, toIndex) => {
    setStudyTabs((prevTabs) => {
      const updatedStudyTabs = [...prevTabs];
      const [movedTab] = updatedStudyTabs.splice(fromIndex, 1);
      updatedStudyTabs.splice(toIndex, 0, movedTab);
      return updatedStudyTabs;
    });
  };

  const handleMoveTabLeft = (index) => {
    if (index > 0) {
      handleMoveTab(index, index - 1);
    }
  };

  const handleMoveTabRight = (index) => {
    if (index < studyTabs?.length - 1) {
      handleMoveTab(index, index + 1);
    }
  };

  const handleScrollTabLeft = () => {
    tabContainerRef?.current?.scrollBy({ left: -150, behavior: "smooth" });
    updateScrollState();
  };

  const handleScrollTabRight = () => {
    tabContainerRef?.current?.scrollBy({ left: 150, behavior: "smooth" });
    updateScrollState();
  };

  const updateScrollState = () => {
    const scrollLeft = tabContainerRef?.current?.scrollLeft;
    const scrollWidth = tabContainerRef?.current?.scrollWidth;
    const clientWidth = tabContainerRef?.current?.clientWidth;
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (toggleRef?.current && !toggleRef?.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <div
        ref={toggleRef}
        className="border-bottom tabbars hstack gap-2 px-0 pb-0 bg-light position-relative"
        style={{ padding: 5 }}
      >
        {collapseSidebar ? (
          <RiMenuUnfoldFill onClick={() => setCollpaseSidebar(false)} />
        ) : (
          <RiMenuFoldFill onClick={() => setCollpaseSidebar(true)} />
        )}
        {studyTabs?.length > 0 && (
          <HiMiniChevronDown
            onClick={() => setOpen(!open)}
            className={open && "activee"}
          />
        )}
        <TabHistory
          open={open}
          studyTabs={studyTabs}
          handleMoveTab={handleMoveTab}
          handleActiveTab={handleActiveTab}
          handleDeleteTab={handleDeleteTab}
          handleDeleteAllTab={handleDeleteAllTab}
        />
        <TiHomeOutline
          className={!activeStudyTab && "activee"}
          onClick={handleInactiveAllTab}
        />
        {studyTabs?.length >= 8 && (
          <MdOutlineKeyboardArrowLeft
            className={canScrollLeft && "activee"}
            onClick={handleScrollTabLeft}
            title="← Left Scroll"
          />
        )}
        <DnDContext>
          <div
            ref={tabContainerRef}
            onScroll={updateScrollState}
            className="hstack gap-2 w-100 overflow-auto blank"
          >
            {studyTabs?.map((item, index) => (
              <DraggableTab
                key={item.studyTabId}
                item={item}
                index={index}
                handleMoveTab={handleMoveTab}
                handleActiveTab={handleActiveTab}
                handleDeleteTab={handleDeleteTab}
                handleCloseOtherTab={handleCloseOtherTab}
                handleCloseRightTabs={handleCloseRightTabs}
                handleCloseLeftTabs={handleCloseLeftTabs}
                handleDeleteAllTab={handleDeleteAllTab}
                handleMoveTabLeft={handleMoveTabLeft}
                handleMoveTabRight={handleMoveTabRight}
              />
            ))}
          </div>
        </DnDContext>
        {studyTabs?.length >= 8 && (
          <MdOutlineKeyboardArrowRight
            className={canScrollRight && "activee"}
            onClick={handleScrollTabRight}
            title="Right Scroll →"
          />
        )}
      </div>
    </>
  );
};

export default EDCMainPanel;
