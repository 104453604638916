import React, { useEffect, useState } from "react";

const TabulationPercentageGraph = ({
    scoreType,
    startScore,
    endScore,
    boxColumn,
    value,
    handleScoreChange,
    required,
    disabled,
}) => {
    const [percentage, setPercentage] = useState();
    const numbers = Array.from(
        { length: Number(endScore || 0) },
        (_, i) => i + 1
    );

    const handleClick = (valueData) => {
        if (!disabled) {
            setPercentage(valueData);
            handleScoreChange(valueData);
        }
    };

    useEffect(() => {
        setPercentage(value);
    }, [value]);

    return (
        <div className="d-flex gap-2 mt-2">
            <div
                onClick={() => handleClick(0)}
                style={{
                    width: 30,
                    height: 30,
                    display: "flex",
                    fontSize: 12,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "15%",
                    cursor: disabled ? "no-drop" : "pointer",
                    color: disabled ? "#a2a1a1" : "#333",
                    fontWeight: disabled ? "normal" : "bold",
                    transition: "all 0.3s ease",
                    background: "rgb(224, 224, 224)",
                }}
            >
                0{scoreType === "percentage" && "%"}
            </div>
            <div className="hstack gap-5">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                    }}
                >
                    {Array.from(
                        { length: Math.ceil(numbers.length / Number(boxColumn || 5)) },
                        (_, colIndex) => (
                            <div
                                key={colIndex}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                }}
                            >
                                {numbers
                                    .slice(
                                        colIndex * Number(boxColumn || 5),
                                        colIndex * Number(boxColumn || 5) + Number(boxColumn || 5)
                                    )
                                    .map((num) => (
                                        <div
                                            key={num}
                                            onClick={() => handleClick(num)}
                                            style={{
                                                width: 30,
                                                height: 30,
                                                display: "flex",
                                                fontSize: 12,
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: "15%",
                                                cursor: disabled ? "no-drop" : "pointer",
                                                background:
                                                    percentage === num
                                                        ? `conic-gradient(#5cb2ed ${num * 3.6
                                                        }deg, #e0e0e0 ${num * 3.6}deg 360deg)`
                                                        : "#e0e0e0",
                                                color: disabled ? "#a2a1a1" : "#333",
                                                fontWeight: disabled ? "normal" : "bold",
                                                boxShadow:
                                                    percentage === num
                                                        ? "0 2px 4px rgba(0, 0, 0, 0.2)"
                                                        : "none",
                                                transition: "all 0.3s ease",
                                            }}
                                        >
                                            {(Number(startScore) || 0) + num}
                                            {scoreType === "percentage" && "%"}
                                        </div>
                                    ))}
                            </div>
                        )
                    )}
                </div>
                {(percentage || percentage === 0) && (
                    <div
                        style={{
                            position: "relative",
                            width: 180,
                            height: 180,
                            borderRadius: "15%",
                            background: `conic-gradient(#103c5e ${percentage * 3.6
                                }deg, #e0e0e0 ${percentage * 3.6}deg 360deg)`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <div
                            style={{
                                width: 120,
                                height: 120,
                                borderRadius: "15%",
                                background: "#fff",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: 24,
                                    fontWeight: "bold",
                                    color: "##ffffffe6",
                                }}
                            >
                                {percentage}{scoreType === "percentage" && "%"}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TabulationPercentageGraph;
