import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";

const HAQFormValues = ({ item, valueData, handleHAQFormChange, haqFormDataEntry, disabledData,
  tableRef,
  onMouseDown,
 }) => {
  const [isSpecify, setIsSpecify] = useState(false);
  const [isSpecified, setIsSpecified] = useState(false);
  const sections = [
    {
      title: "DRESSING & GROOMING",
      questions: [
        "Dress yourself, including tying shoelaces and doing buttons?",
        "Shampoo your hair?",
      ],
    },
    {
      title: "ARISING",
      questions: ["Stand up from a straight chair?", "Get in and out of bed?"],
    },
    {
      title: "EATING",
      questions: [
        "Cut your meat?",
        "Lift a full cup or glass to your mouth?",
        "Open a new milk carton?",
      ],
    },
    {
      title: "WALKING",
      questions: ["Walk outdoors on flat ground?", "Climb up five steps?"],
    },
  ];

  const sectionsOne = [
    {
      title: "HYGIENE",
      questions: [
        "Wash and dry your body?",
        "Take a tub bath?",
        "Get on and off the toilet? ",
      ],
    },
    {
      title: "REACH",
      questions: [
        "Reach and get down a 5-pound object (such as a bag of sugar) from just above your head? ",
        "Bend down to pick up clothing from the floor? ",
      ],
    },
    {
      title: "GRIP",
      questions: [
        "Open car doors? ",
        "Open jars which have been previously opened?",
        "Turn faucets on and off?",
      ],
    },
    {
      title: "ACTIVITIES",
      questions: [
        "Run errands and shop?",
        "Get in and out of a car? ",
        "Do chores such as vacuuming or yardwork?",
      ],
    },
  ];

  useEffect(() => {
    setIsSpecify((haqFormDataEntry?.[9]?.QSORRES?.includes("Other") || valueData?.[9]?.QSORRES?.includes("Other")));
    setIsSpecified((haqFormDataEntry?.[23]?.QSORRES?.includes("Other") || valueData?.[23]?.QSORRES?.includes("Other")));
  }, [haqFormDataEntry]);

  return (
    <>
      <Form.Label className="mb-1 d-block">
        In this section we are interested in learning how your illness affects
        your ability to function in daily life.
      </Form.Label>
      <Form.Label className="mb-1 fw-bold">
        Please check the response which best describes your usual abilities OVER
        THE PAST WEEK:
      </Form.Label>
      <div className="overflow-auto">
      <Table
        bordered
        ref={tableRef}
        className="custom-table tableLibrary border-start border-end mt-1"
      >
        <thead>
          <tr style={{ verticalAlign: "top" }}>
            <th className="p-1"></th>
            <th className="p-1">
              <Form.Label className="m-0">Without ANY difficulty</Form.Label>
            </th>
            <th className="p-1">
              <Form.Label className="m-0">With SOME difficulty</Form.Label>
            </th>
            <th className="p-1">
              <Form.Label className="m-0">With MUCH difficulty</Form.Label>
            </th>
            <th className="p-1">
              <Form.Label className="m-0">UNABLE to do</Form.Label>
            </th>
          </tr>
        </thead>
        <tbody>
          {sections.map((section, sectionIndex) => (
            <React.Fragment key={sectionIndex}>
              <tr>
                <td className="p-1 text-start fw-bold">
                  <Form.Label className="m-0">{section.title}</Form.Label>
                </td>
              </tr>
              <tr>
                <td className="p-1 text-start">
                  <Form.Label className="m-0">Are you able to:</Form.Label>
                </td>
              </tr>
              {section.questions.map((question, qIndex) => (
                <tr key={qIndex}>
                  <td className="p-1 text-start ps-3">
                    <Form.Label className="m-0">- {question}</Form.Label>
                  </td>
                  {[...Array(4)].map((_, optionIndex) => (
                    <td className="p-1" key={optionIndex}>
                      <Form.Check
                        value={optionIndex === 0 ? "Without ANY difficulty" : optionIndex === 1 ? "With SOME difficulty" : optionIndex === 2 ? "With MUCH difficulty" : "UNABLE to do"}
                        onChange={(e) =>
                          handleHAQFormChange(
                            "QSCAT",
                            section?.title,
                            "QSTEST",
                            question,
                            "QSORRES",
                            e.target.value,
                            sectionIndex === 0 ? (sectionIndex + qIndex) : sectionIndex === 1 ? (sectionIndex + qIndex + 1) : sectionIndex === 2 ? (sectionIndex + qIndex + 2) : (sectionIndex + qIndex + 4),
                            item,
                          )
                        }
                        // label={(optionIndex === 0 ? "Without ANY difficulty" : optionIndex === 1 ? "With SOME difficulty" : optionIndex === 2 ? "With MUCH difficulty" : "UNABLE to do") + "-" + (sectionIndex === 0 ? (sectionIndex + qIndex) : sectionIndex === 1 ? (sectionIndex + qIndex + 1) : sectionIndex === 2 ? (sectionIndex + qIndex + 2) : (sectionIndex + qIndex + 4))}
                        disabled={disabledData}
                        checked={
                          (
                            haqFormDataEntry?.[sectionIndex === 0 ? (sectionIndex + qIndex) : sectionIndex === 1 ? (sectionIndex + qIndex + 1) : sectionIndex === 2 ? (sectionIndex + qIndex + 2) : (sectionIndex + qIndex + 4)]?.QSORRES ||
                            valueData?.[sectionIndex === 0 ? (sectionIndex + qIndex) : sectionIndex === 1 ? (sectionIndex + qIndex + 1) : sectionIndex === 2 ? (sectionIndex + qIndex + 2) : (sectionIndex + qIndex + 4)]?.QSORRES
                          ) ===
                          (optionIndex === 0
                            ? "Without ANY difficulty"
                            : optionIndex === 1
                              ? "With SOME difficulty"
                              : optionIndex === 2
                                ? "With MUCH difficulty"
                                : "UNABLE to do")
                        }
                        type="radio"
                        name={`radioGroup-${sectionIndex}-${qIndex}-1`}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      </div>
      <Form.Label className="mb-1 fw-bold">
        Please check any AIDS OR DEVICES that you usually use for any of these
        activities:
      </Form.Label>
      <Container fluid>
        <Row>
          <Form.Group as={Col} md={3}>
            <Form.Check
              type="checkbox"
              label="Cane"
              value="Cane"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sections.map(section => section.title).join(" / "),
                  "QSTEST",
                  "AIDS OR DEVICES",
                  "QSORRES",
                  e.target.value,
                  9,
                  item,
                  "checkbox"
                )
              }
              disabled={disabledData}
              checked={haqFormDataEntry?.[9]?.QSORRES?.includes("Cane") || valueData?.[9]?.QSORRES?.includes("Cane")}
            />
            <Form.Check
              type="checkbox"
              label="Walker"
              value="Walker"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sections.map(section => section.title).join(" / "),
                  "QSTEST",
                  "AIDS OR DEVICES",
                  "QSORRES",
                  e.target.value,
                  9,
                  item,
                  "checkbox"
                )
              }
              disabled={disabledData}
              checked={haqFormDataEntry?.[9]?.QSORRES?.includes("Walker") || valueData?.[9]?.QSORRES?.includes("Walker")}
            />
            <Form.Check
              type="checkbox"
              label="Crutches"
              value="Crutches"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sections.map(section => section.title).join(" / "),
                  "QSTEST",
                  "AIDS OR DEVICES",
                  "QSORRES",
                  e.target.value,
                  9,
                  item,
                  "checkbox"
                )
              }
              disabled={disabledData}
              checked={haqFormDataEntry?.[9]?.QSORRES?.includes("Crutches") || valueData?.[9]?.QSORRES?.includes("Crutches")}
            />
            <Form.Check
              type="checkbox"
              label="Wheelchair"
              value="Wheelchair"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sections.map(section => section.title).join(" / "),
                  "QSTEST",
                  "AIDS OR DEVICES",
                  "QSORRES",
                  e.target.value,
                  9,
                  item,
                  "checkbox"
                )
              }
              disabled={disabledData}
              checked={haqFormDataEntry?.[9]?.QSORRES?.includes("Wheelchair") || valueData?.[9]?.QSORRES?.includes("Wheelchair")}
            />
          </Form.Group>
          <Form.Group as={Col} md={9}>
            <Form.Check
              type="checkbox"
              label="Devices used for dressing (button hook, zipper pull, long-handled shoe horn, etc.)"
              value="Devices used for dressing (button hook, zipper pull, long-handled shoe horn, etc.)"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sections.map(section => section.title).join(" / "),
                  "QSTEST",
                  "AIDS OR DEVICES",
                  "QSORRES",
                  e.target.value,
                  9,
                  item,
                  "checkbox"
                )
              }
              disabled={disabledData}
              checked={haqFormDataEntry?.[9]?.QSORRES?.includes("Devices used for dressing (button hook, zipper pull, long-handled shoe horn, etc.)") || valueData?.[9]?.QSORRES?.includes("Devices used for dressing (button hook, zipper pull, long-handled shoe horn, etc.)")}
            />
            <Form.Check
              type="checkbox"
              label="Built up or special utensils"
              value="Built up or special utensils"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sections.map(section => section.title).join(" / "),
                  "QSTEST",
                  "AIDS OR DEVICES",
                  "QSORRES",
                  e.target.value,
                  9,
                  item,
                  "checkbox"
                )
              }
              disabled={disabledData}
              checked={haqFormDataEntry?.[9]?.QSORRES?.includes("Built up or special utensils") || valueData?.[9]?.QSORRES?.includes("Built up or special utensils")}
            />
            <Form.Check
              type="checkbox"
              label="Special or built up chair"
              value="Special or built up chair"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sections.map(section => section.title).join(" / "),
                  "QSTEST",
                  "AIDS OR DEVICES",
                  "QSORRES",
                  e.target.value,
                  9,
                  item,
                  "checkbox"
                )
              }
              disabled={disabledData}
              checked={haqFormDataEntry?.[9]?.QSORRES?.includes("Special or built up chair") || valueData?.[9]?.QSORRES?.includes("Special or built up chair")}
            />
            <Form.Check
              type="checkbox"
              label="Other (Specify: ______________)"
              value="Other"
              onChange={(e) => {
                handleHAQFormChange(
                  "QSCAT",
                  sections.map(section => section.title).join(" / "),
                  "QSTEST",
                  "AIDS OR DEVICES",
                  "QSORRES",
                  e.target.value,
                  9,
                  item,
                  "checkbox"
                ); setIsSpecify(!isSpecify)
              }}
              disabled={disabledData}
              checked={haqFormDataEntry?.[9]?.QSORRES?.includes("Other") || valueData?.[9]?.QSORRES?.includes("Other")}
            />
            {isSpecify && (
              <Form.Control
                as={`textarea`}
                rows={2}
                placeholder="Type Here"
                value={haqFormDataEntry?.[10]?.QSORRES || valueData?.[10]?.QSORRES}
                onChange={(e) =>
                  handleHAQFormChange(
                    "QSCAT",
                    sections.map(section => section.title).join(" / "),
                    "QSTEST",
                    "AIDS OR DEVICES OTHER",
                    "QSORRES",
                    e.target.value,
                    10,
                    item,
                  )
                }
                disabled={disabledData}
              />
            )}
          </Form.Group>
        </Row>
      </Container>
      <Form.Label className="mb-1 fw-bold">
        Please check any categories for which you usually need HELP FROM ANOTHER
        PERSON:
      </Form.Label>
      <Container fluid>
        <Row>
          <Form.Group as={Col} md={3}>
            <Form.Check
              type="checkbox"
              label="Dressing and Grooming"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sections.map(section => section.title).join(" / "),
                  "QSTEST",
                  "HELP FROM ANOTHER PERSON",
                  "QSORRES",
                  e.target.value,
                  11,
                  item,
                  "checkbox"
                )
              }
              checked={haqFormDataEntry?.[11]?.QSORRES?.includes("Dressing and Grooming") || valueData?.[11]?.QSORRES?.includes("Dressing and Grooming")}
              disabled={disabledData}
            />
            <Form.Check
              type="checkbox"
              label="Arising"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sections.map(section => section.title).join(" / "),
                  "QSTEST",
                  "HELP FROM ANOTHER PERSON",
                  "QSORRES",
                  e.target.value,
                  11,
                  item,
                  "checkbox"
                )
              }
              checked={haqFormDataEntry?.[11]?.QSORRES?.includes("Arising") || valueData?.[11]?.QSORRES?.includes("Arising")}
              disabled={disabledData}
            />
          </Form.Group>
          <Form.Group as={Col} md={9}>
            <Form.Check
              type="checkbox"
              label="Devices used for dressing (button hook, zipper pull, long-handled shoe horn, etc.)"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sections.map(section => section.title).join(" / "),
                  "QSTEST",
                  "HELP FROM ANOTHER PERSON",
                  "QSORRES",
                  e.target.value,
                  11,
                  item,
                  "checkbox"
                )
              }
              checked={haqFormDataEntry?.[11]?.QSORRES?.includes("Devices used for dressing (button hook, zipper pull, long-handled shoe horn, etc.)") || valueData?.[11]?.QSORRES?.includes("Devices used for dressing (button hook, zipper pull, long-handled shoe horn, etc.)")}
              disabled={disabledData}
            />
            <Form.Check
              type="checkbox"
              label="Eating"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sections.map(section => section.title).join(" / "),
                  "QSTEST",
                  "HELP FROM ANOTHER PERSON",
                  "QSORRES",
                  e.target.value,
                  11,
                  item,
                  "checkbox"
                )
              }
              checked={haqFormDataEntry?.[11]?.QSORRES?.includes("Eating") || valueData?.[11]?.QSORRES?.includes("Eating")}
              disabled={disabledData}
            />
            <Form.Check
              type="checkbox"
              label="Walking"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sections.map(section => section.title).join(" / "),
                  "QSTEST",
                  "HELP FROM ANOTHER PERSON",
                  "QSORRES",
                  e.target.value,
                  11,
                  item,
                  "checkbox"
                )
              }
              checked={haqFormDataEntry?.[11]?.QSORRES?.includes("Walking") || valueData?.[11]?.QSORRES?.includes("Walking")}
              disabled={disabledData}
            />
          </Form.Group>
        </Row>
      </Container>

      <Form.Label className="mb-1 mt-3 d-block">
        Please check the response which best describes your usual abilities OVER
        THE PAST WEEK:
      </Form.Label>
      <div className="overflow-auto">
      <Table
        bordered
        ref={tableRef}
        className="custom-table tableLibrary border-start border-end mt-1"
      >
        <thead>
          <tr style={{ verticalAlign: "top" }}>
            <th className="p-1"></th>
            <th className="p-1">
              <Form.Label className="m-0">Without ANY difficulty</Form.Label>
            </th>
            <th className="p-1">
              <Form.Label className="m-0">With SOME difficulty</Form.Label>
            </th>
            <th className="p-1">
              <Form.Label className="m-0">With MUCH difficulty</Form.Label>
            </th>
            <th className="p-1">
              <Form.Label className="m-0">UNABLE to do</Form.Label>
            </th>
          </tr>
        </thead>
        <tbody>
          {sectionsOne.map((section, sectionIndex) => (
            <React.Fragment key={sectionIndex}>
              <tr>
                <td className="p-1 text-start fw-bold">
                  <Form.Label className="m-0">{section.title}</Form.Label>
                </td>
              </tr>
              <tr>
                <td className="p-1 text-start">
                  <Form.Label className="m-0">Are you able to:</Form.Label>
                </td>
              </tr>
              {section.questions.map((question, qIndex) => (
                <tr key={qIndex}>
                  <td className="p-1 text-start ps-3">
                    <Form.Label className="m-0">- {question}</Form.Label>
                  </td>
                  {[...Array(4)].map((_, optionIndex) => (
                    <td className="p-1" key={optionIndex}>
                      <Form.Check
                        value={optionIndex === 0 ? "Without ANY difficulty" : optionIndex === 1 ? "With SOME difficulty" : optionIndex === 2 ? "With MUCH difficulty" : "UNABLE to do"}
                        onChange={(e) =>
                          handleHAQFormChange(
                            "QSCAT",
                            section?.title,
                            "QSTEST",
                            question,
                            "QSORRES",
                            e.target.value,
                            sectionIndex === 0 ? (sectionIndex + qIndex + 12) : sectionIndex === 1 ? (sectionIndex + qIndex + 14) : sectionIndex === 2 ? (sectionIndex + qIndex + 15) : (sectionIndex + qIndex + 17),
                            item,
                          )
                        }
                        checked={
                          (
                            haqFormDataEntry?.[sectionIndex === 0 ? (sectionIndex + qIndex + 12) : sectionIndex === 1 ? (sectionIndex + qIndex + 14) : sectionIndex === 2 ? (sectionIndex + qIndex + 15) : (sectionIndex + qIndex + 17)]?.QSORRES ||
                            valueData?.[sectionIndex === 0 ? (sectionIndex + qIndex + 12) : sectionIndex === 1 ? (sectionIndex + qIndex + 14) : sectionIndex === 2 ? (sectionIndex + qIndex + 15) : (sectionIndex + qIndex + 17)]?.QSORRES
                          ) ===
                          (optionIndex === 0
                            ? "Without ANY difficulty"
                            : optionIndex === 1
                              ? "With SOME difficulty"
                              : optionIndex === 2
                                ? "With MUCH difficulty"
                                : "UNABLE to do")
                        }
                        // label={(optionIndex === 0 ? "Without ANY difficulty" : optionIndex === 1 ? "With SOME difficulty" : optionIndex === 2 ? "With MUCH difficulty" : "UNABLE to do") + "-" + (sectionIndex === 0 ? (sectionIndex + qIndex + 11) : sectionIndex === 1 ? (sectionIndex + qIndex + 13) : sectionIndex === 2 ? (sectionIndex + qIndex + 14) : (sectionIndex + qIndex + 16))}
                        disabled={disabledData}
                        type="radio"
                        name={`radioGroup-${sectionIndex}-${qIndex}-2`}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      </div>
      <Form.Label className="mb-1 fw-bold">
        Please check any AIDS OR DEVICES that you usually use for any of these
        activities:
      </Form.Label>
      <Container fluid>
        <Row>
          <Form.Group as={Col} md={3}>
            <Form.Check
              type="checkbox"
              label="Raised toilet seat"
              value="Raised toilet seat"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sectionsOne.map(section => section.title).join(" / "),
                  "QSTEST",
                  "AIDS OR DEVICES",
                  "QSORRES",
                  e.target.value,
                  23,
                  item,
                  "checkbox"
                )
              }
              disabled={disabledData}
              checked={haqFormDataEntry?.[23]?.QSORRES?.includes("Raised toilet seat") || valueData?.[23]?.QSORRES?.includes("Raised toilet seat")}
            />
            <Form.Check
              type="checkbox"
              label="Bathtub seat"
              value="Bathtub seat"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sectionsOne.map(section => section.title).join(" / "),
                  "QSTEST",
                  "AIDS OR DEVICES",
                  "QSORRES",
                  e.target.value,
                  23,
                  item,
                  "checkbox"
                )
              }
              disabled={disabledData}
              checked={haqFormDataEntry?.[23]?.QSORRES?.includes("Bathtub seat") || valueData?.[23]?.QSORRES?.includes("Bathtub seat")}
            />
            <Form.Check
              type="checkbox"
              label="Jar opener (for jars previously opened)"
              value="Jar opener (for jars previously opened)"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sectionsOne.map(section => section.title).join(" / "),
                  "QSTEST",
                  "AIDS OR DEVICES",
                  "QSORRES",
                  e.target.value,
                  23,
                  item,
                  "checkbox"
                )
              }
              disabled={disabledData}
              checked={haqFormDataEntry?.[23]?.QSORRES?.includes("Jar opener (for jars previously opened)") || valueData?.[23]?.QSORRES?.includes("Jar opener (for jars previously opened)")}
            />
          </Form.Group>
          <Form.Group as={Col} md={9}>
            <Form.Check
              type="checkbox"
              label="Bathtub bar"
              value="Bathtub bar"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sectionsOne.map(section => section.title).join(" / "),
                  "QSTEST",
                  "AIDS OR DEVICES",
                  "QSORRES",
                  e.target.value,
                  23,
                  item,
                  "checkbox"
                )
              }
              disabled={disabledData}
              checked={haqFormDataEntry?.[23]?.QSORRES?.includes("Bathtub bar") || valueData?.[23]?.QSORRES?.includes("Bathtub bar")}
            />
            <Form.Check
              type="checkbox"
              label="Long-handled appliances for reach"
              value="Long-handled appliances for reach"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sectionsOne.map(section => section.title).join(" / "),
                  "QSTEST",
                  "AIDS OR DEVICES",
                  "QSORRES",
                  e.target.value,
                  23,
                  item,
                  "checkbox"
                )
              }
              disabled={disabledData}
              checked={haqFormDataEntry?.[23]?.QSORRES?.includes("Long-handled appliances for reach") || valueData?.[23]?.QSORRES?.includes("Long-handled appliances for reach")}
            />
            <Form.Check
              type="checkbox"
              label="Long-handled appliances in bathroom"
              value="Long-handled appliances in bathroom"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sectionsOne.map(section => section.title).join(" / "),
                  "QSTEST",
                  "AIDS OR DEVICES",
                  "QSORRES",
                  e.target.value,
                  23,
                  item,
                  "checkbox"
                )
              }
              disabled={disabledData}
              checked={haqFormDataEntry?.[23]?.QSORRES?.includes("Long-handled appliances in bathroom") || valueData?.[23]?.QSORRES?.includes("Long-handled appliances in bathroom")}
            />
            <Form.Check
              type="checkbox"
              label="Other (Specify: ______________)"
              value="Other"
              onChange={(e) => {
                handleHAQFormChange(
                  "QSCAT",
                  sectionsOne.map(section => section.title).join(" / "),
                  "QSTEST",
                  "AIDS OR DEVICES",
                  "QSORRES",
                  e.target.value,
                  23,
                  item,
                  "checkbox"
                ); setIsSpecified(!isSpecified)
              }}
              disabled={disabledData}
              checked={haqFormDataEntry?.[23]?.QSORRES?.includes("Other") || valueData?.[23]?.QSORRES?.includes("Other")}
            />
            {isSpecified && (
              <Form.Control
                as={`textarea`}
                rows={2}
                placeholder="Type Here"
                value={haqFormDataEntry?.[24]?.QSORRES || valueData?.[24]?.QSORRES}
                onChange={(e) =>
                  handleHAQFormChange(
                    "QSCAT",
                    sectionsOne.map(section => section.title).join(" / "),
                    "QSTEST",
                    "AIDS OR DEVICES OTHER",
                    "QSORRES",
                    e.target.value,
                    24,
                    item,
                  )
                }
                disabled={disabledData}
              />
            )}
          </Form.Group>
        </Row>
      </Container>
      <Form.Label className="mb-1 fw-bold">
        Please check any categories for which you usually need HELP FROM ANOTHER
        PERSON:
      </Form.Label>
      <Container fluid>
        <Row>
          <Form.Group as={Col} md={3}>
            <Form.Check
              type="checkbox"
              label="Hygiene"
              value="Hygiene"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sectionsOne.map(section => section.title).join(" / "),
                  "QSTEST",
                  "HELP FROM ANOTHER PERSON",
                  "QSORRES",
                  e.target.value,
                  25,
                  item,
                  "checkbox"
                )
              }
              disabled={disabledData}
              checked={haqFormDataEntry?.[25]?.QSORRES?.includes("Hygiene") || valueData?.[25]?.QSORRES?.includes("Hygiene")}
            />
            <Form.Check
              type="checkbox"
              label="Reach"
              value="Reach"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sectionsOne.map(section => section.title).join(" / "),
                  "QSTEST",
                  "HELP FROM ANOTHER PERSON",
                  "QSORRES",
                  e.target.value,
                  25,
                  item,
                  "checkbox"
                )
              }
              disabled={disabledData}
              checked={haqFormDataEntry?.[25]?.QSORRES?.includes("Reach") || valueData?.[25]?.QSORRES?.includes("Reach")}
            />
          </Form.Group>
          <Form.Group as={Col} md={9}>
            <Form.Check
              type="checkbox"
              label="Gripping and opening things"
              value="Gripping and opening things"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sectionsOne.map(section => section.title).join(" / "),
                  "QSTEST",
                  "HELP FROM ANOTHER PERSON",
                  "QSORRES",
                  e.target.value,
                  25,
                  item,
                  "checkbox"
                )
              }
              disabled={disabledData}
              checked={haqFormDataEntry?.[25]?.QSORRES?.includes("Gripping and opening things") || valueData?.[25]?.QSORRES?.includes("Gripping and opening things")}
            />
            <Form.Check
              type="checkbox"
              label="Errands and chores"
              value="Errands and chores"
              onChange={(e) =>
                handleHAQFormChange(
                  "QSCAT",
                  sectionsOne.map(section => section.title).join(" / "),
                  "QSTEST",
                  "HELP FROM ANOTHER PERSON",
                  "QSORRES",
                  e.target.value,
                  25,
                  item,
                  "checkbox"
                )
              }
              disabled={disabledData}
              checked={haqFormDataEntry?.[25]?.QSORRES?.includes("Errands and chores") || valueData?.[25]?.QSORRES?.includes("Errands and chores")}
            />
          </Form.Group>
        </Row>
      </Container>
    </>
  );
};

export default HAQFormValues;
