import React from "react";
import { Handle, Position } from "@xyflow/react";
import { IoEyeOutline, IoSettingsOutline } from "react-icons/io5";
import { FaRegPlayCircle, FaRegTrashAlt } from "react-icons/fa";
import { GrHistory } from "react-icons/gr";
import { useParams } from "react-router";
import { cdiscServices } from "../../../../../../Services/CDISC/cdiscServices";

const CustomNode = ({ data }) => {
  const { studyId } = useParams()
  const derivationCRFData = async (type) => {
    let userData = {}
    userData.studyId = studyId
    userData.label = data?.label
    userData.derivationId = data?.id
    userData.primaryDataType = data?.primaryDataType
    userData.primaryDataset = data?.primaryDataset
    userData.secondaryDataType = data?.secondaryDataType
    userData.secondaryDataset = data?.secondaryDataset
    userData.primaryKey = data?.primaryKey
    userData.procedureType = data?.procedureType
    userData.cellDataset = data?.cellDataset
    userData.newColumnsCustomValue = data?.newColumnsCustomValue || []
    userData.newColumnsColumnValue = data?.newColumnsColumnValue || []
    userData.newColumnsFunctionValue = data?.newColumnsFunctionValue || []
    userData.customManipulation = data?.customManipulation || []
    userData.columnManipulation = data?.columnManipulation || []
    userData.functionManipulation = data?.functionManipulation || []
    if (data?.procedureType === "sort") {
      userData.sortData = data?.sortData
    } else if (data?.procedureType === "transpose") {
      userData.idVar = data?.idVar?.map(item => item.column)
      userData.byVar = data?.byVar?.map(item => item.column)
      userData.vars = data?.vars?.map(item => item.column)
      userData.prefix = data?.prefix
      userData.suffix = data?.suffix
    }
    if (data?.keepColumns?.length > 0 || data?.keepCustoms) {
      let keepCustoms = data?.keepCustoms?.split("||")?.map(key => ({
        label: key.trim(),
        value: key.trim(),
      })) || []
      userData.keepColumns = [...data?.keepColumns, ...keepCustoms] || []
    }
    if (data?.dropColumns?.length > 0 || data?.dropCustoms) {
      let dropCustoms = data?.dropCustoms?.split("||")?.map(key => ({
        label: key.trim(),
        value: key.trim(),
      })) || []
      userData.dropColumns = [...data?.dropColumns, ...dropCustoms] || []
    }
    if (data?.retainCustoms || data?.retainColumns) {
      let retainData = data?.retainCustoms + "||" + data?.retainColumns
      let retainArray = retainData?.split("||")?.map(item => item.trim()) || []
      userData.retain = retainArray?.filter(item => item !== "")
    }
    userData.statements = data?.statements.filter(item => item.type !== "end")
    userData.type = !type
    let result = await cdiscServices.derivationCRFData(userData)
    await data?.getDomainDerivationList()
    if (type && result?.foundStatus?.statusCode === 200) {
      data?.addTabs(
        `${result?.data?.label || "Datasets"}`,
        "fa-solid fa-circle-nodes",
        `derivedDatasets_${result?.data?.derivationId}`,
        {derivationId: result?.data?.derivationId}
      )
    }
  }
  return (
    <>
      <div className={`custom-node border rounded-1 py-2 px-3 position-relative bg-white ${data?.logStatus === 200 ? "border-success" : data?.logStatus === 400 ? "border-danger" : "border-secondary"}`}>
        <Handle
          type="target"
          position={Position.Left}
          style={{ background: data?.logStatus === 200 ? "#198754" : data?.logStatus === 400 ? "#dc3545" : "#555" }}
        />
        <div className={data?.logStatus === 200 ? "text-success" : data?.logStatus === 400 ? "text-danger" : "text-secondary"} style={{ fontSize: 8, fontWeight: 600 }}>{data.label}</div>
        <Handle
          type="source"
          position={Position.Right}
          style={{ background: data?.logStatus === 200 ? "#198754" : data?.logStatus === 400 ? "#dc3545" : "#555" }}
        />
        <div
          className="position-absolute action-icons"
          style={{ top: -17, right: 0 }}
        >
          <button
            className="border-0 bg-white p-0 pe-1 text-primary"
            title="Configuration"
            style={{ fontSize: 7 }}
            onClick={() => {
              data?.setIsOpenProperty(true);
              data?.setDerivationObject(data);
            }}
          >
            <IoSettingsOutline />
          </button>
          {data?.primaryDataType &&
            <>
              <button
                className="border-0 bg-white p-0 pe-1"
                title="Run"
                style={{ fontSize: 7, }}
                onClick={() => derivationCRFData(false)}
              >
                <FaRegPlayCircle className="text-success" />
              </button>

              <button
                className="border-0 bg-white p-0 pe-1"
                title="View"
                style={{ fontSize: 8 }}
                onClick={() => derivationCRFData(true)}
              >
                <IoEyeOutline />
              </button>
            </>
          }
          <button
            className="border-0 bg-white p-0 pe-1"
            title={data?.logs || "Please First Run"}
            style={{ fontSize: 6 }}
          >
            <GrHistory className="text-warning" />
          </button>
          <button
            className="border-0 bg-white p-0"
            title="Delete This Section"
            style={{ fontSize: 6 }}
            onClick={() => data?.onRemove(data?.id)}
          >
            <FaRegTrashAlt className="text-danger" />
          </button>
        </div>
      </div>
    </>
  );
};

export default CustomNode;
