import React, { useEffect, useRef } from "react";
import { Table } from "react-bootstrap";
import DynamicRuler from "./DynamicRuler";
import CdrgData from "../../../CDRG/CdrgData";
import CDRGPdfDataDetails from "../../CDRGPdfDataDetails";
import "../../../../../FormReport/CommonDesign/print.css";

const EditorWritingPage = ({
  size,
  margin,
  showFooter,
  showHeader,
  orientation,
  isFullScreen,
  componentRef,
  imageToInsert,
  emojiToInsert,
  handleLinePositionChange,
}) => {
  const editorRef = useRef(null);
  const editorStyle = { width: "100%" };

  const marginStyle = {
    paddingTop: margin.top,
    paddingBottom: margin.bottom,
    paddingLeft: margin.left,
    paddingRight: margin.right,
  };

  const pageStyle = {
    width: orientation === "portrait" ? size.width : size.height,
    height: orientation === "portrait" ? size.height : size.width,
    marginBottom: margin.bottom,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  };

  const contentStyle = {
    ...marginStyle,
    flexGrow: 1,
  };

  const insertImageAtCaret = (imageSrc) => {
    const img = document.createElement("img");
    img.src = imageSrc;
    img.style.maxWidth = "100%";
    img.style.maxHeight = "100%";

    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      range.insertNode(img);
      range.collapse(false);
    }
  };

  useEffect(() => {
    if (imageToInsert) {
      insertImageAtCaret(imageToInsert);
    }
  }, [imageToInsert]);

  const insertEmojiAtCaret = (emoji) => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const emojiNode = document.createTextNode(emoji);
      range.insertNode(emojiNode);
      range.collapse(false);
    }
  };

  useEffect(() => {
    if (emojiToInsert) {
      insertEmojiAtCaret(emojiToInsert);
    }
  }, [emojiToInsert]);

  return (
    <>
      <DynamicRuler
        orientation={orientation}
        size={size}
        onLinePositionChange={handleLinePositionChange}
      />
      <div
        style={{
          height: isFullScreen ? "calc(100vh - 130px)" : "calc(100vh - 285px)",
          overflow: "auto",
        }}
      >
        <div className="text-editor text-editor-container" ref={editorRef}>
          <div
            contentEditable={true}
            style={{
              border: "1px solid #eee",
              borderRadius: 3,
              outline: "none",
              minHeight: "11in",
              ...editorStyle,
              ...pageStyle,
            }}
            suppressContentEditableWarning={true}
          >
            <div ref={componentRef}>
              {showHeader && (
                <div
                  className="singleFormHeader w-100"
                  style={{
                    ...contentStyle,
                    borderBottom: "1px dashed #eee",
                  }}
                  contentEditable={true}
                >
                  <div className="d-flex justify-content-between">
                    <p className="m-0 fw-bold">Study 039-24</p>
                    <p className="m-0 fw-bold">
                      Clinical Study Data Reviewer’s Guide
                    </p>
                  </div>
                </div>
              )}
              <div style={{ ...contentStyle }}>
                <Table>
                  {showHeader && (
                    <thead>
                      <tr className="spacingHeader">
                        <th className="border-0">
                          <div className="t-head">&nbsp;</div>
                          <div className="t-head">&nbsp;</div>
                          <div className="t-head">&nbsp;</div>
                        </th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    <tr>
                      <td className="border-0 p-0">
                        <div className="content">
                          <div className="page">
                            <CdrgData />
                          </div>
                          <div className="pages">
                            <CDRGPdfDataDetails />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  {showFooter && (
                    <tfoot>
                      <tr className="spacingHeader">
                        <td className="border-0 p-0">
                          <div className="t-foot">&nbsp;</div>
                          <div className="t-foot">&nbsp;</div>
                          <div className="t-foot">&nbsp;</div>
                          <div className="t-foot">&nbsp;</div>
                        </td>
                      </tr>
                    </tfoot>
                  )}
                </Table>
              </div>
              {showFooter && (
                <div
                  className="footer"
                  style={{
                    ...contentStyle,
                    borderTop: "1px dashed #eee",
                  }}
                >
                  <div className="hstack justify-content-between">
                    <div>
                      <p className="m-0 fontSize12" contentEditable={true}>
                        This is an electronically authenticated report generated
                        by Galaaxx
                      </p>
                      <p className="m-0 fontSize12" contentEditable={true}>
                        Confidencials
                      </p>
                    </div>
                    <p className="m-0 fontSize12" style={{ pointerEvents: "none" }}>
                      Page : 1 of 1
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditorWritingPage;
