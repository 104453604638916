import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { cdrgStudyServices } from "../../../../../Services/CDISC/cdrgStudyServices";

const AddTrialDesignDetasets = ({ fields, setFields, studyId, title, setTitle, description, setDescription, setTrailDesignId, getStudyTrailDesignDatasetData, trailDesignId, cleanInputBox }) => {
  const [type, setType] = useState("");
  const [loader, setLoader] = useState("");
  const [trialId, setTrialId] = useState("");
  const [trailDesignData, setTrailDesignData] = useState([]);

  const getActiveTrailDesignDataset = async () => {
    let data = await cdrgStudyServices.getActiveTrailDesignDataset();
    setTrailDesignData(data?.data);
  };

  const addNewStudyTrailDesignDataset = async () => {
    setLoader(true);
    let userData = {};
    userData.studyId = studyId
    userData.trialId = trialId;
    userData.title = title;
    userData.description = description;
    userData.columns = fields;
    let data = await cdrgStudyServices.addNewStudyTrailDesignDataset(userData);
    if (data?.statusCode === 200) {
      getStudyTrailDesignDatasetData();
      cleanInputBox("");
      setLoader(false);
    }
    setLoader(false);
  };

  const handleUpdate = async () => {
    setLoader(true);
    let userData = {};
    userData.studyId = studyId
    userData.id = trailDesignId?._id
    userData.title = title;
    userData.description = description;
    userData.columns = fields;
    let data = await cdrgStudyServices.updateStudyTrailDesignDatasetData(userData);
    if (data?.statusCode === 200) {
      getStudyTrailDesignDatasetData();
      setTrailDesignId("");
      cleanInputBox("");
      setLoader(false);
    }
    setLoader(false);
  };

  const handleInputChange = (index, event) => {
    const values = [...fields];
    values[index][event.target.name] = event.target.value;
    setFields(values);
  };

  const handleAddField = () => {
    setFields([...fields, { name: "", label: "" }]);
  };

  const handleRemoveField = (index) => {
    const values = [...fields];
    values.splice(index, 1);
    setFields(values);
  };

  useEffect(() => {
    if (trailDesignId) {
      setType("custom");
      setTitle(trailDesignId.title)
      setDescription(trailDesignId.description)
      setFields([{ name: trailDesignId.name, label: trailDesignId.label }])
    }
  }, [trailDesignId]);

  useEffect(() => {
    getActiveTrailDesignDataset();
  }, []);

  return (
    <div className="p-2">
      <Form.Group className="mb-2">
        <div className="hstack justify-content-between">
          <Form.Label className="fw-bold mb-1">
            Type <span className="text-danger">*</span>
          </Form.Label>
          {trailDesignId?._id && (
            <button
              title="Add New Metadata"
              className="text-white border-success bg-success border-0"
              style={{ fontSize: 10, borderRadius: 3 }}
              onClick={() => cleanInputBox()}
            >
              <i className="fa-solid fa-square-plus"></i> Add New
            </button>
          )}
        </div>
        <Form.Select
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <option>Select type</option>
          <option selected={type === "custom"} value="custom">Custom</option>
          <option selected={type === "standard"} value="standard">Standard</option>
        </Form.Select>
      </Form.Group>
      {type === "custom" && (
        <>
          <Form.Group className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Title <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="rounded-1"
              type="text"
              placeholder="Enter Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Description <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="rounded-1"
              as="textarea"
              placeholder="Enter Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <div className="hstack justify-content-between">
              <Form.Label className="fw-bold mb-1">
                Column <span className="text-danger">*</span>
              </Form.Label>
              <div
                className="w-auto p-0 cursor-pointer"
                style={{ fontSize: 12 }}
                onClick={handleAddField}
              >
                <i className="fa fa-square-plus"></i> Add More
              </div>
            </div>
            {fields.map((field, index) => (
              <div className="hstack gap-2 mb-2" key={index}>
                <Form.Control
                  className="rounded-1"
                  type="text"
                  placeholder="Enter Name"
                  name="name"
                  value={field.name}
                  onChange={(e) => handleInputChange(index, e)}
                />
                <Form.Control
                  className="rounded-1"
                  type="text"
                  placeholder="Enter Label"
                  name="label"
                  value={field.label}
                  onChange={(e) => handleInputChange(index, e)}
                />

                {fields.length > 1 && (
                  <div
                    className="w-auto p-0 text-danger cursor-pointer"
                    title="Delete this Row"
                    style={{ fontSize: 12 }}
                    onClick={() => handleRemoveField(index)}
                  >
                    <i className="fa fa-trash"></i>
                  </div>
                )}
              </div>
            ))}
          </Form.Group>
        </>
      )}

      {type === "standard" && (
        <>
          <Form.Group className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Select Trail Design <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              value={trialId}
              onChange={(e) => setTrialId(e.target.value)}
            >
              <option value="">Select Trail Design</option>
              {trailDesignData?.map((item, index) => (
                <option
                  key={index}
                  value={item._id}
                >
                  {item.title}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </>
      )}

      <div className="text-end footer_button">
        <div className="text-end footer_button">
          <button className="submitbutton px-4" onClick={trailDesignId ? handleUpdate : addNewStudyTrailDesignDataset}>
            {loader ? (
              <Spinner
                animation="blabel"
                style={{ width: 15, height: 15 }}
                className="me-2"
              />
            ) : null}
            {trailDesignId ? "Update" : "Add"} Trail Design
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTrialDesignDetasets;
