import React, { Fragment, useState } from "react";
import { Form, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { cdiscServices } from "../../../../../../Services/CDISC/cdiscServices";
import { useParams } from "react-router";
import ReviewDataTemplate from "./ReviewDataTemplate";
import { ContextMenuTrigger } from "react-contextmenu";
import RightClick from "../../../../../LeftComponent/RightClick";
import { BiCommentEdit } from "react-icons/bi";
import GenerateQuery from "./GenerateQuery";
import Pagination from "../../../../../ETMF/Pagination/Pagination";

const LegacyTableData = ({
  studyDataTemplate,
  tableRef,
  onMouseDown,
  setIsOpenProperty,
  getStudyTrailDataTemplates,
  setIsFormOpen,
  setCRFDataId,
  setCRFProperties,
  setToggle2,
  setToggle3,
  toggle3,
  setToggle4,
  toggle4,
}) => {
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [id, setId] = useState("");
  const [status, setStatus] = useState("");
  const { studyId } = useParams();
  const deleteStudyTrailDataTemplates = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this CRF!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let results = await cdiscServices.deleteStudyTrailDataTemplates(id, studyId);
        if (results?.statusCode === 200) {
          getStudyTrailDataTemplates();
        }
      }
    });
  };

  const reviewStatusChangeUserStudyDataTrailTemplates = async (id, status) => {
    if (status === "completed") {
      setToggle3();
      setId(id);
      setStatus(status);
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this CRF!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#103C5E",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let userData = {};
          userData.id = id;
          userData.reviewStatus = status;
          userData.studyId = studyId;
          let results =
            await cdiscServices.reviewStatusChangeUserStudyDataTrailTemplates(
              userData
            );
          if (results?.statusCode === 200) {
            getStudyTrailDataTemplates();
          }
        }
      });
    }
  };

  const lockStatusChangeUserStudyTrailDataTemplates = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to ${status} this CRF!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${status} it!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.dataEntrystatus = status;
        userData.studyId = studyId;
        let results =
          await cdiscServices.lockStatusChangeUserStudyTrailDataTemplates(userData);
        if (results?.statusCode === 200) {
          getStudyTrailDataTemplates();
        }
      }
    });
  };

  const openGenerateQueryModal = (id) => {
    setToggle4();
    setId(id)
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = studyDataTemplate?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(studyDataTemplate?.length / itemsPerPage);

  return (
    <>
      <div className="overflow-auto">
        <Table
          ref={tableRef}
          id="resizable-table"
          className="text-nowrap custom-table"
          hover
          striped
        >
          <thead className="position-sticky z-1" style={{ top: -1 }}>
            <tr>
              <th
                onMouseDown={(e) => onMouseDown(e, 0)}
                style={{ width: 40 }}
                className="text-center"
              >
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Dataset Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Dataset Label</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Review Status</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Data Entry Status</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)}>Query Status</th>
              <th onMouseDown={(e) => onMouseDown(e, 6)}>Query Date | Time</th>
              <th onMouseDown={(e) => onMouseDown(e, 7)}>Import Date | Time</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 8)}
                style={{ width: 130 }}
                className="text-center"
              >
                Template Properties
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 9)}
                style={{ width: 70 }}
                className="text-center"
              >
                Update
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 10)}
                style={{ width: 60 }}
                className="text-center"
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {result?.map((item, index) => (
              <Fragment>
                <tr
                  key={index}
                  className={`position-relative cursor-pointer ${highlightedRow === index && "activeMenu"
                    }`}
                  onClick={() => {
                    setHighlightedRow(index);
                    setCRFProperties(item);
                  }}
                >
                  <td
                    className="text-center"
                    onClick={() => setIsOpenProperty(true)}
                  >
                    <ContextMenuTrigger
                      id={`${index}`}
                      collect={() => ({ id: index })}
                    >
                      {(pageNumber - 1) * itemsPerPage + index + 1}
                    </ContextMenuTrigger>
                    <RightClick
                      ContextId={`${index}`}
                      Menu1
                      MenuName1={"Generate Query"}
                      icons1={
                        <BiCommentEdit
                          className="edit"
                          style={{ marginBottom: -3 }}
                        />
                      }
                      handleClick1={(e) => openGenerateQueryModal(item._id)}
                    />
                  </td>
                  <td onClick={() => setIsOpenProperty(true)}>
                    <ContextMenuTrigger
                      id={`${index}`}
                      collect={() => ({ id: index })}
                    >
                      {item?.datasetName}
                    </ContextMenuTrigger>
                  </td>
                  <td onClick={() => setIsOpenProperty(true)}>
                    <ContextMenuTrigger
                      id={`${index}`}
                      collect={() => ({ id: index })}
                    >
                      {item?.datasetLabel}
                    </ContextMenuTrigger>
                  </td>
                  <td onClick={() => setIsOpenProperty(true)}>
                    <ContextMenuTrigger
                      id={`${index}`}
                      collect={() => ({ id: index })}
                    >
                      <Form.Select
                        className="p-0   border-0 px-2 bg-transparent"
                        onChange={(e) =>
                          reviewStatusChangeUserStudyDataTrailTemplates(
                            item._id,
                            e.target.value
                          )
                        }
                      >
                        <option
                          hidden
                          selected={item.reviewStatus === "notAssign"}
                          value={"notAssign"}
                        >
                          Not Assign
                        </option>
                        <option
                          hidden
                          selected={item.reviewStatus === "assigned"}
                          value={"assigned"}
                        >
                          Assigned
                        </option>
                        <option
                          selected={item.reviewStatus === "started"}
                          value={"started"}
                        >
                          Started
                        </option>
                        <option
                          selected={item.reviewStatus === "completed"}
                          value={"completed"}
                        >
                          Completed
                        </option>
                        <option
                          selected={item.reviewStatus === "pending"}
                          value={"pending"}
                        >
                          Pending
                        </option>
                      </Form.Select>
                    </ContextMenuTrigger>
                  </td>
                  <td onClick={() => setIsOpenProperty(true)}>
                    <ContextMenuTrigger
                      id={`${index}`}
                      collect={() => ({ id: index })}
                    >
                      <span className="text-danger">
                        <Form.Select
                          className="p-0   border-0 px-2 bg-transparent"
                          onChange={(e) =>
                            lockStatusChangeUserStudyTrailDataTemplates(
                              item._id,
                              e.target.value
                            )
                          }
                        >
                          <option
                            selected={item.dataEntrystatus === "locked"}
                            value={"locked"}
                          >
                            Locked
                          </option>
                          <option
                            selected={item.dataEntrystatus === "unLocked"}
                            value={"unLocked"}
                          >
                            Unlocked
                          </option>
                        </Form.Select>
                      </span>
                    </ContextMenuTrigger>
                  </td>
                  <td
                    onClick={() => setIsOpenProperty(true)}
                    className="text-capitalize"
                  >
                    <ContextMenuTrigger
                      id={`${index}`}
                      collect={() => ({ id: index })}
                    >
                      {item.queryStatus === "noQuery"
                        ? "NO Query"
                        : item.queryStatus}
                    </ContextMenuTrigger>
                  </td>
                  <td onClick={() => setIsOpenProperty(true)}>
                    <ContextMenuTrigger
                      id={`${index}`}
                      collect={() => ({ id: index })}
                    >
                      {item.queryStatus === "open" ?
                        <Moment format="DD MMM YYYY | HH:mm:ss">
                          {item?.queryDateTime}
                        </Moment>
                        :
                        "_"
                      }
                    </ContextMenuTrigger>
                  </td>
                  <td onClick={() => setIsOpenProperty(true)}>
                    <ContextMenuTrigger
                      id={`${index}`}
                      collect={() => ({ id: index })}
                    >
                      <Moment format="DD MMM YYYY | HH:mm:ss">
                        {item?.createdAt}
                      </Moment>
                    </ContextMenuTrigger>
                  </td>
                  <td className="text-center">
                    <ContextMenuTrigger
                      id={`${index}`}
                      collect={() => ({ id: index })}
                    >
                      <button
                        onClick={setToggle2}
                        title="Update"
                        className="text-white border-success bg-success border-0"
                        style={{ fontSize: 10, borderRadius: 3 }}
                      >
                        View Properties
                      </button>
                    </ContextMenuTrigger>
                  </td>
                  <td className="text-center">
                    <ContextMenuTrigger
                      id={`${index}`}
                      collect={() => ({ id: index })}
                    >
                      <button
                        onClick={() => {
                          setCRFDataId(item);
                          setIsFormOpen(true);
                        }}
                        title="Update"
                        className="text-white border-success bg-success border-0"
                        style={{ fontSize: 10, borderRadius: 3 }}
                      >
                        Update
                      </button>
                    </ContextMenuTrigger>
                  </td>
                  <td className="text-center">
                    <ContextMenuTrigger
                      id={`${index}`}
                      collect={() => ({ id: index })}
                    >
                      <button
                        className="text-white border-danger bg-danger border-0"
                        style={{ fontSize: 10, borderRadius: 3 }}
                        title="Delete Data Template"
                        onClick={() => deleteStudyTrailDataTemplates(item?.uniqueId)}
                      >
                        Delete
                      </button>
                    </ContextMenuTrigger>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={studyDataTemplate?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
      <ReviewDataTemplate
        SHOW={!toggle3}
        HIDE={setToggle3}
        status={status}
        studyId={studyId}
        id={id}
        getStudyTrailDataTemplates={getStudyTrailDataTemplates}
      />
      <GenerateQuery
        SHOW={!toggle4}
        HIDE={setToggle4}
        studyId={studyId}
        templateId={id}
        getStudyTrailDataTemplates={getStudyTrailDataTemplates}
      />
    </>
  );
};

export default LegacyTableData;
