import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useParams } from "react-router";
import Moment from "react-moment";
import { cdiscServices } from "../../../../../../Services/CDISC/cdiscServices";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../../../NoDataFoundTable/NoDataFoundTable";

const ReviewHistory = ({ onMouseDown, tableRef, crfProperties }) => {
  const { studyId } = useParams();
  const [reviewHistory, setReviewHistory] = useState([]);
  const [loader, setLoader] = useState(false);
  const getStudyTrailDataTemplateReviewHistory = async (id) => {
    setLoader(true)
    let data = await cdiscServices.getStudyTrailDataTemplateReviewHistory(id, studyId)
    setReviewHistory(data?.data || [])
    setLoader(false)
  }
  useEffect(() => {
    getStudyTrailDataTemplateReviewHistory(crfProperties?._id);
  }, [crfProperties, studyId]);
  return (
    <>
      {loader ? (
        <SpinerDataLoader />
      ) : reviewHistory?.length === 0 ? (
        <NoDataFoundTable MSG="Review History not found" />
      ) : (
        <>
          <Table
            id="resizable-table"
            striped
            hover
            className="m-0 custom-table"
            ref={tableRef}
          >
            <thead>
              <tr>
                <th
                  onMouseDown={(e) => onMouseDown(e, 0)}
                  style={{ width: 50 }}
                  className="text-center"
                >
                  Sr.
                </th>
                <th onMouseDown={(e) => onMouseDown(e, 1)}>Status</th>
                <th onMouseDown={(e) => onMouseDown(e, 2)}>Activity By</th>
                <th onMouseDown={(e) => onMouseDown(e, 3)}>
                  Activity Date | Time
                </th>
              </tr>
            </thead>
            <tbody>
              {reviewHistory?.map((item, index) => (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-capitalize">{item.status}</td>
                  <td>{item.fullName}</td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm:ss">
                      {item?.createdAt}
                    </Moment>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default ReviewHistory;
