import React from 'react'

const CdrgData = () => {
  return (
    <div className='PrintHeader'>
      <div className='text-center'>
        <div style={{fontSize: 30}}>Clinical Study Data Reviewer’s Guide</div>
        <div style={{fontSize: 24}} ><b>Protocol Number: 039-24</b></div>
        <div style={{fontSize: 24}}><b>Amneal EU, Limited</b></div>
      </div>
    </div>
  )
}

export default CdrgData

