import React, { useEffect, useState } from "react";
import { Col, Collapse, Form, InputGroup, Row } from "react-bootstrap";
import { cdiscServices } from "../../../../../../../Services/CDISC/cdiscServices";
import { FaTrashCan } from "react-icons/fa6";
import Multiselect from "multiselect-react-dropdown";
import SplitPane from "react-split-pane";

const StatementTools = ({
  studyId,
  derivationId,
  getDomainDerivationList,
  isHalfScreen,
}) => {
  let [uniqueColumnKey, setUniqueColumnKey] = useState([]);
  let [statements, setStatements] = useState([]);
  let [templateIds, setTemplateIds] = useState([]);
  let [columns, setColumns] = useState([]);
  let [primaryDataType, setPrimaryDataType] = useState("");

  const findDomainDerivationData = async () => {
    let result = await cdiscServices.findDomainDerivationData(
      studyId,
      derivationId
    );
    let derivationData = result?.data;
    if (
      derivationData?.primaryDataset ||
      derivationData?.secondaryDataType ||
      derivationData?.cellDataset
    ) {
      setTemplateIds([
        { templateId: derivationData?.primaryDataset || "" },
        { templateId: derivationData?.secondaryDataset || "" },
        { templateId: derivationData?.cellDataset || "" },
      ]);
    }
    setPrimaryDataType(derivationData?.primaryDataType);
    if (derivationData?.primaryDataset || derivationData?.primaryDataType) {
      getDatasetColumList(
        derivationData?.primaryDataset,
        derivationData?.primaryDataType
      );
    }
    setStatements(derivationData?.statements);
  }

  useEffect(() => {
    findDomainDerivationData();
  }, [derivationId]);


  const derivationCRFData = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.derivationId = derivationId;
    userData.statements = statements;
    await cdiscServices.derivationSaveCRFData(userData);
    getDomainDerivationList();
  };

  const getMultiDataEntryColumList = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.templateIds = templateIds || [];
    let data = await cdiscServices.getMultiDataEntryColumList(userData);
    setUniqueColumnKey(data?.uniqueColumnKey || []);
  };

  const getDatasetColumList = async (primaryDataset, type) => {
    let data = await cdiscServices.getDatasetColumList(
      studyId,
      type,
      primaryDataset
    );
    setColumns(data?.data);
  };

  useEffect(() => {
    if (templateIds?.length > 0) {
      getMultiDataEntryColumList();
    }
  }, [templateIds?.length]);

  const addNewStatement = (type) => {
    const newObject = {
      id: Math.random().toString().substr(2, 10),
      type: type,
    };

    if (type === "if" || type === "elseif") {
      newObject.keyType = "";
      newObject.keys = [];
      newObject.key = "";
      newObject.operator = "";
      newObject.value = "";
    }

    if (type === "else" || type === "then") {
      newObject.columnType = "";
      newObject.permissionType = "";
      newObject.dataType = "";
      newObject.columnLabel = "";
      newObject.columnName = "";
      newObject.value = "";
    }

    setStatements((prevItems) => [...prevItems, newObject]);
  };

  const handleStatementChange = (id, key, value) => {
    setStatements((prevStatements) =>
      prevStatements.map((statement) =>
        statement.id === id ? { ...statement, [key]: value } : statement
      )
    );
  };

  const handleMultiSelectChange = (id, key, value) => {
    setStatements((prevStatements) =>
      prevStatements.map((statement) =>
        statement.id === id ? { ...statement, [key]: value } : statement
      )
    );
  };

  const removeStatement = (id) => {
    let newArray = statements?.filter((item) => item?.id !== id);
    setStatements(newArray);
  };

  return (
    <>
      <SplitPane
        split="vertical"
        className="position-relative"
        defaultSize="15%"
        style={{ height: isHalfScreen ? "95.7%" : "98.2%" }}
      >
        <div className="position-relative h-100">
          <div>
            <span class="CP ps-3 small linkClass" onClick={() => addNewStatement("if")}>
              IF
            </span>
            <span class="CP ps-3 small linkClass" onClick={() => addNewStatement("else")}>
              ELSE
            </span>
            <span
              class="CP ps-3 small linkClass"
              onClick={() => addNewStatement("elseif")}
            >
              ELSE IF
            </span>
            <span class="CP ps-3 small linkClass" onClick={() => addNewStatement("then")}>
              THEN DO
            </span>
            <span class="CP ps-3 small linkClass" onClick={() => addNewStatement("end")}>
              END DO
            </span>
          </div>
          <span
            class="CP p-1 updateProfile position-absolute bottom-0 w-100 text-center rounded-0"
            onClick={derivationCRFData}
          >
            Save
          </span>
        </div>
        <div className="py-2 px-3">
          {statements?.length > 0 ? (
            statements?.map((item, index) => (
              <div key={index}>
                <Collapse in={item?.type === "if"}>
                  <div className="position-relative shadow-sm border mb-3">
                    <div className="bg-light border-bottom p-1 px-3 hstack justify-content-between">
                      <Form.Label
                        className="mb-0 fw-bold"
                        style={{ minWidth: 40 }}
                      >
                        IF
                      </Form.Label>
                      <FaTrashCan
                        onClick={() => removeStatement(item?.id)}
                        style={{ fontSize: 12 }}
                        className="CP text-danger"
                        title="Remove Statement"
                      />
                    </div>
                    <div className="p-3">
                      <Form.Select
                        onChange={(e) =>
                          handleStatementChange(
                            item.id,
                            "keyType",
                            e.target.value
                          )
                        }
                        className="mb-2"
                      >
                        <option value="">Select</option>
                        <option
                          selected={item?.keyType === "firstKey"}
                          value="firstKey"
                        >
                          First Key
                        </option>
                        <option
                          selected={item?.keyType === "lastKey"}
                          value="lastKey"
                        >
                          Last Key
                        </option>
                        <option
                          selected={item?.keyType === "column"}
                          value="column"
                        >
                          Column
                        </option>
                      </Form.Select>
                      {item?.keyType !== "column" && (
                        <Multiselect
                          displayValue="label"
                          options={(primaryDataType === "Merge" ||
                            primaryDataType === "Append"
                            ? uniqueColumnKey
                            : columns
                          )?.map((keys, key) => ({
                            label: keys?.name,
                            value: keys?.name,
                          }))}
                          selectedValues={item.keys || []}
                          onSelect={(selectedList) =>
                            handleMultiSelectChange(
                              item.id,
                              "keys",
                              selectedList
                            )
                          }
                          onRemove={(removedList) =>
                            handleMultiSelectChange(
                              item.id,
                              "keys",
                              removedList
                            )
                          }
                        />
                      )}
                      {item?.keyType === "column" && (
                        <>
                          <Form.Select
                            onChange={(e) =>
                              handleStatementChange(
                                item.id,
                                "key",
                                e.target.value
                              )
                            }
                            className="mb-2"
                          >
                            <option value={""}>{`Select Column`}</option>
                            {(primaryDataType === "Merge" ||
                              primaryDataType === "Append"
                              ? uniqueColumnKey
                              : columns
                            )?.map((data, index) => (
                              <option
                                selected={item?.key === data?.name}
                                key={index}
                                value={data?.name}
                              >
                                {data?.name}
                              </option>
                            ))}
                          </Form.Select>
                          <div className="my-2">
                            <InputGroup className="justify-content-center">
                              <InputGroup.Text
                                className={`cursor-pointer updateProfile ${item.operator === "AND" &&
                                  "bg-white text-muted"
                                  }`}
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "AND"
                                  )
                                }
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: "3px 0 0 3px",
                                }}
                              >
                                AND
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "OR"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "OR" &&
                                  "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                }}
                              >
                                OR
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "+"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "+" && "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                }}
                              >
                                +
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "-"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "-" && "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                }}
                              >
                                -
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "/"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "/" && "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                }}
                              >
                                /
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "*"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "*" && "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                }}
                              >
                                *
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "%"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "%" && "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                }}
                              >
                                %
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "="
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "=" && "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: "0 3px 3px 0",
                                }}
                              >
                                =
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "!="
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "!=" &&
                                  "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: "0 3px 3px 0",
                                }}
                              >
                                !=
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    ">"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === ">" && "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: "0 3px 3px 0",
                                }}
                              >
                                {`>`}
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "<"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "<" && "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: "0 3px 3px 0",
                                }}
                              >
                                {`<`}
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "=<"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "=<" &&
                                  "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: "0 3px 3px 0",
                                }}
                              >
                                {`=<`}
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "=>"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "=>" &&
                                  "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: "0 3px 3px 0",
                                }}
                              >
                                {`=>`}
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "IN"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "IN" &&
                                  "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: "0 3px 3px 0",
                                }}
                              >
                                IN
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "!IN"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "!IN" &&
                                  "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: "0 3px 3px 0",
                                }}
                              >
                                !IN
                              </InputGroup.Text>
                            </InputGroup>
                          </div>
                          <Form.Control
                            onChange={(e) =>
                              handleStatementChange(
                                item.id,
                                "value",
                                e.target.value
                              )
                            }
                            type="text"
                            value={item.value}
                            placeholder="Enter Value"
                          />
                        </>
                      )}
                    </div>
                  </div>
                </Collapse>
                <Collapse in={item?.type === "elseif"}>
                  <div className="position-relative shadow-sm border mb-3">
                    <div className="bg-light border-bottom p-1 px-3 hstack justify-content-between">
                      <Form.Label
                        className="mb-0 fw-bold"
                        style={{ minWidth: 40 }}
                      >
                        ELSEIF
                      </Form.Label>
                      <FaTrashCan
                        onClick={() => removeStatement(item?.id)}
                        style={{ fontSize: 12 }}
                        className="CP text-danger"
                        title="Remove Statement"
                      />
                    </div>
                    <div className="p-3">
                      <Form.Select
                        onChange={(e) =>
                          handleStatementChange(
                            item.id,
                            "keyType",
                            e.target.value
                          )
                        }
                        className="mb-2"
                      >
                        <option value="">Select</option>
                        <option
                          selected={item?.keyType === "firstKey"}
                          value="firstKey"
                        >
                          First Key
                        </option>
                        <option
                          selected={item?.keyType === "lastKey"}
                          value="lastKey"
                        >
                          Last Key
                        </option>
                        <option
                          selected={item?.keyType === "column"}
                          value="column"
                        >
                          Column
                        </option>
                      </Form.Select>
                      {item?.keyType !== "column" && (
                        <Multiselect
                          displayValue="label"
                          options={(primaryDataType === "Merge" ||
                            primaryDataType === "Append"
                            ? uniqueColumnKey
                            : columns
                          )?.map((keys, key) => ({
                            label: keys?.name,
                            value: keys?.name,
                          }))}
                          selectedValues={item.keys || []}
                          onSelect={(selectedList) =>
                            handleMultiSelectChange(
                              item.id,
                              "keys",
                              selectedList
                            )
                          }
                          onRemove={(removedList) =>
                            handleMultiSelectChange(
                              item.id,
                              "keys",
                              removedList
                            )
                          }
                        />
                      )}
                      {item?.keyType === "column" && (
                        <>
                          <Form.Select
                            onChange={(e) =>
                              handleStatementChange(
                                item.id,
                                "key",
                                e.target.value
                              )
                            }
                            className="mb-2"
                          >
                            <option value={""}>{`Select Column`}</option>
                            {(primaryDataType === "Merge" ||
                              primaryDataType === "Append"
                              ? uniqueColumnKey
                              : columns
                            )?.map((data, index) => (
                              <option
                                selected={item?.key === data?.name}
                                key={index}
                                value={data?.name}
                              >
                                {data?.name}
                              </option>
                            ))}
                          </Form.Select>
                          <div className="my-2">
                            <InputGroup className="justify-content-center">
                              <InputGroup.Text
                                className={`cursor-pointer updateProfile ${item.operator === "AND" &&
                                  "bg-white text-muted"
                                  }`}
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "AND"
                                  )
                                }
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: "3px 0 0 3px",
                                }}
                              >
                                AND
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "OR"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "OR" &&
                                  "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                }}
                              >
                                OR
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "+"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "+" && "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                }}
                              >
                                +
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "-"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "-" && "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                }}
                              >
                                -
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "/"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "/" && "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                }}
                              >
                                /
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "*"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "*" && "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                }}
                              >
                                *
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "%"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "%" && "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                }}
                              >
                                %
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "="
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "=" && "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: "0 3px 3px 0",
                                }}
                              >
                                =
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "!="
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "!=" &&
                                  "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: "0 3px 3px 0",
                                }}
                              >
                                !=
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    ">"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === ">" && "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: "0 3px 3px 0",
                                }}
                              >
                                {`>`}
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "<"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "<" && "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: "0 3px 3px 0",
                                }}
                              >
                                {`<`}
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "=<"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "=<" &&
                                  "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: "0 3px 3px 0",
                                }}
                              >
                                {`=<`}
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "=>"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "=>" &&
                                  "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: "0 3px 3px 0",
                                }}
                              >
                                {`=>`}
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "IN"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "IN" &&
                                  "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: "0 3px 3px 0",
                                }}
                              >
                                IN
                              </InputGroup.Text>
                              <InputGroup.Text
                                onClick={() =>
                                  handleMultiSelectChange(
                                    item.id,
                                    "operator",
                                    "!IN"
                                  )
                                }
                                className={`cursor-pointer updateProfile ${item.operator === "!IN" &&
                                  "bg-white text-muted"
                                  }`}
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: "0 3px 3px 0",
                                }}
                              >
                                !IN
                              </InputGroup.Text>
                            </InputGroup>
                          </div>
                          <Form.Control
                            value={item.value}
                            onChange={(e) =>
                              handleStatementChange(
                                item.id,
                                "value",
                                e.target.value
                              )
                            }
                            type="text"
                            placeholder="Enter Value"
                          />
                        </>
                      )}
                    </div>
                  </div>
                </Collapse>
                <Collapse in={item?.type === "then"}>
                  <div className="position-relative shadow-sm border mb-3">
                    <div className="bg-light border-bottom p-1 px-3 hstack justify-content-between">
                      <Form.Label
                        className="mb-0 fw-bold"
                        style={{ minWidth: 40 }}
                      >
                        THEN DO
                      </Form.Label>
                      <FaTrashCan
                        onClick={() => removeStatement(item?.id)}
                        style={{ fontSize: 12 }}
                        className="CP text-danger"
                        title="Remove Statement"
                      />
                    </div>
                    <div className="p-3">
                      <Form.Select
                        className="mb-1"
                        onChange={(e) =>
                          handleStatementChange(
                            item.id,
                            "columnType",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select Coumn Type</option>
                        <option
                          selected={item?.columnType === "dataset"}
                          value="dataset"
                        >
                          From Dataset
                        </option>
                        <option
                          selected={item?.columnType === "custom"}
                          value="custom"
                        >
                          Custom
                        </option>
                        <option
                          selected={item?.columnType === "output"}
                          value="output"
                        >
                          Output
                        </option>
                      </Form.Select>
                      {item.columnType === "dataset" && (
                        <Row>
                          <Form.Group as={Col} md={6}>
                            <Form.Select
                              className="mb-1"
                              onChange={(e) =>
                                handleStatementChange(
                                  item.id,
                                  "columnName",
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select Coumn</option>
                              {(primaryDataType === "Merge" ||
                                primaryDataType === "Append"
                                ? uniqueColumnKey
                                : columns
                              )?.map((data, index) => (
                                <option
                                  selected={item?.columnName === data?.name}
                                  key={index}
                                  value={data?.name}
                                >
                                  {data?.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                          <Form.Group as={Col} md={6}>
                            <Form.Select
                              onChange={(e) =>
                                handleStatementChange(
                                  item.id,
                                  "retainType",
                                  e.target.value
                                )
                              }>
                              <option value={""}>Select Retain Type</option>
                              <option selected={item?.retainType === "yes"} value={"yes"}>Retain Yes</option>
                              <option selected={item?.retainType === "no"} value={"no"}>Retain No</option>
                            </Form.Select>
                          </Form.Group>
                        </Row>
                      )}
                      {item.columnType === "custom" && (
                        <Row>
                          <Form.Group as={Col} md={6}>
                            <Form.Select
                              className="mb-2"
                              onChange={(e) =>
                                handleStatementChange(
                                  item.id,
                                  "dataType",
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select Column Data Type</option>
                              <option
                                selected={item?.dataType === "text"}
                                value="text"
                              >
                                Text
                              </option>
                              <option
                                selected={item?.dataType === "number"}
                                value="number"
                              >
                                Number
                              </option>
                              <option
                                selected={item?.dataType === "date"}
                                value="date"
                              >
                                Date
                              </option>
                              <option
                                selected={item?.dataType === "time"}
                                value="time"
                              >
                                Time
                              </option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group as={Col} md={6}>
                            <Form.Select
                              className="mb-2"
                              onChange={(e) =>
                                handleStatementChange(
                                  item.id,
                                  "permissionType",
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select Column Data Type</option>
                              <option
                                selected={
                                  item?.permissionType === "permissible"
                                }
                                value="permissible"
                              >
                                Permissible
                              </option>
                              <option
                                selected={item?.permissionType === "require"}
                                value="require"
                              >
                                Require
                              </option>
                              <option
                                selected={item?.permissionType === "expected"}
                                value="expected"
                              >
                                Expected
                              </option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group as={Col} md={4}>
                            <Form.Control
                              className="mb-2"
                              value={item?.columnName}
                              type="text"
                              onChange={(e) =>
                                handleStatementChange(
                                  item.id,
                                  "columnName",
                                  e.target.value
                                )
                              }
                              placeholder="Enter Custom Column Name"
                            />
                          </Form.Group>
                          <Form.Group as={Col} md={4}>
                            <Form.Control
                              value={item?.columnLabel}
                              type="text"
                              onChange={(e) =>
                                handleStatementChange(
                                  item.id,
                                  "columnLabel",
                                  e.target.value
                                )
                              }
                              placeholder="Enter Custom Column Label"
                            />
                          </Form.Group>
                          <Form.Group as={Col} md={4}>
                            <Form.Select
                              onChange={(e) =>
                                handleStatementChange(
                                  item.id,
                                  "retainType",
                                  e.target.value
                                )
                              }>
                              <option value={""}>Select Retain Type</option>
                              <option selected={item?.retainType === "yes"} value={"yes"}>Retain Yes</option>
                              <option selected={item?.retainType === "no"} value={"no"}>Retain No</option>
                            </Form.Select>
                          </Form.Group>
                        </Row>
                      )}
                      <>
                        <div className="my-2">
                          <InputGroup className="justify-content-center">
                            <InputGroup.Text
                              style={{
                                padding: "5px 9.7px",
                                fontSize: 10,
                                borderRadius: 3,
                              }}
                            >
                              =
                            </InputGroup.Text>
                          </InputGroup>
                        </div>
                        <Form.Control
                          value={item?.value}
                          onChange={(e) =>
                            handleStatementChange(
                              item.id,
                              "value",
                              e.target.value
                            )
                          }
                          type={item?.dataType || "text"}
                          placeholder="Enter Value"
                        />
                      </>
                    </div>
                  </div>
                </Collapse>
                <Collapse in={item?.type === "else"}>
                  <div className="position-relative shadow-sm border mb-3">
                    <div className="bg-light border-bottom p-1 px-3 hstack justify-content-between">
                      <Form.Label
                        className="mb-0 fw-bold"
                        style={{ minWidth: 40 }}
                      >
                        ELSE
                      </Form.Label>
                      <FaTrashCan
                        onClick={() => removeStatement(item?.id)}
                        style={{ fontSize: 12 }}
                        className="CP text-danger"
                        title="Remove Statement"
                      />
                    </div>
                    <div className="p-3">
                      <Form.Select
                        className="mb-1"
                        onChange={(e) =>
                          handleStatementChange(
                            item.id,
                            "columnType",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select Coumn Type</option>
                        <option
                          selected={item?.columnType === "dataset"}
                          value="dataset"
                        >
                          From Dataset
                        </option>
                        <option
                          selected={item?.columnType === "custom"}
                          value="custom"
                        >
                          Custom
                        </option>
                        <option
                          selected={item?.columnType === "output"}
                          value="output"
                        >
                          Output
                        </option>
                      </Form.Select>
                      {item.columnType === "dataset" && (
                        <Row>
                          <Form.Group as={Col} md={6}>
                            <Form.Select
                              className="mb-1"
                              onChange={(e) =>
                                handleStatementChange(
                                  item.id,
                                  "columnName",
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select Coumn</option>
                              {(primaryDataType === "Merge" ||
                                primaryDataType === "Append"
                                ? uniqueColumnKey
                                : columns
                              )?.map((data, index) => (
                                <option
                                  selected={item?.columnName === data?.name}
                                  key={index}
                                  value={data?.name}
                                >
                                  {data?.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                          <Form.Group as={Col} md={6}>
                            <Form.Select
                              onChange={(e) =>
                                handleStatementChange(
                                  item.id,
                                  "retainType",
                                  e.target.value
                                )
                              }>
                              <option value={""}>Select Retain Type</option>
                              <option selected={item?.retainType === "yes"} value={"yes"}>Retain Yes</option>
                              <option selected={item?.retainType === "no"} value={"no"}>Retain No</option>
                            </Form.Select>
                          </Form.Group>
                        </Row>
                      )}
                      {item.columnType === "custom" && (
                        <Row>
                          <Form.Group as={Col} md={6}>
                            <Form.Select
                              className="mb-2"
                              onChange={(e) =>
                                handleStatementChange(
                                  item.id,
                                  "dataType",
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select Column Data Type</option>
                              <option
                                selected={item?.dataType === "text"}
                                value="text"
                              >
                                Text
                              </option>
                              <option
                                selected={item?.dataType === "number"}
                                value="number"
                              >
                                Number
                              </option>
                              <option
                                selected={item?.dataType === "date"}
                                value="date"
                              >
                                Date
                              </option>
                              <option
                                selected={item?.dataType === "time"}
                                value="time"
                              >
                                Time
                              </option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group as={Col} md={6}>
                            <Form.Select
                              className="mb-2"
                              onChange={(e) =>
                                handleStatementChange(
                                  item.id,
                                  "permissionType",
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select Column Data Type</option>
                              <option
                                selected={
                                  item?.permissionType === "permissible"
                                }
                                value="permissible"
                              >
                                Permissible
                              </option>
                              <option
                                selected={item?.permissionType === "require"}
                                value="require"
                              >
                                Require
                              </option>
                              <option
                                selected={item?.permissionType === "expected"}
                                value="expected"
                              >
                                Expected
                              </option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group as={Col} md={6}>
                            <Form.Control
                              className="mb-2"
                              value={item?.columnName}
                              type="text"
                              onChange={(e) =>
                                handleStatementChange(
                                  item.id,
                                  "columnName",
                                  e.target.value
                                )
                              }
                              placeholder="Enter Custom Column Name"
                            />
                          </Form.Group>
                          <Form.Group as={Col} md={6}>
                            <Form.Control
                              value={item?.columnLabel}
                              type="text"
                              onChange={(e) =>
                                handleStatementChange(
                                  item.id,
                                  "columnLabel",
                                  e.target.value
                                )
                              }
                              placeholder="Enter Custom Column Label"
                            />
                          </Form.Group>
                        </Row>
                      )}
                      <>
                        <div className="my-2">
                          <InputGroup className="justify-content-center">
                            <InputGroup.Text
                              style={{
                                padding: "5px 9.7px",
                                fontSize: 10,
                                borderRadius: 3,
                              }}
                            >
                              =
                            </InputGroup.Text>
                          </InputGroup>
                        </div>
                        <Form.Control
                          value={item?.value}
                          onChange={(e) =>
                            handleStatementChange(
                              item.id,
                              "value",
                              e.target.value
                            )
                          }
                          type={item?.dataType || "text"}
                          placeholder="Enter Value"
                        />
                      </>
                    </div>
                  </div>
                </Collapse>
                <Collapse in={item?.type === "end"}>
                  <div className="position-relative shadow-sm border mb-3">
                    <div className="bg-light border-bottom p-1 px-3 hstack justify-content-between">
                      <Form.Label
                        className="mb-0 fw-bold"
                        style={{ minWidth: 40 }}
                      >
                        END DO
                      </Form.Label>
                      <FaTrashCan
                        onClick={() => removeStatement(item?.id)}
                        style={{ fontSize: 12 }}
                        className="CP text-danger"
                        title="Remove Statement"
                      />
                    </div>
                  </div>
                </Collapse>
              </div>
            ))
          ) : (
            <div className="hstack justify-content-center h-100">
              No Statement
            </div>
          )}
        </div >
      </SplitPane >
    </>
  );
};

export default StatementTools;
