import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { cdrgStudyServices } from "../../../../Services/CDISC/cdrgStudyServices";

const CDRGPdfDataDetails = () => {
  const { studyId } = useParams();
  const [annotation, setAnnotation] = useState("");
  const [description, setDescription] = useState("");
  const [acronymsData, setAcronymsData] = useState([]);
  const [appendixData, setAppendixData] = useState([]);
  const [dataConformance, setDataConformance] = useState("");

  const getStudyAcrouymsMetadata = async () => {
    let data = await cdrgStudyServices.getStudyAcrouymsMetadata(studyId);
    setAcronymsData(data?.data);
  };

  const getStudyAppendixIncExcData = async () => {
    let data = await cdrgStudyServices.getStudyAppendixIncExcData(studyId);
    setAppendixData(data?.data);
  };

  const getStudySubjectDataDescription = async () => {
    let data = await cdrgStudyServices.getStudySubjectDataDescription(
      "overview",
      studyId
    );
    setDescription(data?.data?.description || "");
  };

  const getStudySubjectDataDescriptionAnnotation = async () => {
    let data = await cdrgStudyServices.getStudySubjectDataDescription(
      "Annotations",
      studyId
    );
    setAnnotation(data?.data?.description || "");
  };

  const getStudyDataConformanceInputs = async () => {
    let data = await cdrgStudyServices.getStudyDataConformanceInputs(studyId);
    setDataConformance(data?.data?.description || "");
  };

  useEffect(() => {
    getStudyAcrouymsMetadata();
    getStudyAppendixIncExcData();
    getStudyDataConformanceInputs();
    getStudySubjectDataDescription();
    getStudySubjectDataDescriptionAnnotation();
  }, []);

  const styles = {
    mainContainer: {
      fontSize: "14px",
      lineHeight: "1.6",
      color: "#333",
    },
    heading: {
      textAlign: "left",
      marginBottom: "10px",
      fontSize: "20px",
    },
    subHeading: {
      margin: "10px 0",
      fontSize: "20px",
      marginLeft: "15px",
    },
    subSubHeading: {
      margin: "10px 0",
      fontSize: "18px",
      marginLeft: "28px",
    },
    paragraph: {
      marginLeft: "44px",
    },
    paragraphs: {
      marginLeft: "77px",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      marginLeft: "20px",
      border: "1px solid black",
    },
    th: {
      border: "1px solid black",
      padding: "5px",
      fontWeight: "bold",
      textAlign: "left",
      fontSize: "13px",
      backgroundColor: "#80808085",
    },
    td: {
      border: "1px solid black",
      padding: "5px",
      fontSize: "12px",
    },
  };

  return (
    <>
      <div style={styles.mainContainer}>
        <h1 style={styles.heading}>3. Introduction</h1>
        <h2 style={styles.subHeading}>3.1 Purpose</h2>
        <p style={styles.paragraph}>
          This document provides context for tabulation datasets and terminology
          that benefit from additional explanation beyond the Data Definitions
          document (define.xml). In addition, this document provides a summary
          of SDTM conformance findings.
        </p>

        <h2 style={styles.subHeading}>3.2 Acronyms</h2>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>Acronym</th>
              <th style={styles.th}>Translation</th>
            </tr>
          </thead>
          <tbody>
            {acronymsData?.map((item, index) => (
              <tr key={index}>
                <td style={styles.td}>{item.acronyms}</td>
                <td style={styles.td}>{item.translation}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <h2 style={styles.subHeading}>
          3.3 Study Data Standards and Dictionary Inventory
        </h2>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>Standard or Dictionary</th>
              <th style={styles.th}>Versions Used</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={styles.td}>SDTM</td>
              <td style={styles.td}>SDTM IG v3.3</td>
            </tr>
            <tr>
              <td style={styles.td}>Controlled Terminology</td>
              <td style={styles.td}>
                CDISC SDTM CT as of 29MAR2024 plus user defined
              </td>
            </tr>
            <tr>
              <td style={styles.td}>Data Definitions</td>
              <td style={styles.td}>define.xml v2.0</td>
            </tr>
            <tr>
              <td style={styles.td}>Medications Dictionary</td>
              <td style={styles.td}>Not Applicable</td>
            </tr>
            <tr>
              <td style={styles.td}>Medical Events Dictionary</td>
              <td style={styles.td}>Not Applicable</td>
            </tr>
          </tbody>
        </table>
        <h1 style={styles.heading} className="mt-1">
          4. Protocol Description
        </h1>
        <h2 style={styles.subHeading}>4.1 Protocol Number and Title</h2>
        <p style={styles.paragraph}>
          Protocol Number: 039-24
          <br />
          Protocol Title: A randomized, open label, balanced, three treatment,
          single period, single dose, parallel, truncated, oral bioequivalence
          study with pharmacokinetic endpoints of test products (T1 & T2)
          Cabozantinib Tablets 60 mg manufactured by Amneal Pharmaceuticals Pvt.
          Ltd., Oral Solid Dosage Unit, Pharmez, Matoda, India comparing with
          reference product (R) CABOMETYX® (cabozantinib) tablets 60 mg
          Manufactured for Exelixis, Inc. Alameda, CA 94502, in Healthy males
          not of reproductive potential (i.e., surgically sterile) and healthy
          females not of reproductive potential under fasting conditions.
          <br />
          Protocol Version: 1, dated 20MAR2024
          <br />
          Errata to Protocol: Errata No.:01 dated 21JUN2024
        </p>
        <h2 style={styles.subHeading}>4.2 Protocol Design</h2>
        <p style={styles.paragraph}>
          A randomized, open label, balanced, three treatment, single period,
          single dose, parallel, truncated, oral bioequivalence study with
          pharmacokinetic endpoints under fasting condition.
        </p>
        <h2 style={styles.subHeading}>4.3 Trial Design Datasets</h2>
        <p style={styles.paragraph}>
          Are Trial Design datasets included in the submission? Yes
        </p>
        <h3 style={styles.subSubHeading}>4.3.1 TA- Trial Arms</h3>
        <p style={styles.paragraphs}>
          The trial arms table describes each planned arm in the trial. An arm
          is described as an Ordered sequence of elements. One record per
          planned Element per Arm.
        </p>
        <h3 style={styles.subSubHeading}>4.3.2 TE- Trial Elements</h3>
        <p style={styles.paragraphs}>
          The Trial Elements (TE) dataset contains the definitions of the
          elements that appear in the Trial Arms (TA) dataset. One record per
          planned Element.
        </p>
        <h3 style={styles.subSubHeading}>4.3.3 TV- Trial Visits</h3>
        <p style={styles.paragraphs}>
          The Trial Visits (TV) dataset describes the planned Visits in a trial.
          Visits are defined as “clinical encounters” and are described using
          the timing variables VISIT, VISITNUM and VISITDY. One record per
          planned Visit per Arm.
        </p>
        <h3 style={styles.subSubHeading}>
          4.3.4 TI- Trial Inclusion/Exclusion Criteria
        </h3>
        <p style={styles.paragraphs}>
          The Trial Inclusion Exclusion (TI) dataset is not subject oriented. It
          contains all the inclusion and exclusion criteria for the trial, and
          thus provides information that may not be present in the subject-level
          data on inclusion and exclusion criteria. Please refer to Appendix I
          for the inclusion/exclusion criteria.
        </p>
        <h3 style={styles.subSubHeading}>4.3.5 TS- Trial Summary</h3>
        <p style={styles.paragraphs}>
          Trial Summary is used to record basic information about the study such
          as trial phase, protocol title, and trial objectives. The Trial
          Summary dataset contains information about the planned and actual
          trial characteristics. One record per trial summary parameter value.
        </p>
        <h1 style={styles.heading}>5. Subject Data Description</h1>
        <h2 style={styles.subHeading}>5.1 Overview</h2>
        <p style={styles.paragraph}>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </p>
        <h2 style={styles.subHeading}>5.2 Annotated CRFs</h2>
        <p style={styles.paragraph}>
          <div dangerouslySetInnerHTML={{ __html: annotation }} />
        </p>
        <h2 style={styles.subHeading}>5.3 SDTM Subject Domains</h2>
        <table style={styles.table} className="mt-3">
          <thead>
            <tr>
              <th style={styles.th}>Dataset Label</th>
              <th style={styles.th}>Efficacy</th>
              <th style={styles.th}>Safety</th>
              <th style={styles.th}>Other</th>
              <th style={styles.th}>SUPP-</th>
              <th style={styles.th}>Related Using RELREC</th>
              <th style={styles.th}>Observation Class</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={styles.td}>DM- Demographics</td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}>X</td>
              <td style={styles.td}>X</td>
              <td style={styles.td}></td>
              <td style={styles.td}>Special Purpose</td>
            </tr>
            <tr>
              <td style={styles.td}>DM- Demographics</td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}>X</td>
              <td style={styles.td}>X</td>
              <td style={styles.td}></td>
              <td style={styles.td}>Special Purpose</td>
            </tr>
            <tr>
              <td style={styles.td}>DM- Demographics</td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}>X</td>
              <td style={styles.td}>X</td>
              <td style={styles.td}></td>
              <td style={styles.td}>Special Purpose</td>
            </tr>
            <tr>
              <td style={styles.td}>DM- Demographics</td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}>X</td>
              <td style={styles.td}>X</td>
              <td style={styles.td}></td>
              <td style={styles.td}>Special Purpose</td>
            </tr>
            <tr>
              <td style={styles.td}>EX- Exposure</td>
              <td style={styles.td}></td>
              <td style={styles.td}>X</td>
              <td style={styles.td}>X</td>
              <td style={styles.td}>X</td>
              <td style={styles.td}></td>
              <td style={styles.td}>Interventions</td>
            </tr>
            <tr>
              <td style={styles.td}>AE- Adverse Events</td>
              <td style={styles.td}></td>
              <td style={styles.td}>X</td>
              <td style={styles.td}>X</td>
              <td style={styles.td}>X</td>
              <td style={styles.td}></td>
              <td style={styles.td}>Events</td>
            </tr>
            <tr>
              <td style={styles.td}>EG- ECG Test Results</td>
              <td style={styles.td}></td>
              <td style={styles.td}>X</td>
              <td style={styles.td}>X</td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}>Findings</td>
            </tr>
            <tr>
              <td style={styles.td}>DS- Disposition</td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}>X</td>
              <td style={styles.td}>X</td>
              <td style={styles.td}></td>
              <td style={styles.td}>Events</td>
            </tr>
            <tr>
              <td style={styles.td}>DV- Protocol Deviations</td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}>X</td>
              <td style={styles.td}>X</td>
              <td style={styles.td}></td>
              <td style={styles.td}>Events</td>
            </tr>
            <tr>
              <td style={styles.td}>LB- Laboratory Test Results</td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}>Findings</td>
            </tr>
            <tr>
              <td style={styles.td}>PC- Pharmacokinetic Concentrations</td>
              <td style={styles.td}>X</td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}>X</td>
              <td style={styles.td}></td>
              <td style={styles.td}>Findings</td>
            </tr>
            <tr>
              <td style={styles.td}>PP- Pharmacokinetic Parameters</td>
              <td style={styles.td}>X</td>
              <td style={styles.td}>X</td>
              <td style={styles.td}></td>
              <td style={styles.td}>X</td>
              <td style={styles.td}></td>
              <td style={styles.td}>Findings</td>
            </tr>
            <tr>
              <td style={styles.td}>PE- Physical Examination</td>
              <td style={styles.td}></td>
              <td style={styles.td}>X</td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}>Findings</td>
            </tr>
            <tr>
              <td style={styles.td}>PE- Physical Examination</td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}>X</td>
              <td style={styles.td}></td>
              <td style={styles.td}>Findings</td>
            </tr>
            <tr>
              <td style={styles.td}>VS- Vital Signs</td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}>Findings</td>
            </tr>
            <tr> 
              <td style={styles.td}>SC- Subject Characteristics</td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}>X</td>
              <td style={styles.td}></td>
              <td style={styles.td}>Findings</td>
            </tr>
            <tr>
              <td style={styles.td}>ML- Meal Data</td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}></td>
              <td style={styles.td}>X</td>
              <td style={styles.td}></td>
              <td style={styles.td}>Findings</td>
            </tr>
          </tbody>
        </table>
        <h3 style={styles.subSubHeading}>5.3.1 DM- Demographics</h3>
        <p style={styles.paragraphs}>
          The Demographics domain includes a set of essential standard variables
          that describe each subject in a clinical study. It is the parent
          domain for all other observations for human clinical subjects. One
          record per subject, Tabulation.
        </p>
        <h3 style={styles.subSubHeading}>5.3.2 SE- Subject Element</h3>
        <p style={styles.paragraphs}>
          The Subject Elements dataset is particularly useful for studies with
          multiple treatment periods, such as crossover studies. The Subject
          Elements dataset contains the date/times at which a subject moved from
          one Element to another. One record per actual Element per subject.
        </p>
        <h3 style={styles.subSubHeading}>5.3.3 SV- Subject Visits</h3>
        <p style={styles.paragraphs}>
          The Subject Visits domain consolidates information about the timing of
          subject visits that is otherwise spread over domains that include the
          visit variables (VISITNUM and possibly VISIT and/or VISITDY). One
          record per subject per actual visit.
        </p>
        <h3 style={styles.subSubHeading}>5.3.4 CO- Comments</h3>
        <p style={styles.paragraphs}>
          The Comments dataset accommodates two sources of comments: 1) those
          collected alongside other data on topical case report form (CRF) pages
          such as Adverse Events and 2) those collected on a separate page
          specifically dedicated to comments. One record per comment per
          subject, Tabulation.
        </p>
        <h3 style={styles.subSubHeading}>5.3.5 EX- Exposure</h3>
        <p style={styles.paragraphs}>
          The Exposure domain model records the details of a subject's exposure
          to protocol-specified study treatment. Study treatment may be any
          intervention that is prospectively defined as a test material within a
          study and is typically but not always supplied to the subject. One
          record per protocol-specified study treatment, constant-dosing
          interval, per subject, Tabulation.
        </p>
        <h3 style={styles.subSubHeading}>5.3.6 AE- Adverse Events</h3>
        <p style={styles.paragraphs}>
          Adverse events may be captured either as free text or a pre-specified
          list of terms. One record per adverse event per subject, Tabulation. •
          Variables AELLT, AELLTCD, AEPTCD, AEHLT, AEHLTCD, AEHLGT, AEHLGTCD,
          AEBODSYS, AEBDSYCD, AESOC and AESOCCD are null as MedDRA coding is not
          mandatory
        </p>
        <h3 style={styles.subSubHeading}>5.3.7 EG- ECG Test Results</h3>
        <p style={styles.paragraphs}>
          Findings related to the collection of ECG data, including position of
          the subject, method of evaluation, all cycle measurements and all
          findings from the ECG including an overall interpretation if collected
          or derived. One record per ECG observation per time point per visit
          per subject, Tabulation.
        </p>
        <h3 style={styles.subSubHeading}>5.3.8 DS- Disposition</h3>
        <p style={styles.paragraphs}>
          A subject domain utilized for the submission of information
          encompassing and represent in data, vocabulary or records related to
          disposition. One record per disposition status or protocol milestone
          per subject, Tabulation.
        </p>
        <h3 style={styles.subSubHeading}>5.3.9 DV- Protocol Deviations</h3>
        <p style={styles.paragraphs}>
          The intent of the domain is to capture protocol violations and
          deviations during the course of the study and will store only those
          criteria violations by or deviated from by the subject and not a
          response to each violation or deviation. One record per protocol
          deviation per subject, Tabulation. • Were there any deviations
          recorded for protocol /procedure in the study? Yes If Yes, Specify the
          deviation and the reason Deviations and reasons are added in DV.xpt
        </p>
        <h3 style={styles.subSubHeading}>5.3.10 LB- Laboratory Test Results</h3>
        <p style={styles.paragraphs}>
          Laboratory test findings including, but is not limited to hematology,
          clinical chemistry and urinalysis data. This domain does not include
          pharmacokinetic data, which are stored in separate domains. One record
          per lab test per time point per visit per subject, Tabulation.
        </p>
        <h3 style={styles.subSubHeading}>
          5.3.11 PC- Pharmacokinetic Concentrations
        </h3>
        <p style={styles.paragraphs}>
          Concentrations of drugs/metabolites in plasma as a function of time.
          One record per sample characteristic or time-point concentration per
          reference time point or per analyte per subject, Tabulation. • Were
          all subjects participated in PK analysis? Yes If No, Specify the
          subject number and reason.
        </p>
        <h3 style={styles.subSubHeading}>
          5.3.12 PP- Pharmacokinetic Parameters
        </h3>
        <p style={styles.paragraphs}>
          Pharmacokinetic parameters derived from pharmacokinetic
          concentration-time (PC) data. One record per PK parameter per
          time-concentration profile per subject, Tabulation. • Were all
          subjects participated in PK analysis? Yes If No, Specify the subject
          number and reason.
        </p>
        <h3 style={styles.subSubHeading}>5.3.14 VS- Vital Signs</h3>
        <p style={styles.paragraphs}>
          Measurements including but not limited to blood pressure, temperature,
          respiration, BMI, height and weight. One record per vital sign
          measurement per time point per visit per subject, Tabulation.
        </p>
        <h3 style={styles.subSubHeading}>5.3.15 SC- Subject Characteristics</h3>
        <p style={styles.paragraphs}>
          The subject characteristics domain is for data not collected in other
          domains that is subject- related. One record per characteristic per
          subject, Tabulation
        </p>
        <h3 style={styles.subSubHeading}>5.3.16 ML- Meals Data</h3>
        <p style={styles.paragraphs}>
          The subject’s meal consumption were included in the Meal Data domain
          as per the Protocol. One record per food occurrence or constant intake
          interval per subject, Tabulation.
        </p>
        <h2 style={styles.subHeading}>5.4 SDTM Relationship Domains</h2>
        <h3 style={styles.subSubHeading}>
          5.4.1 SUPPDM- Supplemental Qualifiers for DM
        </h3>
        <p style={styles.paragraphs}>
          This dataset was created to include extra information that captured in
          demographic and could not entirely fit into DM domain. Following
          variables are captured into SUPPDM:
        </p>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>QNAM</th>
              <th style={styles.th}>QLABEL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={styles.td}>ENRDTC</td>
              <td style={styles.td}>Date of Enrollment</td>
            </tr>
            <tr>
              <td style={styles.td}>VOLREGNO</td>
              <td style={styles.td}>Volunteer Registration No.</td>
            </tr>
          </tbody>
        </table>
        <h3 style={styles.subSubHeading}>
          5.4.2 SUPPEX- Supplemental Qualifiers for EX
        </h3>
        <p style={styles.paragraphs}>
          This dataset was created to include extra information that captured in
          exposure and could not entirely fit into EX domain. Following
          variables are captured into SUPPEX:
        </p>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>QNAM</th>
              <th style={styles.th}>QLABEL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={styles.td}>EXNOUNIT</td>
              <td style={styles.td}>Number of Unit Dispensed</td>
            </tr>
            <tr>
              <td style={styles.td}>MOUTHCHK</td>
              <td style={styles.td}>Mouth Check Done</td>
            </tr>
            <tr>
              <td style={styles.td}>SBCHWCRS</td>
              <td style={styles.td}>Subject Instructed Tablet Chew or Crush</td>
            </tr>
            <tr>
              <td style={styles.td}>TESTREF</td>
              <td style={styles.td}>Test/Reference</td>
            </tr>
            <tr>
              <td style={styles.td}>WATERVOL</td>
              <td style={styles.td}>Volume of Water</td>
            </tr>
          </tbody>
        </table>
        <h3 style={styles.subSubHeading}>
          5.4.3 SUPPAE- Supplemental Qualifiers for AE
        </h3>
        <p style={styles.paragraphs}>
          This dataset was created to include extra information that captured in
          adverse events and could not entirely fit into AE domain. Following
          variables are captured into SUPPAE:
        </p>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>QNAM</th>
              <th style={styles.th}>QLABEL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={styles.td}>AEACTION</td>
              <td style={styles.td}>Action Taken Event</td>
            </tr>
            <tr>
              <td style={styles.td}>AETRTEM</td>
              <td style={styles.td}>Treatment Emergent Flag</td>
            </tr>
            <tr>
              <td style={styles.td}>DOSEDTC</td>
              <td style={styles.td}>Last Dosing Date and Time</td>
            </tr>
            <tr>
              <td style={styles.td}>TRTCODE</td>
              <td style={styles.td}>Investigational Product Administered</td>
            </tr>
            <tr>
              <td style={styles.td}>VOLWITH</td>
              <td style={styles.td}>Volunteer Withdrawn</td>
            </tr>
          </tbody>
        </table>
        <h3 style={styles.subSubHeading}>
          5.4.4 SUPPPC- Supplemental Qualifiers for PC
        </h3>
        <p style={styles.paragraphs}>
          This dataset was created to include extra information that captured in
          Pharmacokinetic Concentrations and could not entirely fit into PC
          domain. Following variables are captured into SUPPPC:
        </p>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>QNAM</th>
              <th style={styles.th}>QLABEL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={styles.td}>PCACTTPT</td>
              <td style={styles.td}>PC Actual Timepoint</td>
            </tr>
            <tr>
              <td style={styles.td}>PCFORMUL</td>
              <td style={styles.td}>PC Formulation</td>
            </tr>
            <tr>
              <td style={styles.td}>PCSCHTIM</td>
              <td style={styles.td}>Scheduled Time of Collection</td>
            </tr>
          </tbody>
        </table>
        <h3 style={styles.subSubHeading}>
          5.4.5 SUPPPP- Supplemental Qualifiers for PP
        </h3>
        <p style={styles.paragraphs}>
          This dataset was created to include extra information that captured in
          Pharmacokinetic Parameters and could not entirely fit into PP domain.
          Following variables are captured into SUPPPP:
        </p>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>QNAM</th>
              <th style={styles.th}>QLABEL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={styles.td}>NSAMPLE</td>
              <td style={styles.td}>N Samples</td>
            </tr>
            <tr>
              <td style={styles.td}>PPFORMUL</td>
              <td style={styles.td}>PP Formulation</td>
            </tr>
          </tbody>
        </table>
        <h3 style={styles.subSubHeading}>
          5.4.6 SUPPDS- Supplemental Qualifiers for DS
        </h3>
        <p style={styles.paragraphs}>
          This dataset was created to include extra information that captured in
          Disposition and could not entirely fit into DS domain. Following
          variables are captured into SUPPDS:
        </p>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>QNAM</th>
              <th style={styles.th}>QLABEL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={styles.td}>DSTERM1</td>
              <td style={styles.td}>Reason For Withdrawal 1</td>
            </tr>
          </tbody>
        </table>
        <h3 style={styles.subSubHeading}>
          5.4.7 SUPPDV- Supplemental Qualifiers for DV
        </h3>
        <p style={styles.paragraphs}>
          This dataset was created to include extra information that captured in
          Protocol Deviations and could not entirely fit into DV domain.
          Following variables are captured into SUPPDV:
        </p>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>QNAM</th>
              <th style={styles.th}>QLABEL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={styles.td}>DVATIMC</td>
              <td style={styles.td}>Actual Time of Collection</td>
            </tr>
            <tr>
              <td style={styles.td}>DVPERIOD</td>
              <td style={styles.td}>Period No.</td>
            </tr>
            <tr>
              <td style={styles.td}>DVSTIMC</td>
              <td style={styles.td}>Scheduled Time of Collections</td>
            </tr>
            <tr>
              <td style={styles.td}>DVTIM</td>
              <td style={styles.td}>Deviation Time in Minute</td>
            </tr>
            <tr>
              <td style={styles.td}>DVTERM1</td>
              <td style={styles.td}>Protocol Deviation Term 1</td>
            </tr>
            <tr>
              <td style={styles.td}>DVTERM2</td>
              <td style={styles.td}>Protocol Deviation Term 2</td>
            </tr>
            <tr>
              <td style={styles.td}>DVTERM3</td>
              <td style={styles.td}>Protocol Deviation Term 3</td>
            </tr>
            <tr>
              <td style={styles.td}>DVTERM4</td>
              <td style={styles.td}>Protocol Deviation Term 4</td>
            </tr>
          </tbody>
        </table>
        <h3 style={styles.subSubHeading}>
          5.4.8 SUPPVS- Supplemental Qualifiers for VS
        </h3>
        <p style={styles.paragraphs}>
          This dataset was created to include extra information that captured in
          Vital Signs and could not entirely fit into VS domain. Following
          variables are captured into SUPPVS:
        </p>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>QNAM</th>
              <th style={styles.th}>QLABEL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={styles.td}>VSFIT</td>
              <td style={styles.td}>Fit For Dosing</td>
            </tr>
            <tr>
              <td style={styles.td}>VSSCHTIM</td>
              <td style={styles.td}>VS Scheduled Time</td>
            </tr>
          </tbody>
        </table>
        <h3 style={styles.subSubHeading}>
          5.4.9 SUPPML- Supplemental Qualifiers for ML
        </h3>
        <p style={styles.paragraphs}>
          This dataset was created to include extra information that captured in
          Meal Data and could not entirely fit into ML domain. Following
          variables are captured into SUPPML:
        </p>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>QNAM</th>
              <th style={styles.th}>QLABEL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={styles.td}>DTILFOOD</td>
              <td style={styles.td}>Details of Food Left Over by Subject</td>
            </tr>
            <tr>
              <td style={styles.td}>MLSCHTM</td>
              <td style={styles.td}>Meal Scheduled Time</td>
            </tr>
          </tbody>
        </table>
        <h1 style={styles.heading} className="mt-2">
          6. Data Conformance Summary
        </h1>
        <h2 style={styles.subHeading}>6.1 Conformance Inputs</h2>
        <p style={styles.paragraph}>
          <div dangerouslySetInnerHTML={{ __html: dataConformance }} />
        </p>
        <h2 style={styles.subHeading}>6.2 Issues Summary</h2>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>Dataset</th>
              <th style={styles.th}>Diagnostic Message</th>
              <th style={styles.th}>Count</th>
              <th style={styles.th}>Explanation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={styles.td}>AE</td>
              <td style={styles.td}>
                EPOCH value not found in 'Epoch' extensible codelist
              </td>
              <td style={styles.td}>13</td>
              <td style={styles.td}>
                "POST-STUDY" already present in EPOCH extensible codelist.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>AE</td>
              <td style={styles.td}>Missing End Time-Point value</td>
              <td style={styles.td}>8</td>
              <td style={styles.td}>
                AEENDTC is missing as adverse event end date is unknown.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>AE</td>
              <td style={styles.td}>
                Expected variable with missing value for all recordss
              </td>
              <td style={styles.td}>11</td>
              <td style={styles.td}>
                AELLT, AELLTCD, AEPTCD, AEHLT, AEHLTCD, AEHLGT, AEHLGTCD,
                AEBODSYS, AEBDSYCD, AESOC and AESOCCD are coding variables,
                coding variables are not mandatory
              </td>
            </tr>
            <tr>
              <td style={styles.td}>AE</td>
              <td style={styles.td}>
                Missing values for one or more variables subject to MedDRA:
                AELLT, AELLTCD, AEDECOD, AEPTCD, AEHLT, AEHLTCD, AEHLGT,
                AEHLGTCD, AEBODSYS, AEBDSYCD, AESOC, AESOCCD
              </td>
              <td style={styles.td}>13</td>
              <td style={styles.td}>
                AELLT, AELLTCD, AEDECOD, AEPTCD, AEHLT, AEHLTCD, AEHLGT,
                AEHLGTCD, AEBODSYS, AEBDSYCD, AESOC and AESOCCD are coding
                variables, coding variables are not mandatory.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>DM</td>
              <td style={styles.td}>
                Expected variable with missing value for all records
              </td>
              <td style={styles.td}>4</td>
              <td style={styles.td}>
                ACTARMUD, ARMNRS, DTHDTC and DTHFL are expected variables, these
                variables can be missing
              </td>
            </tr>
            <tr>
              <td style={styles.td}>DM</td>
              <td style={styles.td}>
                ARMCD/ARM is populated for a subject not assigned to treatment
              </td>
              <td style={styles.td}>2</td>
              <td style={styles.td}>
                Subject no. E01 and E02 are not treated.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>DM</td>
              <td style={styles.td}>
                ACTARMCD/ACTARM is populated for a subject who wasn't treated
              </td>
              <td style={styles.td}>2</td>
              <td style={styles.td}>
                Subject no. E01 and E02 are not treated.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>DS</td>
              <td style={styles.td}>
                EPOCH value not found in 'Epoch' extensible codelist
              </td>
              <td style={styles.td}>45</td>
              <td style={styles.td}>
                "POST-STUDY" already present in EPOCH extensible codelist.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>DS</td>
              <td style={styles.td}>
                Missing DSDTC variable, when DSDY variable is present
              </td>
              <td style={styles.td}>1</td>
              <td style={styles.td}>
                DSDTC is permissible variable hence not added and According to
                latest IG DSDY is expected variable hence added in DS domain.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>DV</td>
              <td style={styles.td}>
                Model permissible variable added into standard domain
              </td>
              <td style={styles.td}>2</td>
              <td style={styles.td}>
                DVTPTNUM and DVTPT added as required for data collected
              </td>
            </tr>
            <tr>
              <td style={styles.td}>DV</td>
              <td style={styles.td}>
                Duplicate records per Key Variables in Define.xml
              </td>
              <td style={styles.td}>4</td>
              <td style={styles.td}>DVTPT variable values are different</td>
            </tr>
            <tr>
              <td style={styles.td}>DV</td>
              <td style={styles.td}>Duplicate records in DV domain</td>
              <td style={styles.td}>4</td>
              <td style={styles.td}>DVTPT variable values are different</td>
            </tr>
            <tr>
              <td style={styles.td}>EG</td>
              <td style={styles.td}>
                EGORRESU value not found in 'Unit' extensible codelist
              </td>
              <td style={styles.td}>327</td>
              <td style={styles.td}>
                "msec" already present in Unit extensible codelist.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>EG</td>
              <td style={styles.td}>
                EGSTRESU value not found in 'Unit' extensible codelist
              </td>
              <td style={styles.td}>327</td>
              <td style={styles.td}>
                "msec" already present in Unit extensible codelist.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>EG</td>
              <td style={styles.td}>EGTEST value not found</td>
              <td style={styles.td}>423</td>
              <td style={styles.td}>
                "Heart Rate", "P", "P/QRS/T", "PP","PR Interval", "QRS
                Duration", "QT Interval","QTc by Bazet"
              </td>
            </tr>
            <tr>
              <td style={styles.td}>EG</td>
              <td style={styles.td}>
                EGTESTCD value not found in 'ECG Test Code' extensible codelist
              </td>
              <td style={styles.td}>423</td>
              <td style={styles.td}>
                "HR","P","PP","PQRST",”PRINT","QRSDUR","QTCB", "QTINT" and "RR"
                already present in "ECG Test code" codelist.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>LB</td>
              <td style={styles.td}>
                EPOCH value not found in 'Epoch' extensible codelist
              </td>
              <td style={styles.td}>1131</td>
              <td style={styles.td}>
                "POST-STUDY" already present in EPOCH extensible codelist.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>LB</td>
              <td style={styles.td}>
                LBNRIND value not found in 'Reference Range Indicator'
                extensible codelist
              </td>
              <td style={styles.td}>411</td>
              <td style={styles.td}>
                "CS","NCS" already present in "Reference Range Indicator"
                codelist.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>LB</td>
              <td style={styles.td}>
                LBORRESU value not found in 'Unit' extensible codelist
              </td>
              <td style={styles.td}>983</td>
              <td style={styles.td}>
                "Ery/uL",”HPF”,"Leu/uL",”gm/dL”,”gms/dL”,”mIU/mL”, “pg/mL”,
                ”x10^3/uL”,”x10^6/uL” already present in "Unit" codelist.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>LB</td>
              <td style={styles.td}>
                LBSTRESU value not found in 'Unit' extensible codelist
              </td>
              <td style={styles.td}>81</td>
              <td style={styles.td}>
                “Leu/uL”, ” mIU/mL”, ” pg/mL” already present in 'Unit'
                extensible codelist
              </td>
            </tr>
            <tr>
              <td style={styles.td}>LB</td>
              <td style={styles.td}>LBTEST value not found in</td>
              <td style={styles.td}>331</td>
              <td style={styles.td}>
                "HIV-1/2 Antibody","Hepatitis B Virus SurfaceAntigen",
                "Hepatitis C Virus Antibody","Others","Pus Cells", "Rapid Plasma
                Reagin" and "Transperancy" already present in "Laboratory Test
                Name" codelist.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>LB</td>
              <td style={styles.td}>
                LBTESTCD value not found in 'Laboratory Test Code' extensible
                codelist
              </td>
              <td style={styles.td}>331</td>
              <td style={styles.td}>
                "HBSAG","HCAB","HIV12AB","OTHERS","PCELLS","R PR","TRANSPER"
                already added in "Laboratory Test Code" codelist.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>SC</td>
              <td style={styles.td}>
                SCSTRESC value not found in 'Marital Status Response' extensible
                codelist when SCTESTCD == 'MARISTAT'
              </td>
              <td style={styles.td}>47</td>
              <td style={styles.td}>
                "Married", "Unmarried" already added in 'Marital Status
                Response' codelist.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>SE</td>
              <td style={styles.td}>
                EPOCH value not found in 'Epoch' extensible codelist
              </td>
              <td style={styles.td}>45</td>
              <td style={styles.td}>
                "POST-STUDY" already present in EPOCH extensible codelist.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>SV</td>
              <td style={styles.td}>
                EPOCH value not found in 'Epoch' extensible codelist
              </td>
              <td style={styles.td}>45</td>
              <td style={styles.td}>
                "POST-STUDY" already present in EPOCH extensible codelist.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>TA</td>
              <td style={styles.td}>
                EPOCH value not found in 'Epoch' extensible codelist
              </td>
              <td style={styles.td}>3</td>
              <td style={styles.td}>
                "POST-STUDY" already present in EPOCH extensible codelist.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>TS</td>
              <td style={styles.td}>
                TSPARM value not found in 'Trial Summary Parameter Test Name'
                extensible codelist
              </td>
              <td style={styles.td}>1</td>
              <td style={styles.td}>
                “Sponsor Reference Identifier” already added in "Trial Summary
                Parameter Test Name" codelist.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>TS</td>
              <td style={styles.td}>
                TSPARMCD value not found in 'Trial Summary Parameter Test Code'
                extensible codelist
              </td>
              <td style={styles.td}>1</td>
              <td style={styles.td}>
                “SPOREFID” already added in "Trial Summary Parameter Test Code"
                codelist.
              </td>
            </tr>
            <tr>
              <td style={styles.td}>TS</td>
              <td style={styles.td}>
                Model permissible variable added into standard domain
              </td>
              <td style={styles.td}>2</td>
              <td style={styles.td}>
                TSVAL1 and TSVAL2 added as required for data collected
              </td>
            </tr>
          </tbody>
        </table>
        <h2 style={styles.subHeading}>
          Appendix I: Inclusion/Exclusion Criteria
        </h2>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>Protocol/Amendment Version</th>
              <th style={styles.th}>Category</th>
              <th style={styles.th}>IETESTCD</th>
              <th style={styles.th}>Full Text of Criterion</th>
            </tr>
          </thead>
          <tbody>
            {appendixData?.map((item, index) => (
              <tr key={index}>
                <td style={styles.td}>{item.version}</td>
                <td style={styles.td}>{item.category}</td>
                <td style={styles.td}>{item.IETESTCD}</td>
                <td style={styles.td}>{item.criterion}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CDRGPdfDataDetails;
