import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { VscChevronDown, VscChevronRight } from "react-icons/vsc";
import { usersOnboardingService } from "../../Services/usersOnboarding";
import { GoChecklist, GoChevronDown, GoChevronUp } from "react-icons/go";
import { TfiComments } from "react-icons/tfi";
import { CiPillsBottle1 } from "react-icons/ci";
import { FaDatabase, FaFolder } from "react-icons/fa6";
import { MdOutlineContentPasteSearch } from "react-icons/md";
import { BsHighlights } from "react-icons/bs";

function CDISCSidebar({ handleAddNewTab, checkActiveTab, studyId }) {
  const [openSites, setOpenSites] = useState(false);
  const [openSTDMData, setOpenSTDMData] = useState(false);
  const [openSTDMTabulation, setOpenSTDMTabulation] = useState(false);
  const [openStudySettings, setOpenStudySettings] = useState(false);
  const [studyLabel, setStudyLabel] = useState("");
  const [openCDRGMetadata, setOpenCDRGMetadata] = useState(false);
  const [openSubjectDataDescription, setOpenSubjectDataDescription] =
    useState(false);

  const finadStudyLabel = async () => {
    let data = await usersOnboardingService.finadStudyLabel(studyId);
    setStudyLabel(data?.data);
  };

  useEffect(() => {
    finadStudyLabel();
  }, [studyId]);

  return (
    <>
      <div className="link__tab">
        <div
          onClick={() => setOpenSites(!openSites)}
          aria-expanded={openSites}
          className={"text-start h-auto tab_url w-100"}
          style={{ background: "#ccd6dd" }}
        >
          <div className="hstack justify-content-between">
            <div>
              <i className={"me-2 ps-0 fa-solid fa-folder"}></i>
              <span>{studyLabel}</span>
            </div>
          </div>
        </div>
      </div>

      <Collapse in={true}>
        <div>
          <div
            className={`CP small link__tab ${checkActiveTab("cdiscStudyDetails") && "selectedMenu"
              }`}
            onClick={() => {
              handleAddNewTab(
                "Study Details",
                "fa-solid fa-address-card",
                "cdiscStudyDetails"
              );
            }}
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building ps-2 me-2 small"></i>
              Study Details
            </div>
          </div>

          <div
            className={`CP small link__tab ${checkActiveTab("protocolMetadata") && "selectedMenu"
              }`}
            onClick={() => {
              handleAddNewTab(
                "Study Protocol",
                "fa-solid fa-address-card",
                "protocolMetadata"
              );
            }}
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building ps-2 me-2 small"></i>
              Study Protocol
            </div>
          </div>

          <div
            className={`CP small link__tab ${checkActiveTab("auditTrial") && "selectedMenu"
              }`}
            onClick={() => {
              handleAddNewTab(
                "Audit Trial",
                "fa-solid fa-address-card",
                "auditTrial"
              );
            }}
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building ps-2 me-2 small"></i>
              Audit Trail
            </div>
          </div>

          <div
            className={`CP small link__tab ${openSTDMData && "selectedMenu"}`}
            onClick={() => setOpenSTDMData(!openSTDMData)}
          >
            <div className="hstack justify-content-between">
              <span className="text-start h-auto tab_url w-100">
                <span>
                  <i
                    className={`ps-2 small fa-solid ${openSTDMData ? "fa-folder-open" : "fa-folder"
                      }`}
                  ></i>
                </span>
                <span className="ms-2 fs-13">SDTM</span>
              </span>
              <span>
                {openSTDMData ? <VscChevronDown /> : <VscChevronRight />}
              </span>
            </div>
          </div>
          <Collapse in={openSTDMData}>
            <div>
              <div
                className={`CP small link__tab ps-2 ${checkActiveTab("sdtmDataset") && "selectedMenu"
                  }`}
                onClick={() => {
                  handleAddNewTab(
                    "Datasets",
                    "fa-solid fa-address-card",
                    "sdtmDataset"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building ps-2 me-2 small"></i>
                  Datasets
                </div>
              </div>
              <div
                className={`CP small link__tab ps-2 ${checkActiveTab("sdtmVariable") && "selectedMenu"
                  }`}
                onClick={() => {
                  handleAddNewTab(
                    "Variable",
                    "fa-solid fa-address-card",
                    "sdtmVariable"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building ps-2 me-2 small"></i>
                  Variable
                </div>
              </div>
              <div
                className={`CP small link__tab ps-2 ${checkActiveTab("sdtmValueLavel") && "selectedMenu"
                  }`}
                onClick={() => {
                  handleAddNewTab(
                    "Value Lavel",
                    "fa-solid fa-address-card",
                    "sdtmValueLavel"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building ps-2 me-2 small"></i>
                  Value Lavel
                </div>
              </div>
              <div
                className={`CP small link__tab ps-2 ${checkActiveTab("sdtmWhereClause") && "selectedMenu"
                  }`}
                onClick={() => {
                  handleAddNewTab(
                    "Where Clause",
                    "fa-solid fa-address-card",
                    "sdtmWhereClause"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building ps-2 me-2 small"></i>
                  Where Clause
                </div>
              </div>
              <div
                className={`CP small link__tab ps-2 ${checkActiveTab("sdtmCodeList") && "selectedMenu"
                  }`}
                onClick={() => {
                  handleAddNewTab(
                    "Code List",
                    "fa-solid fa-address-card",
                    "sdtmCodeList"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building ps-2 me-2 small"></i>
                  Code List
                </div>
              </div>
              <div
                className={`CP small link__tab ps-2 ${checkActiveTab("sdtmMethod") && "selectedMenu"
                  }`}
                onClick={() => {
                  handleAddNewTab(
                    "Method",
                    "fa-solid fa-address-card",
                    "sdtmMethod"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building ps-2 me-2 small"></i>
                  Method
                </div>
              </div>
              <div
                className={`CP small link__tab ps-2 ${checkActiveTab("sdtmComment") && "selectedMenu"
                  }`}
                onClick={() => {
                  handleAddNewTab(
                    "Comment",
                    "fa-solid fa-address-card",
                    "sdtmComment"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building ps-2 me-2 small"></i>
                  Comment
                </div>
              </div>
              <div
                className={`CP small link__tab ps-2 ${checkActiveTab("sdtmDocument") && "selectedMenu"
                  }`}
                onClick={() => {
                  handleAddNewTab(
                    "Document",
                    "fa-solid fa-address-card",
                    "sdtmDocument"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building ps-2 me-2 small"></i>
                  Document
                </div>
              </div>
              <div
                className={`CP small link__tab ps-2 ${checkActiveTab("sdtmDictionaries") && "selectedMenu"
                  }`}
                onClick={() => {
                  handleAddNewTab(
                    "Dictionaries",
                    "fa-solid fa-address-card",
                    "sdtmDictionaries"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building ps-2 me-2 small"></i>
                  Dictionaries
                </div>
              </div>
              <div
                className={`CP small link__tab ps-2 ${checkActiveTab("sdtmADRG") && "selectedMenu"
                  }`}
                onClick={() => {
                  handleAddNewTab(
                    "ADRG",
                    "fa-solid fa-address-card",
                    "sdtmADRG"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building ps-2 me-2 small"></i>
                  ADRG
                </div>
              </div>
              <div
                className={`CP small link__tab ps-2 ${checkActiveTab("sdtmCDRG") && "selectedMenu"
                  }`}
                onClick={() => {
                  handleAddNewTab(
                    "CDRG",
                    "fa-solid fa-address-card",
                    "sdtmCDRG"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building ps-2 me-2 small"></i>
                  CDRG
                </div>
              </div>
              <div
                className={`CP small link__tab ps-2 ${checkActiveTab("sdtmACRF") && "selectedMenu"
                  }`}
                onClick={() => {
                  handleAddNewTab(
                    "ACRF",
                    "fa-solid fa-address-card",
                    "sdtmACRF"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building ps-2 me-2 small"></i>
                  ACRF
                </div>
              </div>
              <div
                className={`CP small link__tab ps-2 ${openSTDMTabulation && "selectedMenu"
                  }`}
                onClick={() => setOpenSTDMTabulation(!openSTDMTabulation)}
              >
                <div className="hstack justify-content-between">
                  <span className="text-start h-auto tab_url w-100">
                    <span>
                      <i
                        className={`ps-2 small fa-solid ${openSTDMTabulation ? "fa-folder-open" : "fa-folder"
                          }`}
                      ></i>
                    </span>
                    <span className="ms-2 fs-13">tabulation</span>
                  </span>
                  <span>
                    {openSTDMTabulation ? (
                      <VscChevronDown />
                    ) : (
                      <VscChevronRight />
                    )}
                  </span>
                </div>
              </div>
              <Collapse in={openSTDMTabulation}>
                <div>
                  <div
                    className={`CP small link__tab ps-3 ${checkActiveTab("tabulationStdm") && "selectedMenu"
                      }`}
                    onClick={() => {
                      handleAddNewTab(
                        "stdm",
                        "fa-solid fa-address-card",
                        "tabulationStdm"
                      );
                    }}
                  >
                    <div className="text-start h-auto tab_url w-100">
                      <i className="fa-solid fa-building ps-2 me-2 small"></i>
                      stdm
                    </div>
                  </div>
                  <div
                    className={`CP small link__tab ps-3 ${checkActiveTab("tabulationAdam") && "selectedMenu"
                      }`}
                    onClick={() => {
                      handleAddNewTab(
                        "adam",
                        "fa-solid fa-address-card",
                        "tabulationAdam"
                      );
                    }}
                  >
                    <div className="text-start h-auto tab_url w-100">
                      <i className="fa-solid fa-building ps-2 me-2 small"></i>
                      adam
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          </Collapse>
          <div
            className={`CP small link__tab ${openStudySettings && "selectedMenu"
              }`}
            onClick={() => setOpenStudySettings(!openStudySettings)}
          >
            <div className="hstack justify-content-between">
              <span className="text-start h-auto tab_url w-100">
                <span>
                  <i
                    className={`ps-2 small fa-solid ${openStudySettings ? "fa-folder-open" : "fa-folder"
                      }`}
                  ></i>
                </span>
                <span className="ms-2 fs-13">Study Settings</span>
              </span>
              <span>
                {openStudySettings ? <VscChevronDown /> : <VscChevronRight />}
              </span>
            </div>
          </div>
          <Collapse in={openStudySettings}>
            <div>
              <div
                className={`CP small link__tab ps-2 ${checkActiveTab("sdtmStudyMetdata") && "selectedMenu"
                  }`}
                onClick={() => {
                  handleAddNewTab(
                    "Study Metadata",
                    "fa-solid fa-address-card",
                    "sdtmStudyMetdata"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building ps-2 me-2 small"></i>
                  Study Metadata
                </div>
              </div>
              <div
                className={`CP small link__tab ps-2 ${checkActiveTab("sdtmSDAData") && "selectedMenu"
                  }`}
                onClick={() => {
                  handleAddNewTab(
                    "Source Data Analytics",
                    "fa-solid fa-address-card",
                    "sdtmSDAData"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building ps-2 me-2 small"></i>
                  Source Data Analytics (SDA)
                </div>
              </div>
              <div
                className={`CP small link__tab ps-2 ${checkActiveTab("sdtSettings") && "selectedMenu"
                  }`}
                onClick={() => {
                  handleAddNewTab(
                    "SDTM Settings",
                    "fa-solid fa-address-card",
                    "sdtSettings"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building ps-2 me-2 small"></i>
                  SDTM Settings
                </div>
              </div>
              <div
                className={`CP small link__tab ps-2 ${checkActiveTab("studyValueLavel") && "selectedMenu"
                  }`}
                onClick={() => {
                  handleAddNewTab(
                    "Value Lavel",
                    "fa-solid fa-address-card",
                    "studyValueLavel"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building ps-2 me-2 small"></i>
                  Value Lavel
                </div>
              </div>
              <div
                className={`CP small link__tab ps-2 ${checkActiveTab("studySupplementary") && "selectedMenu"
                  }`}
                onClick={() => {
                  handleAddNewTab(
                    "Supplementary",
                    "fa-solid fa-address-card",
                    "studySupplementary"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building ps-2 me-2 small"></i>
                  Supplementary
                </div>
              </div>

              <div
                className={`CP small link__tab ${openCDRGMetadata && "selectedMenu"
                  }`}
                onClick={() => setOpenCDRGMetadata(!openCDRGMetadata)}
              >
                <div className="hstack justify-content-between">
                  <span className="text-start h-auto tab_url w-100">
                    <span>
                      <i
                        className={`ps-2 small fa-solid ${openCDRGMetadata ? "fa-folder-open" : "fa-folder"
                          }`}
                      ></i>
                    </span>
                    <span className="ms-2 fs-13">CDRG MetaData</span>
                  </span>
                  <span>
                    {openCDRGMetadata ? <VscChevronDown /> : <VscChevronRight />}
                  </span>
                </div>
              </div>
              <Collapse in={openCDRGMetadata}>
                <div>
                  <span
                    className={`CP ps-3 small linkClass ps-4 ${checkActiveTab("CDRGAcronymsData") &&
                      "selectedMenu"
                      }`}
                    onClick={() => {
                      handleAddNewTab(
                        "Acronyms Metadata",
                        "fa-solid fa-folder",
                        "CDRGAcronymsData"
                      );
                    }}
                  >
                    <TfiComments className="ps-2 me-2" style={{ fontSize: 20 }} />
                    Acronyms Metadata
                  </span>
                  <span
                    className={`CP small linkClass ps-4 ${checkActiveTab("CDRGTrialDesignDataset") && "selectedMenu"
                      }`}
                    onClick={() => {
                      handleAddNewTab(
                        `Trial Design Datasets`,
                        "fa-solid fa-folder",
                        "CDRGTrialDesignDataset"
                      );
                    }}
                    style={{marginLeft: -2}}
                  >
                    <CiPillsBottle1
                      className="ps-2 me-2"
                      style={{ fontSize: 20, marginLeft: 2 }}
                    />
                    Trial Design Datasets
                  </span>

                  <span
                    className={`border-bottom CP ps-4 small linkClass ${openSubjectDataDescription && "selectedMenu"
                      }`}
                    onClick={() =>
                      setOpenSubjectDataDescription(!openSubjectDataDescription)
                    }
                    style={{marginLeft: -2}}
                  >
                    <div className="hstack justify-content-between w-100">
                      <span>
                        <FaFolder
                          className="ps-2 me-2"
                          style={{ fontSize: 20, marginLeft: 2 }}
                        />
                        Subject Data Description
                      </span>
                      {openSubjectDataDescription ? <GoChevronUp /> : <GoChevronDown />}
                    </div>
                  </span>
                  <Collapse in={openSubjectDataDescription}>
                    <div>
                      <span
                        className={`CP ps-3 small linkClass ps-5 ${checkActiveTab("descriptionOverview") && "selectedMenu"
                          }`}
                        onClick={() => {
                          handleAddNewTab(
                            `Overview`,
                            "fa-solid fa-folder",
                            "descriptionOverview"
                          );
                        }}
                      >
                        <MdOutlineContentPasteSearch
                          className="ps-1 me-2"
                          style={{ fontSize: 19, marginLeft: 1 }}
                        />
                        Overview
                      </span>
                      <span
                        className={`CP ps-3 small linkClass ps-5 ${checkActiveTab("annotatedCRFs") && "selectedMenu"
                          }`}
                        onClick={() => {
                          handleAddNewTab(
                            `Annotated CRFs`,
                            "fa-solid fa-folder",
                            "annotatedCRFs"
                          );
                        }}
                      >
                        <BsHighlights
                          className="ps-1 me-2"
                          style={{ fontSize: 19, marginLeft: 1 }}
                        />
                        Annotated CRFs
                      </span>
                    </div>
                  </Collapse>
                  <span
                    className={`CP ps-3 small linkClass ps-4 ${checkActiveTab("CDRGDataConformance") && "selectedMenu"
                      }`}
                    onClick={() => {
                      handleAddNewTab(
                        `Data Conformance Inputs`,
                        "fa-solid fa-folder",
                        "CDRGDataConformance"
                      );
                    }}
                  >
                    <FaDatabase
                      className="ps-2 me-2"
                      style={{ fontSize: 18, marginLeft: 2 }}
                    />
                    Data Conformance Inputs
                  </span>
                  <span
                    className={`CP ps-3 small linkClass ps-4 ${checkActiveTab("CDRGIssueSummary") && "selectedMenu"
                      }`}
                    onClick={() => {
                      handleAddNewTab(
                        `Issue Summary`,
                        "fa-solid fa-folder",
                        "CDRGIssueSummary"
                      );
                    }}
                  >
                    <GoChecklist
                      className="ps-2 me-2"
                      style={{ fontSize: 20, marginLeft: 2 }}
                    />
                    Issue Summary
                  </span>
                  <span
                    className={`CP ps-3 small linkClass ps-4 ${checkActiveTab("CDRGAppendixInclusion") && "selectedMenu"
                      }`}
                    onClick={() => {
                      handleAddNewTab(
                        `Appendix Excl/Incl Criteria`,
                        "fa-solid fa-folder",
                        "CDRGAppendixInclusion"
                      );
                    }}
                  >
                    <GoChecklist
                      className="ps-2 me-2"
                      style={{ fontSize: 20, marginLeft: 2 }}
                    />
                    Appendix Excl./Incl. Criteria
                  </span>
                </div>
              </Collapse>
            </div>
          </Collapse>

          <div
            className={`CP small link__tab ${checkActiveTab("crfDataId") && "selectedMenu"
              }`}
            onClick={() => {
              handleAddNewTab(
                "CRF Data",
                "fa-solid fa-address-card",
                "crfDataId"
              );
            }}
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building ps-2 me-2 small"></i>
              CRF Data
            </div>
          </div>
        </div>
      </Collapse>
    </>
  );
}

export default CDISCSidebar;
