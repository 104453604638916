import React from "react";
import { Form, Table } from "react-bootstrap";

const ECGFormValues = ({
  ecgForm = [],
  item,
  handleECGFormChange,
  ECGFormDataEntry,
  valueData,
  disabledData,
  tableRef,
  onMouseDown,
}) => {
  return (
    <div className="overflow-auto">
      <Table
        bordered
        ref={tableRef}
        className="custom-table tableLibrary border-start border-end mt-1"
      >
        <thead>
          <tr style={{ verticalAlign: "bottom" }}>
            <th
              onMouseDown={(e) => onMouseDown(e, 0)}
              className="position-sticky start-0 shadow"
              rowSpan={2}
            >
              <Form.Label className="m-0">
                ECG Test or Examination Name
              </Form.Label>
            </th>
            {ecgForm?.some((item) => item.ecgFormCategory === "yes") && (
              <th onMouseDown={(e) => onMouseDown(e, 1)} rowSpan={2}>
                <Form.Label className="m-0">Category for ECG Test</Form.Label>
              </th>
            )}
            {ecgForm?.some((item) => item.ecgFormCategory === "yes") && (
              <th onMouseDown={(e) => onMouseDown(e, 2)} rowSpan={2}>
                <Form.Label className="m-0">
                  Subcategory for ECG Test
                </Form.Label>
              </th>
            )}
            <th
              className="text-center"
              onMouseDown={(e) => onMouseDown(e, 3)}
              rowSpan={2}
            >
              <Form.Label className="m-0">Result</Form.Label>
            </th>
            <th
              className="text-center"
              onMouseDown={(e) => onMouseDown(e, 4)}
              rowSpan={2}
            >
              <Form.Label className="m-0">Units</Form.Label>
            </th>
            <th
              className="text-center"
              onMouseDown={(e) => onMouseDown(e, 5)}
              colSpan={2}
            >
              <Form.Label className="m-0">Reference Range Indicator</Form.Label>
            </th>
            <th
              className="text-center"
              onMouseDown={(e) => onMouseDown(e, 6)}
              colSpan={2}
            >
              <Form.Label className="m-0">Clinical Significance</Form.Label>
            </th>
            <th
              className="text-center"
              onMouseDown={(e) => onMouseDown(e, 7)}
              rowSpan={2}
            >
              <Form.Label className="m-0">Remark</Form.Label>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 8)} rowSpan={2}></th>
          </tr>
          <tr>
            <th onMouseDown={(e) => onMouseDown(e, 9)} className="text-center">
              <Form.Label className="m-0">Normal</Form.Label>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 10)} className="text-center">
              <Form.Label className="m-0">Abnormal</Form.Label>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 11)} className="text-center">
              <Form.Label className="m-0">CS</Form.Label>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 12)} className="text-center">
              <Form.Label className="m-0">NCS</Form.Label>
            </th>
          </tr>
        </thead>
        <tbody>
          {ecgForm?.map((row, key) => (
            <tr key={key}>
              <td className="position-sticky start-0 bg-white">
                <Form.Label className="m-0">{row.ecgFormName}</Form.Label>
              </td>
              {ecgForm?.some((item) => item.ecgFormCategory === "yes") && (
                <td>
                  <Form.Label className="m-0">
                    {row.ecgFormCategoryName}
                  </Form.Label>
                </td>
              )}
              {ecgForm?.some((item) => item.ecgFormSubCategory === "yes") && (
                <td>
                  <Form.Label className="m-0">
                    {row.ecgFormSubCategoryName}
                  </Form.Label>
                </td>
              )}
              <td
                className="p-0 text-center"
                style={{ background: disabledData && "#e9ecef" }}
              >
                <Form.Control
                  className="border-0 text-center w-100"
                  placeholder="Result"
                  style={{ minWidth: "max-content" }}
                  disabled={disabledData}
                  onChange={(e) =>
                    handleECGFormChange(
                      "EGORRES",
                      e.target.value,
                      "EGORRESU",
                      ECGFormDataEntry?.[key]?.EGORRESU || valueData?.[key]?.EGORRESU,
                      "EGORNRIND",
                      ECGFormDataEntry?.[key]?.EGORNRIND || valueData?.[key]?.EGORNRIND,
                      "EGCLSIG",
                      ECGFormDataEntry?.[key]?.EGCLSIG || valueData?.[key]?.EGCLSIG,
                      "EGCOM",
                      ECGFormDataEntry?.[key]?.EGCOM || valueData?.[key]?.EGCOM,
                      key,
                      item
                    )
                  }
                  value={
                    ECGFormDataEntry?.[key]?.EGORRES ||
                    valueData?.[key]?.EGORRES
                  }
                  type={row.ecgFormResultDataType || "text"}
                />
              </td>
              <td
                className="p-0"
                style={{
                  background:
                    (disabledData || row?.options?.length === 0) && "#e9ecef",
                }}
              >
                <Form.Select
                  className="border-0"
                  style={{ width: "max-content" }}
                  disabled={disabledData || row?.options?.length === 0}
                  onChange={(e) =>
                    handleECGFormChange(
                      "EGORRES",
                      ECGFormDataEntry?.[key]?.EGORRES || valueData?.[key]?.EGORRES,
                      "EGORRESU",
                      e.target.value,
                      "EGORNRIND",
                      ECGFormDataEntry?.[key]?.EGORNRIND || valueData?.[key]?.EGORNRIND,
                      "EGCLSIG",
                      ECGFormDataEntry?.[key]?.EGCLSIG || valueData?.[key]?.EGCLSIG,
                      "EGCOM",
                      ECGFormDataEntry?.[key]?.EGCOM || valueData?.[key]?.EGCOM,
                      key,
                      item
                    )
                  }
                >
                  <option>Select Unit</option>
                  {row?.options?.map((option, i) => (
                    <option
                      selected={
                        (ECGFormDataEntry?.[key]?.EGORRESU ||
                          valueData?.[key]?.EGORRESU) === option.value
                      }
                      key={i}
                      value={option.value}
                    >
                      {option.value}
                    </option>
                  ))}
                </Form.Select>
              </td>
              <td className="text-center">
                <Form.Check
                  type="radio"
                  name={`ecgformnormalype_${key}`}
                  value="Normal"
                  disabled={disabledData}
                  onChange={(e) =>
                    handleECGFormChange(
                      "EGORRES",
                      ECGFormDataEntry?.[key]?.EGORRES || valueData?.[key]?.EGORRES,
                      "EGORRESU",
                      ECGFormDataEntry?.[key]?.EGORRESU || valueData?.[key]?.EGORRESU,
                      "EGORNRIND",
                      e.target.value,
                      "EGCLSIG",
                      ECGFormDataEntry?.[key]?.EGCLSIG || valueData?.[key]?.EGCLSIG,
                      "EGCOM",
                      ECGFormDataEntry?.[key]?.EGCOM || valueData?.[key]?.EGCOM,
                      key,
                      item
                    )
                  }
                  checked={
                    (ECGFormDataEntry?.[key]?.EGORNRIND ||
                      valueData?.[key]?.EGORNRIND) === "Normal"
                  }
                />
              </td>
              <td className="text-center">
                <Form.Check
                  type="radio"
                  name={`ecgformnormalype_${key}`}
                  value="Abnormal"
                  disabled={disabledData}
                  onChange={(e) =>
                    handleECGFormChange(
                      "EGORRES",
                      ECGFormDataEntry?.[key]?.EGORRES || valueData?.[key]?.EGORRES,
                      "EGORRESU",
                      ECGFormDataEntry?.[key]?.EGORRESU || valueData?.[key]?.EGORRESU,
                      "EGORNRIND",
                      e.target.value,
                      "EGCLSIG",
                      ECGFormDataEntry?.[key]?.EGCLSIG || valueData?.[key]?.EGCLSIG,
                      "EGCOM",
                      ECGFormDataEntry?.[key]?.EGCOM || valueData?.[key]?.EGCOM,
                      key,
                      item
                    )
                  }
                  checked={
                    (ECGFormDataEntry?.[key]?.EGORNRIND ||
                      valueData?.[key]?.EGORNRIND) === "Abnormal"
                  }
                />
              </td>
              <td className="text-center">
                <Form.Check
                  type="radio"
                  name={`ecgformcstype_${key}`}
                  value={"CS"}
                  disabled={disabledData || (ECGFormDataEntry?.[key + ecgForm?.length]?.EGORNRIND ||
                    valueData?.[key + ecgForm?.length]?.EGORNRIND) === "Normal"}
                  onChange={(e) =>
                    handleECGFormChange(
                      "EGORRES",
                      ECGFormDataEntry?.[key]?.EGORRES || valueData?.[key]?.EGORRES,
                      "EGORRESU",
                      ECGFormDataEntry?.[key]?.EGORRESU || valueData?.[key]?.EGORRESU,
                      "EGORNRIND",
                      ECGFormDataEntry?.[key]?.EGORNRIND || valueData?.[key]?.EGORNRIND,
                      "EGCLSIG",
                      e.target.value,
                      "EGCOM",
                      ECGFormDataEntry?.[key]?.EGCOM || valueData?.[key]?.EGCOM,
                      key,
                      item
                    )
                  }
                  checked={
                    (ECGFormDataEntry?.[key]?.EGCLSIG ||
                      valueData?.[key]?.EGCLSIG) === "CS"
                  }
                />
              </td>
              <td className="text-center">
                <Form.Check
                  type="radio"
                  name={`ecgformcstype_${key}`}
                  value={"NCS"}
                  disabled={disabledData || (ECGFormDataEntry?.[key + ecgForm?.length]?.EGORNRIND ||
                    valueData?.[key + ecgForm?.length]?.EGORNRIND) === "Normal"}
                  onChange={(e) =>
                    handleECGFormChange(
                      "EGORRES",
                      ECGFormDataEntry?.[key]?.EGORRES || valueData?.[key]?.EGORRES,
                      "EGORRESU",
                      ECGFormDataEntry?.[key]?.EGORRESU || valueData?.[key]?.EGORRESU,
                      "EGORNRIND",
                      ECGFormDataEntry?.[key]?.EGORNRIND || valueData?.[key]?.EGORNRIND,
                      "EGCLSIG",
                      e.target.value,
                      "EGCOM",
                      ECGFormDataEntry?.[key]?.EGCOM || valueData?.[key]?.EGCOM,
                      key,
                      item
                    )
                  }
                  checked={
                    (ECGFormDataEntry?.[key]?.EGCLSIG ||
                      valueData?.[key]?.EGCLSIG) === "NCS"
                  }
                />
              </td>
              <td
                className="p-0"
                style={{ background: disabledData && "#e9ecef" }}
              >
                <Form.Control
                  disabled={disabledData}
                  className="border-0 w-100 p-1"
                  placeholder="Remark"
                  style={{ resize: "none" }}
                  onChange={(e) =>
                    handleECGFormChange(
                      "EGORRES",
                      ECGFormDataEntry?.[key]?.EGORRES || valueData?.[key]?.EGORRES,
                      "EGORRESU",
                      ECGFormDataEntry?.[key]?.EGORRESU || valueData?.[key]?.EGORRESU,
                      "EGORNRIND",
                      ECGFormDataEntry?.[key]?.EGORNRIND || valueData?.[key]?.EGORNRIND,
                      "EGCLSIG",
                      ECGFormDataEntry?.[key]?.EGCLSIG || valueData?.[key]?.EGCLSIG,
                      "EGCOM",
                      e.target.value,
                      key,
                      item
                    )
                  }
                  value={
                    ECGFormDataEntry?.[key]?.EGCOM || valueData?.[key]?.LBORRES
                  }
                  as={"textarea"}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ECGFormValues;
