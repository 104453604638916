import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams } from "react-router";
import { cdrgStudyServices } from "../../../../../Services/CDISC/cdrgStudyServices";

const SubjectDataDescriptionOverview = () => {
  const { studyId } = useParams();
  const [loader, setLoader] = useState("");
  const [description, setDescription] = useState("");

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ 'font': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }],
      ['link', 'image', 'video'],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ['clean'],
    ],
  };

  const Editor = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ];

  const getStudySubjectDataDescription = async () => {
    let data = await cdrgStudyServices.getStudySubjectDataDescription("overview", studyId);
    setDescription(data?.data?.description || "");
  };

  const addUpdateStudySubjectDataDescription = async (dataType) => {
    setLoader(true);
    let userData = {};
    userData.studyId = studyId
    userData.dataType = dataType;
    userData.type = "overview";
    userData.description = description;
    let data = await cdrgStudyServices.addUpdateStudySubjectDataDescription(userData);
    if (data?.statusCode === 200) {
      getStudySubjectDataDescription();
      setLoader(false);
    }
    setLoader(false);
  };

  useEffect(() => {
    getStudySubjectDataDescription();
  }, []);

  return (
    <>
      <div className="p-2 w-75 m-auto">
        <Form.Group className="mb-2">
          <Form.Label className="mb-1 fw-bold">Overview <span className="text-danger">*</span></Form.Label>
          <ReactQuill
            modules={modules}
            formats={Editor}
            theme="snow"
            value={description}
            onChange={setDescription}
          />
        </Form.Group>
        <div
          className="hstack gap-2 text-end w-100"
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <button className="submitbutton" onClick={() => addUpdateStudySubjectDataDescription("import")}>Import</button>
          <button className="submitbutton" onClick={() => addUpdateStudySubjectDataDescription("new")}>
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default SubjectDataDescriptionOverview;
