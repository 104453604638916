import React from "react";
import { Form, Table } from "react-bootstrap";

const VitalSignsFormValues = ({
  vitalSign = [],
  item,
  handleVitalSignChange,
  vitalSignDataEntry,
  valueData,
  disabledData,
  tableRef,
  onMouseDown,
}) => {
  return (
    <div className="overflow-auto">
      <Table
        bordered
        ref={tableRef}
        className="custom-table tableLibrary border-start border-end mt-1"
      >
        <thead>
          <tr style={{ verticalAlign: "bottom" }}>
            <th
              onMouseDown={(e) => onMouseDown(e, 0)}
              className="position-sticky start-0 shadow"
              rowSpan={2}
            >
              <Form.Label className="m-0">
                Vital Signs Test or Examination Name
              </Form.Label>
            </th>
            {vitalSign?.some((item) => item.vitalSignCategory === "yes") && (
              <th rowSpan={2} onMouseDown={(e) => onMouseDown(e, 1)}>
                <Form.Label className="m-0">
                  Category for Vital Signs Test
                </Form.Label>
              </th>
            )}
            {vitalSign?.some((item) => item.vitalSignCategory === "yes") && (
              <th onMouseDown={(e) => onMouseDown(e, 2)} rowSpan={2}>
                <Form.Label className="m-0">
                  Subcategory for Vital Signs Test
                </Form.Label>
              </th>
            )}
            <th
              className="text-center"
              onMouseDown={(e) => onMouseDown(e, 3)}
              rowSpan={2}
            >
              <Form.Label className="m-0">Result</Form.Label>
            </th>
            <th
              onMouseDown={(e) => onMouseDown(e, 4)}
              rowSpan={2}
              className="text-center"
            >
              <Form.Label className="m-0">Units</Form.Label>
            </th>
            <th
              onMouseDown={(e) => onMouseDown(e, 5)}
              colSpan={2}
              className="text-center"
            >
              <Form.Label className="m-0">Reference Range Indicator</Form.Label>
            </th>
            <th
              onMouseDown={(e) => onMouseDown(e, 6)}
              colSpan={2}
              className="text-center"
            >
              <Form.Label className="m-0">Clinical Significance</Form.Label>
            </th>
            <th
              onMouseDown={(e) => onMouseDown(e, 7)}
              rowSpan={2}
            >
              <Form.Label className="m-0">
                Remark &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Form.Label>
            </th>
            <th rowSpan={2} onMouseDown={(e) => onMouseDown(e, 8)}></th>
          </tr>
          <tr>
            <th onMouseDown={(e) => onMouseDown(e, 9)} className="text-center">
              <Form.Label className="m-0">Normal</Form.Label>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 10)} className="text-center">
              <Form.Label className="m-0">Abnormal</Form.Label>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 11)} className="text-center">
              <Form.Label className="m-0">CS</Form.Label>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 12)} className="text-center">
              <Form.Label className="m-0">NCS</Form.Label>
            </th>
          </tr>
        </thead>
        <tbody style={{ whiteSpace: "nowrap" }}>
          {vitalSign?.map((row, key) => (
            <tr key={key}>
              <td className="position-sticky start-0 bg-white">
                <Form.Label className="m-0">{row.vitalSignName}</Form.Label>
              </td>
              {vitalSign?.some((item) => item.vitalSignCategory === "yes") && (
                <td>
                  <Form.Label className="m-0">
                    {row.vitalSignCategoryName}
                  </Form.Label>
                </td>
              )}
              {vitalSign?.some(
                (item) => item.vitalSignSubCategory === "yes"
              ) && (
                <td>
                  <Form.Label className="m-0">
                    {row.vitalSignSubCategoryName}
                  </Form.Label>
                </td>
              )}
              <td
                className="p-0"
                style={{ background: disabledData && "#e9ecef" }}
              >
                <Form.Control
                  className="border-0 text-center w-100"
                  placeholder="Result"
                  style={{ minWidth: "max-content" }}
                  disabled={disabledData}
                  onChange={(e) =>
                    handleVitalSignChange(
                      "VSORRES",
                      e.target.value,
                      "VSORRESU",
                      vitalSignDataEntry?.[key]?.VSORRESU || valueData?.[key]?.VSORRESU,
                      "VSORNRIND",
                      vitalSignDataEntry?.[key]?.VSORNRIND || valueData?.[key]?.VSORNRIND,
                      "VSCLSIG",
                      vitalSignDataEntry?.[key]?.VSCLSIG || valueData?.[key]?.VSCLSIG,
                      "VSCOM",
                      vitalSignDataEntry?.[key]?.VSCOM || valueData?.[key]?.VSCOM,
                      key,
                      item
                    )
                  }
                  value={
                    vitalSignDataEntry?.[key]?.VSORRES ||
                    valueData?.[key]?.VSORRES
                  }
                  type={row.vitalSignResultDataType || "text"}
                />
              </td>
              <td
                className="p-0 text-center"
                style={{ background: (disabledData || (row?.options?.length === 0)) && "#e9ecef" }}
              >
                <Form.Select
                  className="border-0"
                  style={{ width: "max-content" }}
                  disabled={disabledData || (row?.options?.length === 0)}
                  onChange={(e) =>
                    handleVitalSignChange(
                      "VSORRES",
                      vitalSignDataEntry?.[key]?.VSORRES || valueData?.[key]?.VSORRES,
                      "VSORRESU",
                      e.target.value,
                      "VSORNRIND",
                      vitalSignDataEntry?.[key]?.VSORNRIND || valueData?.[key]?.VSORNRIND,
                      "VSCLSIG",
                      vitalSignDataEntry?.[key]?.VSCLSIG || valueData?.[key]?.VSCLSIG,
                      "VSCOM",
                      vitalSignDataEntry?.[key]?.VSCOM || valueData?.[key]?.VSCOM,
                      key,
                      item
                    )
                  }
                >
                  <option>Select Unit</option>
                  {row?.options?.map((option, i) => (
                    <option
                      selected={
                        (vitalSignDataEntry?.[key]?.VSORRESU ||
                          valueData?.[key]?.VSORRESU) === option.value
                      }
                      key={i}
                      value={option.value}
                    >
                      {option.value}
                    </option>
                  ))}
                </Form.Select>
              </td>
              <td className="text-center">
                  <Form.Check
                    type="radio"
                    name={`vitalsignnormalype_${key}`}
                    value="Normal"
                    disabled={disabledData}
                    onChange={(e) =>
                      handleVitalSignChange(
                        "VSORRES",
                        vitalSignDataEntry?.[key]?.VSORRES || valueData?.[key]?.VSORRES,
                        "VSORRESU",
                        vitalSignDataEntry?.[key]?.VSORRESU || valueData?.[key]?.VSORRESU,
                        "VSORNRIND",
                        e.target.value,
                        "VSCLSIG",
                        vitalSignDataEntry?.[key]?.VSCLSIG || valueData?.[key]?.VSCLSIG,
                        "VSCOM",
                        vitalSignDataEntry?.[key]?.VSCOM || valueData?.[key]?.VSCOM,
                        key,
                        item
                      )
                    }
                    checked={
                      (vitalSignDataEntry?.[key]?.VSORNRIND ||
                        valueData?.[key]?.VSORNRIND) === "Normal"
                    }
                  />
              </td>
              <td className="text-center">
                  <Form.Check
                    type="radio"
                    name={`vitalsignnormalype_${key}`}
                    value="Abnormal"
                    disabled={disabledData}
                    onChange={(e) =>
                      handleVitalSignChange(
                        "VSORRES",
                        vitalSignDataEntry?.[key]?.VSORRES || valueData?.[key]?.VSORRES,
                        "VSORRESU",
                        vitalSignDataEntry?.[key]?.VSORRESU || valueData?.[key]?.VSORRESU,
                        "VSORNRIND",
                        e.target.value,
                        "VSCLSIG",
                        vitalSignDataEntry?.[key]?.VSCLSIG || valueData?.[key]?.VSCLSIG,
                        "VSCOM",
                        vitalSignDataEntry?.[key]?.VSCOM || valueData?.[key]?.VSCOM,
                        key,
                        item
                      )
                    }
                    checked={
                      (vitalSignDataEntry?.[key]?.VSORNRIND ||
                        valueData?.[key]?.VSORNRIND) === "Abnormal"
                    }
                  />
              </td>
              <td className="text-center">
                  <Form.Check
                    type="radio"
                    name={`vitalsigncstype_${key}`}
                    value={"CS"}
                    disabled={disabledData || (vitalSignDataEntry?.[key + vitalSign?.length]?.VSORNRIND ||
                      valueData?.[key + vitalSign?.length]?.VSORNRIND) === "Normal"}
                    onChange={(e) =>
                      handleVitalSignChange(
                        "VSORRES",
                        vitalSignDataEntry?.[key]?.VSORRES || valueData?.[key]?.VSORRES,
                        "VSORRESU",
                        vitalSignDataEntry?.[key]?.VSORRESU || valueData?.[key]?.VSORRESU,
                        "VSORNRIND",
                        vitalSignDataEntry?.[key]?.VSORNRIND || valueData?.[key]?.VSORNRIND,
                        "VSCLSIG",
                        e.target.value,
                        "VSCOM",
                        vitalSignDataEntry?.[key]?.VSCOM || valueData?.[key]?.VSCOM,
                        key,
                        item
                      )
                    }
                    checked={
                      (vitalSignDataEntry?.[key]?.VSCLSIG ||
                        valueData?.[key]?.VSCLSIG) === "CS"
                    }
                  />
              </td>
              <td className="text-center">
                  <Form.Check
                    type="radio"
                    name={`vitalsigncstype_${key}`}
                    value={"NCS"}
                    disabled={disabledData || (vitalSignDataEntry?.[key + vitalSign?.length]?.VSORNRIND ||
                      valueData?.[key + vitalSign?.length]?.VSORNRIND) === "Normal"}
                    onChange={(e) =>
                      handleVitalSignChange(
                        "VSORRES",
                        vitalSignDataEntry?.[key]?.VSORRES || vitalSign?.[key]?.VSORRES,
                        "VSORRESU",
                        vitalSignDataEntry?.[key]?.VSORRESU || vitalSign?.[key]?.VSORRESU,
                        "VSORNRIND",
                        vitalSignDataEntry?.[key]?.VSORNRIND || vitalSign?.[key]?.VSORNRIND,
                        "VSCLSIG",
                        e.target.value,
                        "VSCOM",
                        vitalSignDataEntry?.[key]?.VSCOM || vitalSign?.[key]?.VSCOM,
                        key,
                        item
                      )
                    }
                    checked={
                      (vitalSignDataEntry?.[key]?.VSCLSIG ||
                        valueData?.[key]?.VSCLSIG) === "NCS"
                    }
                  />
              </td>
              <td
                className="p-0"
                style={{ background: disabledData && "#e9ecef" }}
              >
                <Form.Control
                  disabled={disabledData}
                  className="border-0 w-100 p-1"
                  placeholder="Remark"
                  style={{ resize: "none" }}
                  onChange={(e) =>
                    handleVitalSignChange(
                      "VSORRES",
                      vitalSignDataEntry?.[key]?.VSORRES || valueData?.[key]?.VSORRES,
                      "VSORRESU",
                      vitalSignDataEntry?.[key]?.VSORRESU || valueData?.[key]?.VSORRESU,
                      "VSORNRIND",
                      vitalSignDataEntry?.[key]?.VSORNRIND || valueData?.[key]?.VSORNRIND,
                      "VSCLSIG",
                      vitalSignDataEntry?.[key]?.VSCLSIG || valueData?.[key]?.VSCLSIG,
                      "VSCOM",
                      e.target.value,
                      key,
                      item
                    )
                  }
                  value={
                    vitalSignDataEntry?.[key]?.VSCOM ||
                    valueData?.[key]?.LBORRES
                  }
                  as={"textarea"}
                />
              </td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default VitalSignsFormValues;
