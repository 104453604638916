import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { cdiscServices } from "../../../../Services/CDISC/cdiscServices";
import Swal from "sweetalert2";

const ReviewDataTemplate = ({ SHOW, HIDE, status, id, studyId, getStudyDataTemplates }) => {
    const [password, setPassword] = useState("");
    const reviewStatusChangeUserStudyDataTemplates = async () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to Completed this CRF!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#103C5E",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Completed it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                let userData = {}
                userData.id = id
                userData.reviewStatus = status
                userData.studyId = studyId
                userData.password = password
                let results = await cdiscServices.reviewStatusChangeUserStudyDataTemplates(userData)
                if (results?.statusCode === 200) {
                    getStudyDataTemplates()
                    HIDE()
                    setPassword()
                }
            }
        });
    };
    return (
        <>
            <Modal
                show={SHOW}
                className={SHOW ? "" : "d-none"}
                onHide={HIDE}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title >
                        Data Template Review Completion Signature
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-2">
                        <Form.Label className="mb-1 fw-bold">Signature Password</Form.Label>
                        <Form.Control
                            onChange={(e) => setPassword(e.target.value)}
                            type="text"
                            value={password}
                             
                            placeholder="Enter Your Signature Passowrd"
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="m-0">
                    <div className="text-end">
                        <button
                            onClick={reviewStatusChangeUserStudyDataTemplates}
                            className="updateProfile">Submit</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ReviewDataTemplate;
