import React from "react";
import { Form, Table } from "react-bootstrap";

const SwallonTenderJoints = ({
  swallonTenderJoint,
  item,
  valueData,
  tableRef,
  onMouseDown,
  handleSwallonTenderFormChange,
  swallonTenderEntry,
  disabledData
}) => {
  const joints = [
    { name: "Temporomandibular", group: "Head", leftTitle: "Left Temporomandibular", left: "left_temporomandibular", rightTitle: "Right Temporomandibular", right: "right_temporomandibular" },
    { name: "Sternoclavicular", group: "Head", leftTitle: "Left Sternoclavicular", left: "left_sternoclavicular", rightTitle: "Right Sternoclavicular", right: "right_sternoclavicular" },
    { name: "Acromioclavicular", group: "Head", leftTitle: "Left Acromioclavicular", left: "left_acromioclavicular", rightTitle: "Right Acromioclavicular", right: "right_acromioclavicular" },
    { name: "Shoulder", group: "Head", leftTitle: "Left Shoulder", left: "left_shoulder", rightTitle: "Right Shoulder", right: "right_shoulder" },
    { name: "Elbow", group: "Head", leftTitle: "Left Elbow", left: "left_elbow", rightTitle: "Right Elbow", right: "right_elbow" },
    { name: "Wrist", group: "Head", leftTitle: "Left Hand Wrist", left: "left_hand_wrist", rightTitle: "Right Hand Wrist", right: "right_hand_wrist" },
    { name: "MCP1", group: "MCPs", leftTitle: "Left Hand Metacarpophalangeal - I", left: "left_hand_metacarpophalangeal_i", rightTitle: "Right Hand Metacarpophalangeal - I", right: "right_hand_metacarpophalangeal_i" },
    { name: "MCP2", group: "MCPs", leftTitle: "Left Hand Metacarpophalangeal - II", left: "left_hand_metacarpophalangeal_ii", rightTitle: "Right Hand Metacarpophalangeal - II", right: "right_hand_metacarpophalangeal_ii" },
    { name: "MCP3", group: "MCPs", leftTitle: "Left Hand Metacarpophalangeal - III", left: "left_hand_metacarpophalangeal_iii", rightTitle: "Right Hand Metacarpophalangeal - III", right: "right_hand_metacarpophalangeal_iii" },
    { name: "MCP4", group: "MCPs", leftTitle: "Left Hand Metacarpophalangeal - IV", left: "left_hand_metacarpophalangeal_iv", rightTitle: "Right Hand Metacarpophalangeal - IV", right: "right_hand_metacarpophalangeal_iv" },
    { name: "MCP5", group: "MCPs", leftTitle: "Left Hand Metacarpophalangeal - V", left: "left_hand_metacarpophalangeal_v", rightTitle: "Right Hand Metacarpophalangeal - V", right: "right_hand_metacarpophalangeal_v" },
    { name: "Thumb interphalangeal", group: "PIPs", leftTitle: "Left Hand Proximal Interphalangeal - I", left: "left_hand_proximal_interphalangeal_i", rightTitle: "Right Hand Proximal Interphalangeal - I", right: "right_hand_proximal_interphalangeal_i" },
    { name: "Upper extremity PIP2", group: "PIPs", leftTitle: "Left Hand Proximal Interphalangeal - II", left: "left_hand_proximal_interphalangeal_ii", rightTitle: "Right Hand Proximal Interphalangeal - II", right: "right_hand_proximal_interphalangeal_ii" },
    { name: "Upper extremity PIP3", group: "PIPs", leftTitle: "Left Hand Proximal Interphalangeal - III", left: "left_hand_proximal_interphalangeal_iii", rightTitle: "Right Hand Proximal Interphalangeal - III", right: "right_hand_proximal_interphalangeal_iii" },
    { name: "Upper extremity PIP4", group: "PIPs", leftTitle: "Left Hand Proximal Interphalangeal - IV", left: "left_hand_proximal_interphalangeal_iv", rightTitle: "Right Hand Proximal Interphalangeal - IV", right: "right_hand_proximal_interphalangeal_iv" },
    { name: "Upper extremity PIP5", group: "PIPs", leftTitle: "Left Hand Proximal Interphalangeal - V", left: "left_hand_proximal_interphalangeal_v", rightTitle: "Right Hand Proximal Interphalangeal - V", right: "right_hand_proximal_interphalangeal_v" },
    { name: "DIP2", group: "DIPs", leftTitle: "Left Hand Distal Interphalangeal - I", left: "left_hand_distal_interphalangeal_i", rightTitle: "Right Hand Distal Interphalangeal - I", right: "right_hand_distal_interphalangeal_i" },
    { name: "DIP3", group: "DIPs", leftTitle: "Left Hand Distal Interphalangeal - II", left: "left_hand_distal_interphalangeal_ii", rightTitle: "Right Hand Distal Interphalangeal - II", right: "right_hand_distal_interphalangeal_ii" },
    { name: "DIP4", group: "DIPs", leftTitle: "Left Hand Distal Interphalangeal - III", left: "left_hand_distal_interphalangeal_iii", rightTitle: "Right Hand Distal Interphalangeal - III", right: "right_hand_distal_interphalangeal_iii" },
    { name: "DIP5", group: "DIPs", leftTitle: "Left Hand Distal Interphalangeal - IV", left: "left_hand_distal_interphalangeal_iv", rightTitle: "Right Hand Distal Interphalangeal - IV", right: "right_hand_distal_interphalangeal_iv" },
    { name: "Hip", group: "Hip Extremity", leftTitle: "Left Side Hip", left: "left_side_hip", rightTitle: "Right Side Hip", right: "right_side_hip" },
    { name: "Knee", group: "Lower Extremity", leftTitle: "Left Leg Knee", left: "left_leg_knee", rightTitle: "Right Leg Knee", right: "right_leg_knee" },
    { name: "Ankle", group: "Lower Extremity", leftTitle: "Left Leg Ankle", left: "left_leg_ankle", rightTitle: "Right Leg Ankle", right: "right_leg_ankle" },
    { name: "Tarsus/Midfoot(feet)", group: "Lower Extremity", leftTitle: "Left Leg Tarsus", left: "left_leg_tarsus", rightTitle: "Right Leg Tarsus", right: "right_leg_tarsus" },
    { name: "MTP1", group: "MTPs", leftTitle: "Left leg metatarsophalangeal - I", left: "left_leg_metatarsophalangeal_i", rightTitle: "Right leg metatarsophalangeal - I", right: "right_leg_metatarsophalangeal_i" },
    { name: "MTP2", group: "MTPs", leftTitle: "Left leg metatarsophalangeal - II", left: "left_leg_metatarsophalangeal_ii", rightTitle: "Right leg metatarsophalangeal - II", right: "right_leg_metatarsophalangeal_ii" },
    { name: "MTP3", group: "MTPs", leftTitle: "Left leg metatarsophalangeal - III", left: "left_leg_metatarsophalangeal_iii", rightTitle: "Right leg metatarsophalangeal - III", right: "right_leg_metatarsophalangeal_iii" },
    { name: "MTP4", group: "MTPs", leftTitle: "Left leg metatarsophalangeal - IV", left: "left_leg_metatarsophalangeal_iv", rightTitle: "Right leg metatarsophalangeal - IV", right: "right_leg_metatarsophalangeal_iv" },
    { name: "MTP5", group: "MTPs", leftTitle: "Left leg metatarsophalangeal - V", left: "left_leg_metatarsophalangeal_v", rightTitle: "Right leg metatarsophalangeal - V", right: "right_leg_metatarsophalangeal_v" },
    { name: "Lower Extremity Toe PIP1", group: "PIPs", leftTitle: "Left Leg Toe proximal interphalangeal - I", left: "left_leg_toe_proximal_interphalangeal_i", rightTitle: "Right Leg Toe proximal interphalangeal - I", right: "right_leg_toe_proximal_interphalangeal_i" },
    { name: "Lower Extremity Toe PIP2", group: "PIPs", leftTitle: "Left Leg Toe proximal interphalangeal - II", left: "left_leg_toe_proximal_interphalangeal_ii", rightTitle: "Right Leg Toe proximal interphalangeal - II", right: "right_leg_toe_proximal_interphalangeal_ii" },
    { name: "Lower Extremity Toe PIP3", group: "PIPs", leftTitle: "Left Leg Toe proximal interphalangeal - III", left: "left_leg_toe_proximal_interphalangeal_iii", rightTitle: "Right Leg Toe proximal interphalangeal - III", right: "right_leg_toe_proximal_interphalangeal_iii" },
    { name: "Lower Extremity Toe PIP4", group: "PIPs", leftTitle: "Left Leg Toe proximal interphalangeal - IV", left: "left_leg_toe_proximal_interphalangeal_iv", rightTitle: "Right Leg Toe proximal interphalangeal - IV", right: "right_leg_toe_proximal_interphalangeal_iv" },
    { name: "Lower Extremity Toe PIP5", group: "PIPs", leftTitle: "Left Leg Toe proximal interphalangeal - V", left: "left_leg_toe_proximal_interphalangeal_v", rightTitle: "Right Leg Toe proximal interphalangeal - V", right: "right_leg_toe_proximal_interphalangeal_v" }
  ];

  const renderRow = (joint, key) => {
    const isGroupStart =
      key === 0 || joints[key].group !== joints[key - 1].group;
    const isGroupEnd =
      key === joints.length - 1 ||
      joints[key].group !== joints[key + 1].group;

    return (
      swallonTenderJoint?.map(item => item.value)?.includes(joint?.left) && swallonTenderJoint?.map(item => item.value)?.includes(joint?.right) ? "" :
        <tr
          key={joint.name}
          className={`${isGroupStart ? "group-start" : ""} ${isGroupEnd ? "group-end" : ""
            }`}
        >
          {/* Left */}
          <td title={joint.leftTitle} className="p-0">
            <input
              disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left)}
              type="radio"
              value={"Present"}
              onChange={(e) =>
                handleSwallonTenderFormChange(
                  joint.leftTitle,
                  "Tender",
                  e.target.value,
                  swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                  swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                  swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST || valueData?.[key]?.LEFTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT || valueData?.[key]?.LEFTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST || valueData?.[key]?.RIGHTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT || valueData?.[key]?.RIGHTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES,
                  key,
                  item
                )
              }
              checked={((swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT === "Tender" || valueData?.[key]?.LEFTTENDER?.QSCAT === "Tender") && (swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST === joint.leftTitle || valueData?.[key]?.LEFTTENDER?.QSTEST === joint.leftTitle) && (swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES)) === "Present"}
              name={`${joint.left}_${key}_Tender`}
            />
          </td>
          <td title={joint.leftTitle} className="p-0">
            <input
              disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left)}
              type="radio"
              value={"Absent"}
              onChange={(e) =>
                handleSwallonTenderFormChange(
                  joint.leftTitle,
                  "Tender",
                  e.target.value,
                  swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                  swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                  swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST || valueData?.[key]?.LEFTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT || valueData?.[key]?.LEFTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST || valueData?.[key]?.RIGHTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT || valueData?.[key]?.RIGHTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES,
                  key,
                  item
                )
              }
              checked={((swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT === "Tender" || valueData?.[key]?.LEFTTENDER?.QSCAT === "Tender") && (swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST === joint.leftTitle || valueData?.[key]?.LEFTTENDER?.QSTEST === joint.leftTitle) && (swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES)) === "Absent"}
              name={`${joint.left}_${key}_Tender`}
            />
          </td>
          <td title={joint.leftTitle} className="p-0">
            <input
              disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left)}
              type="radio"
              value={"ND"}
              onChange={(e) =>
                handleSwallonTenderFormChange(
                  joint.leftTitle,
                  "Tender",
                  e.target.value,
                  swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                  swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                  swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST || valueData?.[key]?.LEFTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT || valueData?.[key]?.LEFTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST || valueData?.[key]?.RIGHTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT || valueData?.[key]?.RIGHTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES,
                  key,
                  item
                )
              }
              checked={((swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT === "Tender" || valueData?.[key]?.LEFTTENDER?.QSCAT === "Tender") && (swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST === joint.leftTitle || valueData?.[key]?.LEFTTENDER?.QSTEST === joint.leftTitle) && (swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES)) === "ND"}
              name={`${joint.left}_${key}_Tender`}
            />
          </td>
          <td title={joint.leftTitle} className="p-0">
            {joint.group !== "Hip Extremity" &&
              <input
                disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left)}
                type="radio"
                value={"Present"}
                onChange={(e) =>
                  handleSwallonTenderFormChange(
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                    joint.leftTitle,
                    "Swallon",
                    e.target.value,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST || valueData?.[key]?.RIGHTSWALLON?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT || valueData?.[key]?.RIGHTSWALLON?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES,
                    key,
                    item
                  )
                }
                checked={((swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT === "Swallon" || valueData?.[key]?.LEFTSWALLON?.QSCAT === "Swallon") && (swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST === joint.leftTitle || valueData?.[key]?.LEFTSWALLON?.QSTEST === joint.leftTitle) && (swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES)) === "Present"}
                name={`${joint.left}_${key}_Swallon`}
              />
            }
          </td>
          <td title={joint.leftTitle} className="p-0">
            {joint.group !== "Hip Extremity" &&
              <input
                disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left)}
                name={`${joint.left}_${key}_Swallon`}
                type="radio"
                value={"Absent"}
                onChange={(e) =>
                  handleSwallonTenderFormChange(
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                    joint.leftTitle,
                    "Swallon",
                    e.target.value,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST || valueData?.[key]?.RIGHTSWALLON?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT || valueData?.[key]?.RIGHTSWALLON?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES,
                    key,
                    item
                  )
                }
                checked={((swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT === "Swallon" || valueData?.[key]?.LEFTSWALLON?.QSCAT === "Swallon") && (swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST === joint.leftTitle || valueData?.[key]?.LEFTSWALLON?.QSTEST === joint.leftTitle) && (swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES)) === "Absent"}
              />
            }
          </td>
          <td title={joint.leftTitle} className="p-0">
            {joint.group !== "Hip Extremity" &&
              <input
                disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left)}
                name={`${joint.left}_${key}_Swallon`}
                type="radio"
                value={"ND"}
                onChange={(e) =>
                  handleSwallonTenderFormChange(
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                    joint.leftTitle,
                    "Swallon",
                    e.target.value,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST || valueData?.[key]?.RIGHTSWALLON?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT || valueData?.[key]?.RIGHTSWALLON?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES,
                    key,
                    item
                  )
                }
                checked={((swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT === "Swallon" || valueData?.[key]?.LEFTSWALLON?.QSCAT === "Swallon") && (swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST === joint.leftTitle || valueData?.[key]?.LEFTSWALLON?.QSTEST === joint.leftTitle) && (swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES)) === "ND"}
              />
            }
          </td>
          <td title={joint.leftTitle} className="p-0">
            {joint.group !== "Hip Extremity" &&
              <input
                disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left)}
                name={`${joint.left}_${key}_Swallon`}
                type="radio"
                value={"NA"}
                onChange={(e) =>
                  handleSwallonTenderFormChange(
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                    joint.leftTitle,
                    "Swallon",
                    e.target.value,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST || valueData?.[key]?.RIGHTSWALLON?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT || valueData?.[key]?.RIGHTSWALLON?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES,
                    key,
                    item
                  )
                }
                checked={((swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT === "Swallon" || valueData?.[key]?.LEFTSWALLON?.QSCAT === "Swallon") && (swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST === joint.leftTitle || valueData?.[key]?.LEFTSWALLON?.QSTEST === joint.leftTitle) && (swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES)) === "NA"}
              />
            }
          </td>
          {/* Joint Name */}
          <td className="p-0">
            <Form.Label className="m-0">{joint.name}</Form.Label>
          </td>
          {/* Right */}
          <td title={joint.rightTitle} className="p-0">
            <input
              disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right)}
              name={`${joint.right}_${key}_Tender`}
              type="radio"
              value={"Present"}
              onChange={(e) =>
                handleSwallonTenderFormChange(
                  swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                  swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                  swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                  joint.rightTitle,
                  "Tender",
                  e.target.value,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST || valueData?.[key]?.LEFTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT || valueData?.[key]?.LEFTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST || valueData?.[key]?.RIGHTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT || valueData?.[key]?.RIGHTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES,
                  key,
                  item
                )
              }
              checked={((swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT === "Tender" || valueData?.[key]?.RIGHTTENDER?.QSCAT === "Tender") && (swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST === joint.rightTitle || valueData?.[key]?.RIGHTTENDER?.QSTEST === joint.rightTitle) && (swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES)) === "Present"}
            />
          </td>
          <td title={joint.rightTitle} className="p-0">
            <input
              disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right)}
              name={`${joint.right}_${key}_Tender`}
              type="radio"
              value={"Absent"}
              onChange={(e) =>
                handleSwallonTenderFormChange(
                  swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                  swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                  swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                  joint.rightTitle,
                  "Tender",
                  e.target.value,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST || valueData?.[key]?.LEFTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT || valueData?.[key]?.LEFTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST || valueData?.[key]?.RIGHTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT || valueData?.[key]?.RIGHTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES,
                  key,
                  item
                )
              }
              checked={((swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT === "Tender" || valueData?.[key]?.RIGHTTENDER?.QSCAT === "Tender") && (swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST === joint.rightTitle || valueData?.[key]?.RIGHTTENDER?.QSTEST === joint.rightTitle) && (swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES)) === "Absent"}
            />
          </td>
          <td title={joint.rightTitle} className="p-0">
            <input
              disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right)}
              name={`${joint.right}_${key}_Tender`}
              type="radio"
              value={"ND"}
              onChange={(e) =>
                handleSwallonTenderFormChange(
                  swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                  swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                  swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                  joint.rightTitle,
                  "Tender",
                  e.target.value,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST || valueData?.[key]?.LEFTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT || valueData?.[key]?.LEFTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST || valueData?.[key]?.RIGHTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT || valueData?.[key]?.RIGHTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES,
                  key,
                  item
                )
              }
              checked={((swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT === "Tender" || valueData?.[key]?.RIGHTTENDER?.QSCAT === "Tender") && (swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST === joint.rightTitle || valueData?.[key]?.RIGHTTENDER?.QSTEST === joint.rightTitle) && (swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES)) === "ND"}
            />
          </td>
          <td title={joint.rightTitle} className="p-0">
            {joint.group !== "Hip Extremity" &&
              <input
                disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right)}
                name={`${joint.right}_${key}_Swallon`}
                type="radio"
                value={"Present"}
                onChange={(e) =>
                  handleSwallonTenderFormChange(
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST || valueData?.[key]?.LEFTSWALLON?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT || valueData?.[key]?.LEFTSWALLON?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES,
                    joint.rightTitle,
                    "Swallon",
                    e.target.value,
                    key,
                    item
                  )
                }
                checked={((swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT === "Swallon" || valueData?.[key]?.RIGHTSWALLON?.QSCAT === "Swallon") && (swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST === joint.rightTitle || valueData?.[key]?.RIGHTSWALLON?.QSTEST === joint.rightTitle) && (swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES)) === "Present"}
              />
            }
          </td>
          <td title={joint.rightTitle} className="p-0">
            {joint.group !== "Hip Extremity" &&
              <input
                disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right)}
                name={`${joint.right}_${key}_Swallon`}
                type="radio"
                value={"Absent"}
                onChange={(e) =>
                  handleSwallonTenderFormChange(
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST || valueData?.[key]?.LEFTSWALLON?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT || valueData?.[key]?.LEFTSWALLON?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES,
                    joint.rightTitle,
                    "Swallon",
                    e.target.value,
                    key,
                    item
                  )
                }
                checked={((swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT === "Swallon" || valueData?.[key]?.RIGHTSWALLON?.QSCAT === "Swallon") && (swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST === joint.rightTitle || valueData?.[key]?.RIGHTSWALLON?.QSTEST === joint.rightTitle) && (swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES)) === "Absent"}
              />
            }
          </td>
          <td title={joint.rightTitle} className="p-0">
            {joint.group !== "Hip Extremity" &&
              <input
                disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right)}
                name={`${joint.right}_${key}_Swallon`}
                type="radio"
                value={"ND"}
                onChange={(e) =>
                  handleSwallonTenderFormChange(
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST || valueData?.[key]?.LEFTSWALLON?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT || valueData?.[key]?.LEFTSWALLON?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES,
                    joint.rightTitle,
                    "Swallon",
                    e.target.value,
                    key,
                    item
                  )
                }
                checked={((swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT === "Swallon" || valueData?.[key]?.RIGHTSWALLON?.QSCAT === "Swallon") && (swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST === joint.rightTitle || valueData?.[key]?.RIGHTSWALLON?.QSTEST === joint.rightTitle) && (swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES)) === "ND"}
              />
            }
          </td>
          <td title={joint.rightTitle} className="p-0">
            {joint.group !== "Hip Extremity" &&
              <input
                disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right)}
                name={`${joint.right}_${key}_Swallon`}
                type="radio"
                value={"NA"}
                onChange={(e) =>
                  handleSwallonTenderFormChange(
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST || valueData?.[key]?.LEFTSWALLON?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT || valueData?.[key]?.LEFTSWALLON?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES,
                    joint.rightTitle,
                    "Swallon",
                    e.target.value,
                    key,
                    item
                  )
                }
                checked={((swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT === "Swallon" || valueData?.[key]?.RIGHTSWALLON?.QSCAT === "Swallon") && (swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST === joint.rightTitle || valueData?.[key]?.RIGHTSWALLON?.QSTEST === joint.rightTitle) && (swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES)) === "NA"}
              />
            }
          </td>
        </tr>
    );
  };
  return (
    <>
      <Table
        bordered
        className="text-center border-start border-end mt-1 w-100"
      >
        <thead style={{backgroundColor: "#b8cfdf"}}>
          <tr>
            <th className="p-0" colSpan="7">
              <Form.Label className="m-0">Left</Form.Label>
            </th>
            <th className="p-0" colSpan="1">
              <Form.Label className="m-0">Joint</Form.Label>
            </th>
            <th className="p-0" colSpan="7">
              <Form.Label className="m-0">Right</Form.Label>
            </th>
          </tr>
          <tr>
            <th className="p-0" colSpan="3">
              <Form.Label className="m-0">Tender</Form.Label>
            </th>
            <th className="p-0" colSpan="4">
              <Form.Label className="m-0">Swelling</Form.Label>
            </th>
            <th className="p-0"></th>
            <th className="p-0" colSpan="3">
              <Form.Label className="m-0">Tender</Form.Label>
            </th>
            <th className="p-0" colSpan="4">
              <Form.Label className="m-0">Swelling</Form.Label>
            </th>
          </tr>
          <tr>
            <th className="p-0">
              <Form.Label className="m-0">Present</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">Absent</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">ND</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">Present</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">Absent</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">ND</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">NA</Form.Label>
            </th>
            <th className="p-0"></th>
            <th className="p-0">
              <Form.Label className="m-0">Present</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">Absent</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">ND</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">Present</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">Absent</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">ND</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">NA</Form.Label>
            </th>
          </tr>
        </thead>
        <tbody>{joints.map((joint, key) => renderRow(joint, key))}</tbody>
      </Table>
      <div className="hstack gap-3 mt-2">
        <Form.Label>ND: Not Done</Form.Label>
        <Form.Label>NA: Not Applicable</Form.Label>
        <Form.Label>MCP: Metacarpophalangeal joints</Form.Label>
        <Form.Label>PIP: Proximal Interphalangeal Joints</Form.Label>
        <Form.Label>DIP: Distal Interphalangeal Joints</Form.Label>
        <Form.Label>MTP: Metatarsal Phalangeal Joints</Form.Label>
      </div>
    </>
  );
};

export default SwallonTenderJoints;
