import { headers } from "../../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../Helpers/Responses";
import config from "../../config/config.json";

export const cdrgStudyServices = {
  //Acronyms metadata
  getStudyAcrouymsMetadata,
  getActiveAcrouymsMetadata,
  addNewStudyAcrouymsMetadata,
  updateStudyAcrouymsMetadatas,
  deleteStudyAcrouymsMetadatas,

  //issue summary
  getActiveIssueSummery,
  getStudyIssueSummeryData,
  addNewStudyIssueSummery,
  updateStudyIssueSummeryData,
  deleteStudyIssueSummerys,

  // trail design
  getActiveTrailDesignDataset,
  getStudyTrailDesignDatasetData,
  addNewStudyTrailDesignDataset,
  updateStudyTrailDesignDatasetData,
  deleteStudyTrailDesignDatasets,

  // Appendix Inclusion Exclusion
  getStudyAppendixIncExcData,
  addNewStudyAppendixIncExc,
  updateStudyAppendixIncExcData,
  deleteStudyAppendixIncExcs,

  // Data Conformance
  getStudyDataConformanceInputs,
  addUpdateStudyDataConformanceInputs,

  // Subject Data Description
  getStudySubjectDataDescription,
  addUpdateStudySubjectDataDescription,
};

// Get All Acrouyms Metadata List for Table
async function getStudyAcrouymsMetadata(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyAcrouymsMetadata/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Global Active Acrouyms Metadata
async function getActiveAcrouymsMetadata() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveAcrouymsMetadata`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Add New Acrouyms Metadata
async function addNewStudyAcrouymsMetadata(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewStudyAcrouymsMetadata`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Acrouyms Metadata
async function updateStudyAcrouymsMetadatas(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyAcrouymsMetadatas`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

/// Delete Acrouyms Metadata
async function deleteStudyAcrouymsMetadatas(id, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyAcrouymsMetadatas/${id}/${studyId}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get All Issue Summery List for Table
async function getStudyIssueSummeryData(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyIssueSummeryData/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get  Active Issue Summery
async function getActiveIssueSummery() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveIssueSummery`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Add New Issue Summery
async function addNewStudyIssueSummery(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewStudyIssueSummery`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Issue Summery
async function updateStudyIssueSummeryData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyIssueSummeryData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Delete Issue Summery
async function deleteStudyIssueSummerys(id, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyIssueSummerys/${id}/${studyId}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get All Trail Design Dataset List for Table
async function getStudyTrailDesignDatasetData(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyTrailDesignDatasetData/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Global Trail Design Dataset
async function getActiveTrailDesignDataset() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveTrailDesignDataset`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Add New Trail Design Dataset
async function addNewStudyTrailDesignDataset(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewStudyTrailDesignDataset`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Trail Design Dataset
async function updateStudyTrailDesignDatasetData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyTrailDesignDatasetData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Delete Trail Design Dataset
async function deleteStudyTrailDesignDatasets(id, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyTrailDesignDatasets/${id}/${studyId}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get Data Conformance
async function getStudyDataConformanceInputs(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyDataConformanceInputs/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Add Update Data Conformance
async function addUpdateStudyDataConformanceInputs(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addUpdateStudyDataConformanceInputs`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get Subject Data Description
async function getStudySubjectDataDescription(type, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySubjectDataDescription/${type}/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Add Update Subject Data Description
async function addUpdateStudySubjectDataDescription(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addUpdateStudySubjectDataDescription`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get All Study Appendix Inclusion Exclusion List for Table
async function getStudyAppendixIncExcData(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyAppendixIncExcData/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Add New Study Appendix Inclusion Exclusion
async function addNewStudyAppendixIncExc(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewStudyAppendixIncExc`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Study Appendix Inclusion Exclusion
async function updateStudyAppendixIncExcData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyAppendixIncExcData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Delete Study Appendix Inclusion Exclusion
async function deleteStudyAppendixIncExcs(id, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyAppendixIncExcs/${id}/${studyId}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}