import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { useParams } from "react-router";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../NoDataFoundTable/NoDataFoundTable";

const Activity = ({ onMouseDown, tableRef, activity }) => {
  const [loader, setLoader] = useState(false);
  const ref = useParams();
  return (
    <>
      {loader ? (
        <SpinerDataLoader />
      ) : activity?.length === 0 ? (
        <NoDataFoundTable MSG="Activity not found" />
      ) : (
        <Table
          id="resizable-table"
          striped
          hover
          className="m-0 custom-table"
          ref={tableRef}
        >
          <thead>
            <tr>
              <th
                onMouseDown={(e) => onMouseDown(e, 0)}
                style={{ width: 50 }}
                className="text-center"
              >
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Status</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Type</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Activities</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Categories</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)}>Activity By</th>
              <th onMouseDown={(e) => onMouseDown(e, 6)}>Activity Date</th>
              <th onMouseDown={(e) => onMouseDown(e, 7)}>Activity Time</th>
            </tr>
          </thead>
          <tbody ref={ref}>
            <tr>
              <td className="text-center">1</td>
              <td>dsfgh</td>
              <td>dsfgh</td>
              <td>dsfgh</td>
              <td>dsfgh</td>
              <td>dsfgh</td>
              <td>dsfgh</td>
              <td>dsfgh</td>
            </tr>
          </tbody>
        </Table>
      )}
    </>
  );
};

export default Activity;
