import React from "react";
import { Form, Table } from "react-bootstrap";

const PASIFormValues = ({ item, valueData, handlePasiFormChange, pasiFormDataEntry, disabledData }) => {
  const sections = ["Head", "Arms", "Trunk", "Legs"];
  const radios = (section, category, labels, key) =>
    labels.map((label, idx) => (
      <Form.Check
        key={idx}
        type="radio"
        label={label}
        onChange={(e) =>
          handlePasiFormChange(
            "QSCAT",
            section,
            "QSTEST",
            category,
            "QSORRES",
            label,
            key,
            item,
          )
        }
        disabled={disabledData}
        checked={(pasiFormDataEntry?.[key]?.QSORRES || valueData?.[key]?.QSORRES) === label}
        name={`${section.toLowerCase()}${category}Group`}
      />
    ));

  const renderSection = (section, i) => (
    <Table bordered className="border-start border-end" key={section}>
      <thead style={{ background: "#b8cfdf" }}>
        <tr>
          <th className="p-1"></th>
          <th className="p-1">
            <Form.Label className="m-0">{section}</Form.Label>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="p-1">
            <Form.Label className="mb-0 fw-bold">
              Percentage Affected Area
            </Form.Label>
          </td>
          <td>
            <div className="hstack gap-2 radioMap">
              {radios(section, "percentage", [
                "0%",
                "<10%",
                "10-29%",
                "30-49%",
                "50-69%",
                "70-89%",
                "90-100%",
              ],
                section === "Head" ? i + 0 : section === "Arms" ? i + 3 + 0 : section === "Trunk" ? i + 6 + 0 : i + 9 + 0
              )}
            </div>
          </td>
        </tr>
        {["Erythema", "Induration", "Desquamation"].map((category, idx) => (
          <tr key={idx}>
            <td className="p-1">
              <Form.Label className="mb-0">
                <b>{category}</b> {category === "Erythema" && "(redness)"}
                {category === "Induration" && "(thickness)"}
                {category === "Desquamation" && "(scaling)"}
              </Form.Label>
            </td>
            <td>
              <div className="hstack gap-2 radioMap">
                {radios(section, category.toLowerCase(), [
                  "0",
                  "1",
                  "2",
                  "3",
                  "4",
                ],
                  section === "Head" ? i + idx + 1 : section === "Arms" ? i + 3 + idx + 1 : section === "Trunk" ? i + 6 + idx + 1 : i + 9 + idx + 1
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  return <div className="mt-3">{sections.map(renderSection)}</div>;
};

export default PASIFormValues;
