import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { cdrgStudyServices } from "../../../../../Services/CDISC/cdrgStudyServices";

const AddIssueSummary = ({ error, setError, cleanInputBox, explaination, setExplaination, getStudyIssueSummeryData, setIssueSummaryid, issueSummaryid, studyId }) => {

  const [loader, setLoader] = useState(false);
  const [type, setType] = useState("");
  const [errorId, setErrorId] = useState("");
  const [issueGlobalData, setIssueGlobalData] = useState([]);

  const getActiveIssueSummery = async () => {
    let data = await cdrgStudyServices.getActiveIssueSummery();
    setIssueGlobalData(data?.data);
  };

  const addNewStudyIssueSummery = async () => {
    setLoader(true);
    let userData = {};
    userData.studyId = studyId
    userData.errorId = errorId;
    userData.error = error;
    userData.explaination = explaination;
    let data = await cdrgStudyServices.addNewStudyIssueSummery(userData);
    if (data?.statusCode === 200) {
      getStudyIssueSummeryData();
      cleanInputBox("");
      setLoader(false);
    }
    setLoader(false);
  };

  const handleUpdate = async () => {
    setLoader(true);
    let userData = {};
    userData.studyId = studyId
    userData.id = issueSummaryid?._id
    userData.error = error;
    userData.explaination = explaination;
    let data = await cdrgStudyServices.updateStudyIssueSummeryData(userData);
    if (data?.statusCode === 200) {
      getStudyIssueSummeryData();
      setIssueSummaryid("");
      cleanInputBox("");
      setLoader(false);
    }
    setLoader(false);
  };

  useEffect(() => {
    if (issueSummaryid) {
      setType("custom");
      setError(issueSummaryid.error)
      setExplaination(issueSummaryid.explaination)
    }
  }, [issueSummaryid]);

  useEffect(() => {
    getActiveIssueSummery();
  }, []);

  return (
    <div className="p-2">
      <Form.Group className="mb-2">
        <div className="hstack justify-content-between">
          <Form.Label className="fw-bold mb-1">
            Type <span className="text-danger">*</span>
          </Form.Label>
          {issueSummaryid?._id && (
            <button
              title="Add New Metadata"
              className="text-white border-success bg-success border-0"
              style={{ fontSize: 10, borderRadius: 3 }}
              onClick={() => cleanInputBox()}
            >
              <i className="fa-solid fa-square-plus"></i> Add New
            </button>
          )}
        </div>
        <Form.Select
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <option>Select type</option>
          <option selected={type === "custom"} value="custom">Custom</option>
          <option selected={type === "standard"} value="standard">Standard</option>
        </Form.Select>
      </Form.Group>
      {type === "custom" && (
        <>
          <Form.Group className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Error <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Error"
              value={error}
              onChange={(e) => setError(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Explanation <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={8}
              placeholder="Enter Explanation"
              value={explaination}
              onChange={(e) => setExplaination(e.target.value)}
            />
          </Form.Group>
        </>
      )}

      {type === "standard" && (
        <>
          <Form.Group className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Select Issue Summary <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              value={errorId}
              onChange={(e) => setErrorId(e.target.value)}
            >
              <option value="">Select Issue</option>
              {issueGlobalData?.map((item, index) => (
                <option
                  key={index}
                  value={item._id}
                >
                  {item.error}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </>
      )}

      <div className="text-end footer_button">
        <div className="text-end footer_button">
          <button className="submitbutton px-4"
            // disabled={!error || !explaination}
            onClick={issueSummaryid ? handleUpdate : addNewStudyIssueSummery}>
            {loader ? (
              <Spinner
                animation="border"
                style={{ width: 15, height: 15 }}
                className="me-2"
              />
            ) : null}
            {issueSummaryid ? "Update" : "Add"} Issue Summary
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddIssueSummary;
