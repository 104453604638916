import React from "react";
import { Form, Table } from "react-bootstrap";

const PhysicalExaminationFormValues = ({
  physicalExamination = [],
  item,
  handlePhysicalExaminationChange,
  physicalExaminationDataEntry,
  valueData,
  disabledData,
  tableRef,
  onMouseDown,
}) => {
  return (
    <div className="overflow-auto">
      <Table
        bordered
        ref={tableRef}
        className="custom-table tableLibrary border-start border-end mt-1"
      >
        <thead>
          <tr style={{ verticalAlign: "bottom" }}>
            <th
              onMouseDown={(e) => onMouseDown(e, 0)}
              className="position-sticky start-0 shadow"
              rowSpan={2}
            >
              <Form.Label className="m-0">
                Physical Examination Test or Examination Name
              </Form.Label>
            </th>
            {physicalExamination?.some(
              (item) => item.physicalExaminationCategory === "yes"
            ) && (
              <th rowSpan={2} onMouseDown={(e) => onMouseDown(e, 1)}>
                <Form.Label className="m-0">
                  Category for Physical Examination Test
                </Form.Label>
              </th>
            )}
            {physicalExamination?.some(
              (item) => item.physicalExaminationCategory === "yes"
            ) && (
              <th onMouseDown={(e) => onMouseDown(e, 2)} rowSpan={2}>
                <Form.Label className="m-0">
                  Subcategory for Physical Examination Test
                </Form.Label>
              </th>
            )}
            <th
              className="text-center"
              onMouseDown={(e) => onMouseDown(e, 3)}
              colSpan={2}
            >
              <Form.Label className="m-0">Reference Range Indicator</Form.Label>
            </th>
            <th
              className="text-center"
              onMouseDown={(e) => onMouseDown(e, 4)}
              colSpan={2}
            >
              <Form.Label className="m-0">Clinical Significance</Form.Label>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 5)} rowSpan={2}>
              <Form.Label className="m-0">
                Remark &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Form.Label>
            </th>
            <th rowSpan={2} onMouseDown={(e) => onMouseDown(e, 6)}></th>
          </tr>
          <tr>
            <th onMouseDown={(e) => onMouseDown(e, 7)} className="text-center">
              <Form.Label className="m-0">Normal</Form.Label>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 8)} className="text-center">
              <Form.Label className="m-0">Abnormal</Form.Label>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 9)} className="text-center">
              <Form.Label className="m-0">CS</Form.Label>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 10)} className="text-center">
              <Form.Label className="m-0">NCS</Form.Label>
            </th>
          </tr>
        </thead>
        <tbody>
          {physicalExamination?.map((row, key) => (
            <tr key={key}>
              <td className="position-sticky start-0 bg-white">
                <Form.Label className="m-0">
                  {row.physicalExaminationName}
                </Form.Label>
              </td>
              {physicalExamination?.some(
                (item) => item.physicalExaminationCategory === "yes"
              ) && (
                <td>
                  <Form.Label className="m-0">
                    {row.physicalExaminationCategoryName}
                  </Form.Label>
                </td>
              )}
              {physicalExamination?.some(
                (item) => item.physicalExaminationSubCategory === "yes"
              ) && (
                <td>
                  <Form.Label className="m-0">
                    {row.physicalExaminationSubCategoryName}
                  </Form.Label>
                </td>
              )}
              <td className="text-center">
                <Form.Check
                  disabled={disabledData}
                  name={`physicalexaminationnormalype_${key}`}
                  type="radio"
                  value="Normal"
                  onChange={(e) =>
                    handlePhysicalExaminationChange(
                      "PEORRES",
                      e.target.value,
                      "PECLSIG",
                      physicalExaminationDataEntry?.[key]?.PECLSIG || valueData?.[key]?.PECLSIG,
                      "PECOM",
                      physicalExaminationDataEntry?.[key]?.PECOM || valueData?.[key]?.PECOM,
                      key,
                      item
                    )
                  }
                  checked={
                    (physicalExaminationDataEntry?.[key]?.PEORRES ||
                      valueData?.[key]?.PEORRES) === "Normal"
                  }
                />
              </td>
              <td className="text-center">
                <Form.Check
                  disabled={disabledData}
                  name={`physicalexaminationnormalype_${key}`}
                  type="radio"
                  value="Abnormal"
                  onChange={(e) =>
                    handlePhysicalExaminationChange(
                      "PEORRES",
                      e.target.value,
                      "PECLSIG",
                      physicalExaminationDataEntry?.[key]?.PECLSIG || valueData?.[key]?.PECLSIG,
                      "PECOM",
                      physicalExaminationDataEntry?.[key]?.PECOM || valueData?.[key]?.PECOM,
                      key,
                      item
                    )
                  }
                  checked={
                    (physicalExaminationDataEntry?.[key]?.PEORRES ||
                      valueData?.[key]?.PEORRES) === "Abnormal"
                  }
                />
              </td>
              <td className="text-center">
                <Form.Check
                  disabled={disabledData || (physicalExaminationDataEntry?.[key + physicalExamination?.length]?.PEORRES ||
                    valueData?.[key + physicalExamination?.length]?.PEORRES) === "Normal"}
                  name={`physicalexaminationcstype_${key}`}
                  type="radio"
                  value="CS"
                  onChange={(e) =>
                    handlePhysicalExaminationChange(
                      "PEORRES",
                      physicalExaminationDataEntry?.[key]?.PEORRES || valueData?.[key]?.PEORRES,
                      "PECLSIG",
                      e.target.value,
                      "PECOM",
                      physicalExaminationDataEntry?.[key]?.PECOM || valueData?.[key]?.PECOM,
                      key,
                      item
                    )
                  }
                  checked={
                    (physicalExaminationDataEntry?.[key]?.PECLSIG ||
                      valueData?.[key]?.PECLSIG) === "CS"
                  }
                />
              </td>
              <td className="text-center">
                <Form.Check
                  disabled={disabledData || (physicalExaminationDataEntry?.[key + physicalExamination?.length]?.PEORRES ||
                    valueData?.[key + physicalExamination?.length]?.PEORRES) === "Normal"}
                  name={`physicalexaminationcstype_${key}`}
                  type="radio"
                  value="NCS"
                  onChange={(e) =>
                    handlePhysicalExaminationChange(
                      "PEORRES",
                      physicalExaminationDataEntry?.[key]?.PEORRES || valueData?.[key]?.PEORRES,
                      "PECLSIG",
                      e.target.value,
                      "PECOM",
                      physicalExaminationDataEntry?.[key]?.PECOM || valueData?.[key]?.PECOM,
                      key,
                      item
                    )
                  }
                  checked={
                    (physicalExaminationDataEntry?.[key]?.PECLSIG ||
                      valueData?.[key]?.PECLSIG) === "NCS"
                  }
                />
              </td>
              <td
                className="p-0"
                style={{ background: disabledData && "#e9ecef" }}
              >
                <Form.Control
                  disabled={disabledData}
                  className="border-0 w-100 p-1"
                  placeholder="Remark"
                  style={{ resize: "none" }}
                  onChange={(e) =>
                    handlePhysicalExaminationChange(
                      "PEORRES",
                      physicalExaminationDataEntry?.[key]?.PEORRES || valueData?.[key]?.PEORRES,
                      "PECLSIG",
                      physicalExaminationDataEntry?.[key]?.PECLSIG || valueData?.[key]?.PECLSIG,
                      "PECOM",
                      e.target.value,
                      key,
                      item
                    )
                  }
                  value={
                    physicalExaminationDataEntry?.[key]?.PECOM ||
                    valueData?.[key]?.PECOM
                  }
                  as={"textarea"}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default PhysicalExaminationFormValues;
