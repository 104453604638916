import config from "../config/config.json";
import { headers } from "../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../Helpers/Responses";

export const studyAccessManagement = {
  studySiteListData,
  getStudySubjectBySiteId,
  getStudyAccessManagementGroups,
  getStudyAccessManagementPermissionBasedonId,
  assignGroupsofStudyManagement,
  getStudyUserPermission,
  assignStudyManagementGroupToUser,
  updateStudyManagementGroupName,
  deleteStudyManagementGroupName,
  createStudyAccessManagement,
  getFormReviewVersionAndProtocol,

  getETMFAccessManagementGroups,
  updateETMFManagementGroupName,
  createETMFAccessManagement,
  deleteETMFManagementGroupName,
  assignGroupsofETMFManagement,
  getETMFAccessManagementPermissionBasedonId,

  getCTMSAccessManagementGroups,
  updateCTMSManagementGroupName,
  createCTMSAccessManagement,
  deleteCTMSManagementGroupName,
  assignCTMSManagementGroupToUser,
  assignGroupsofCTMSManagement,
  getCTMSAccessManagementPermissionBasedonId,

  getCDISCAccessManagementGroups,
  updateCDISCManagementGroupName,
  createCDISCAccessManagement,
  deleteCDISCManagementGroupName,
  assignCDISCManagementGroupToUser,
  assignGroupsofCDISCManagement,
  getCDISCAccessManagementPermissionBasedonId,

  getIWRSAccessManagementGroups,
  updateIWRSManagementGroupName,
  createIWRSAccessManagement,
  deleteIWRSManagementGroupName,
  assignIWRSManagementGroupToUser,
  assignGroupsofIWRSManagement,
  getIWRSAccessManagementPermissionBasedonId,

  getRBQMAccessManagementGroups,
  updateRBQMManagementGroupName,
  createRBQMAccessManagement,
  deleteRBQMManagementGroupName,
  assignRBQMManagementGroupToUser,
  assignGroupsofRBQMManagement,
  getRBQMAccessManagementPermissionBasedonId,

  getSDMAccessManagementGroups,
  updateSDMManagementGroupName,
  createSDMAccessManagement,
  deleteSDMManagementGroupName,
  assignSDMManagementGroupToUser,
  assignGroupsofSDMManagement,
  getSDMAccessManagementPermissionBasedonId,
};

async function getStudySubjectBySiteId(studyId, studyMode, siteUniqueId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySubjectBySiteId/${studyId}/${studyMode}/${siteUniqueId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function studySiteListData(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}studySiteListData/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getFormReviewVersionAndProtocol(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getFormReviewVersionAndProtocol/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudyAccessManagementGroups(studyId, projectId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyAccessManagementGroups/${studyId}/${projectId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getETMFAccessManagementGroups(studyId, projectId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getETMFAccessManagementGroups/${studyId}/${projectId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudyAccessManagementPermissionBasedonId(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyAccessManagementPermissionBasedonId/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getETMFAccessManagementPermissionBasedonId(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getETMFAccessManagementPermissionBasedonId/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudyUserPermission(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyUserPermission/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function assignStudyManagementGroupToUser(accessData, history) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignStudyManagementGroupToUser`,
    headersWithAuth("PUT", accessData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function assignGroupsofStudyManagement(accessData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignGroupsofStudyManagement`,
    headersWithAuth("PUT", accessData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function assignGroupsofETMFManagement(accessData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignGroupsofETMFManagement`,
    headersWithAuth("PUT", accessData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function updateStudyManagementGroupName(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyManagementGroupName`,
    headersWithAuth("PUT", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function deleteStudyManagementGroupName(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyManagementGroupName`,
    headersWithAuth("DELETE", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function createStudyAccessManagement(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}createStudyAccessManagement`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function updateETMFManagementGroupName(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateETMFManagementGroupName`,
    headersWithAuth("PUT", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function deleteETMFManagementGroupName(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteETMFManagementGroupName`,
    headersWithAuth("DELETE", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function createETMFAccessManagement(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}createETMFAccessManagement`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function createCTMSAccessManagement(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}createCTMSAccessManagement`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function updateCTMSManagementGroupName(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateCTMSManagementGroupName`,
    headersWithAuth("PUT", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function deleteCTMSManagementGroupName(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteCTMSManagementGroupName`,
    headersWithAuth("DELETE", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function assignCTMSManagementGroupToUser(accessData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignCTMSManagementGroupToUser`,
    headersWithAuth("PUT", accessData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function getCTMSAccessManagementGroups(studyId, projectId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getCTMSAccessManagementGroups/${studyId}/${projectId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getCTMSAccessManagementPermissionBasedonId(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getCTMSAccessManagementPermissionBasedonId/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function assignGroupsofCTMSManagement(accessData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignGroupsofCTMSManagement`,
    headersWithAuth("PUT", accessData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function createCDISCAccessManagement(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}createCDISCAccessManagement`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function updateCDISCManagementGroupName(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateCDISCManagementGroupName`,
    headersWithAuth("PUT", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function deleteCDISCManagementGroupName(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteCDISCManagementGroupName`,
    headersWithAuth("DELETE", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function assignCDISCManagementGroupToUser(accessData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignCDISCManagementGroupToUser`,
    headersWithAuth("PUT", accessData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function getCDISCAccessManagementGroups(studyId, projectId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getCDISCAccessManagementGroups/${studyId}/${projectId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getCDISCAccessManagementPermissionBasedonId(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getCDISCAccessManagementPermissionBasedonId/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function assignGroupsofCDISCManagement(accessData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignGroupsofCDISCManagement`,
    headersWithAuth("PUT", accessData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function createIWRSAccessManagement(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}createIWRSAccessManagement`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function updateIWRSManagementGroupName(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateIWRSManagementGroupName`,
    headersWithAuth("PUT", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function deleteIWRSManagementGroupName(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteIWRSManagementGroupName`,
    headersWithAuth("DELETE", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function assignIWRSManagementGroupToUser(accessData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignIWRSManagementGroupToUser`,
    headersWithAuth("PUT", accessData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function getIWRSAccessManagementGroups(studyId, projectId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getIWRSAccessManagementGroups/${studyId}/${projectId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getIWRSAccessManagementPermissionBasedonId(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getIWRSAccessManagementPermissionBasedonId/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function assignGroupsofIWRSManagement(accessData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignGroupsofIWRSManagement`,
    headersWithAuth("PUT", accessData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function createRBQMAccessManagement(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}createRBQMAccessManagement`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function updateRBQMManagementGroupName(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateRBQMManagementGroupName`,
    headersWithAuth("PUT", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function deleteRBQMManagementGroupName(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteRBQMManagementGroupName`,
    headersWithAuth("DELETE", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function assignRBQMManagementGroupToUser(accessData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignRBQMManagementGroupToUser`,
    headersWithAuth("PUT", accessData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function getRBQMAccessManagementGroups(studyId, projectId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getRBQMAccessManagementGroups/${studyId}/${projectId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getRBQMAccessManagementPermissionBasedonId(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getRBQMAccessManagementPermissionBasedonId/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function assignGroupsofRBQMManagement(accessData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignGroupsofRBQMManagement`,
    headersWithAuth("PUT", accessData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function createSDMAccessManagement(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}createSDMAccessManagement`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function updateSDMManagementGroupName(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateSDMManagementGroupName`,
    headersWithAuth("PUT", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function deleteSDMManagementGroupName(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteSDMManagementGroupName`,
    headersWithAuth("DELETE", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function assignSDMManagementGroupToUser(accessData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignSDMManagementGroupToUser`,
    headersWithAuth("PUT", accessData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function getSDMAccessManagementGroups(studyId, projectId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSDMAccessManagementGroups/${studyId}/${projectId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getSDMAccessManagementPermissionBasedonId(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSDMAccessManagementPermissionBasedonId/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function assignGroupsofSDMManagement(accessData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignGroupsofSDMManagement`,
    headersWithAuth("PUT", accessData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}