import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { cdrgStudyServices } from "../../../../../Services/CDISC/cdrgStudyServices";

const AddAcronyms = ({ acronyms, setAcronyms, setTranslation, translation, cleanInputBox, studyId,
    getStudyAcrouymsMetadata, acronymsDataId, setAcronymsDataId }) => {
    const [type, setType] = useState("");
    const [loader, setLoader] = useState(false);
    const [acronymsId, setAcronymsId] = useState("");
    const [acronymsGlobalData, setAcronymsGlobalData] = useState([]);

    const getActiveAcrouymsMetadata = async () => {
        let data = await cdrgStudyServices.getActiveAcrouymsMetadata();
        setAcronymsGlobalData(data?.data);
    };

    const addNewStudyAcrouymsMetadata = async () => {
        setLoader(true);
        let userData = {};
        userData.studyId = studyId
        userData.acronymsId = acronymsId;
        userData.acronyms = acronyms;
        userData.translation = translation;
        let data = await cdrgStudyServices.addNewStudyAcrouymsMetadata(userData);
        if (data?.statusCode === 200) {
            getStudyAcrouymsMetadata();
            cleanInputBox("");
            setLoader(false);
        }
        setLoader(false);
    };

    const handleUpdate = async () => {
        setLoader(true);
        let userData = {};
        userData.studyId = studyId
        userData.id = acronymsDataId?._id
        userData.acronyms = acronyms;
        userData.translation = translation;
        let data = await cdrgStudyServices.updateStudyAcrouymsMetadatas(userData);
        if (data?.statusCode === 200) {
            getStudyAcrouymsMetadata();
            setAcronymsDataId("");
            cleanInputBox("");
            setLoader(false);
        }
        setLoader(false);
    };

    useEffect(() => {
        if (acronymsDataId) {
            setType("custom");
            setAcronyms(acronymsDataId.acronyms)
            setTranslation(acronymsDataId.translation)
        }
    }, [acronymsDataId]);

    useEffect(() => {
        getActiveAcrouymsMetadata();
    }, []);

    return (
        <div className="p-2">
            <Form.Group className="mb-2">
                <div className="hstack justify-content-between">
                    <Form.Label className="fw-bold mb-1">
                        Type <span className="text-danger">*</span>
                    </Form.Label>
                    {acronymsDataId?._id && (
                        <button
                            title="Add New Metadata"
                            className="text-white border-success bg-success border-0"
                            style={{ fontSize: 10, borderRadius: 3 }}
                            onClick={() => cleanInputBox()}
                        >
                            <i className="fa-solid fa-square-plus"></i> Add New
                        </button>
                    )}
                </div>
                <Form.Select
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                >
                    <option>Select type</option>
                    <option selected={type === "custom"} value="custom">Custom</option>
                    <option selected={type === "standard"} value="standard">Standard</option>
                </Form.Select>
            </Form.Group>
            {type === "custom" && (
                <>
                    <Form.Group className="mb-2">
                        <Form.Label className="fw-bold mb-1">
                            Acronyms <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Acronyms"
                            value={acronyms}
                            onChange={(e) => setAcronyms(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label className="fw-bold mb-1">
                            Translation <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={8}
                            placeholder="Enter Translation"
                            value={translation}
                            onChange={(e) => setTranslation(e.target.value)}
                        />
                    </Form.Group>
                </>
            )}
            {type === "standard" && (
                <>
                    <Form.Group className="mb-2">
                        <Form.Label className="fw-bold mb-1">
                            Select Acronyms <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                            value={acronymsId}
                            onChange={(e) => setAcronymsId(e.target.value)}
                        >
                            <option value="">Select Acronyms</option>
                            {acronymsGlobalData?.map((item, index) => (
                                <option
                                    key={index}
                                    value={item._id}
                                >
                                    {item.acronyms}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </>
            )}
            <div className="pt-2 px-2">
                <div className="text-end footer_button">
                    <div className="text-end footer_button">
                        <button className="submitbutton px-4"
                            // disabled={!acronyms || !translation}
                            onClick={acronymsDataId ? handleUpdate : addNewStudyAcrouymsMetadata}>
                            {loader ? (
                                <Spinner
                                    animation="border"
                                    style={{ width: 15, height: 15 }}
                                    className="me-2"
                                />
                            ) : null}
                            {acronymsDataId ? "Update" : "Add"} Acronyms
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAcronyms;
