import React, { useState } from "react";
import SplitPane from "react-split-pane";
import { useParams } from "react-router";
import EventHeaderMenu from "./EventHeaderMenu";
import UseToggle from "../../../../../Hooks/UseToggle";
import EventSignature from "./ActionFiles/EventSignature";
// import StudyEventTableData from "./StudyEventTableData";
import StudyEventFilter from "./ActionFiles/StudyEventFilter";
// import UpdateJsonData from "../StudyIdModal/updateJsonData";
import EventTabProperties from "./Properties/EventTabProperties";
import GenerateStudyQuery from "./ActionFiles/GenerateStudyQuery";
import AddUpdateEventRows from "./ActionFiles/AddUpdateEventRows";
import UseStudySchedule from "../../../../../Hooks/UseStudySchedule";
import AddUpdateEventColumn from "./ActionFiles/AddUpdateEventColumn";
import StudyEventTableProperty from "./ActionFiles/StudyEventTableProperty";
import { NoDataFoundTable } from "../../../../NoDataFoundTable/NoDataFoundTable";
import { studyOnboardingService } from "../../../../../Services/studyServices/studySubjectsServices";
import StudyEventTableData from "./StudyEventTableData";
import Pagination from "../../../../ETMF/Pagination/Pagination";
const StudyIDStudySchedule = ({ tableRef, onMouseDown, viewTypeData }) => {
  const { studyId } = useParams();
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const [visitUniqueId, setVisitUniqueId] = useState("");
  const { toggle1, setToggle1 } = UseToggle();
  const {
    customMessage,
    statusCode,
    formValues,
    handleChange,
    addFormFields,
    removeFormFields,
    removeAddUpdateFields,
    handleSubmit,
    studyDynamicColumn,
    addUpdateOnChange,
    handleSubmit0,
    onChangeInput,
    handleSubmit1,
    UpdateRowOnChange,
    UpdateHistoryOnChange,
    updateStudyDynamicRow,
    setUpdateStudyDynamicRow,
    handleSubmit2,
    studyUniqueId,
    setStudyUniqueId,
    setVisitId,
    visitId,
    setRepeat,
    repeat,
    setTimePointId,
    timePointId,
    setInitiate,
    initiate,
    getStudyVisitRowData,
    setStudyDynamicRow,
    studyDynamicRow,
    setIdUpdateRowTable,
    handleSubmit3,
    DuplicateStudyVisitRow,
    toggle7,
    setToggle7,
    toggle9,
    setToggle9,
    toggle10,
    setToggle10,
    studyDynamicColumnID,
    getStudyVisitColumnData,
    setSelectedNames,
    selectedNames,
    allArray,
    loadSpan,
    handleOpentablePropertyModal,
    timePointData,
    setTimePointData,
    addTimePoints,
    removeTimePoints,
    timePoints,
    setTimePoints,
  } = UseStudySchedule(studyId);
  
  const handleSubmitTableProperty = async () => {
    let datas = {};
    datas.id = studyId;
    datas.category = "studyVisit";
    datas.columnId = studyDynamicColumnID;
    datas.columnData = selectedNames;
    let data = await studyOnboardingService.addTableProperty(datas);
    if (data.statusCode === 200) {
      setToggle7();
      getStudyVisitColumnData();
      getStudyVisitRowData();
    }
  };
  let StudyMode = JSON.parse(localStorage.getItem("StudyMode"));
  const [updatedType, setUpdatedType] = useState("");
  const [filter, setFilter] = useState(false);
  const cleanForAddNewEventData = () => {
    setStudyUniqueId("");
    setUpdateStudyDynamicRow("");
    setVisitId("");
    setUpdatedType("addRow");
  };
  const seIdGenerateQuery = (studyUniqueId) => {
    setToggle9();
    setStudyUniqueId(studyUniqueId);
  };
  const setIdReviewRowTable = (studyUniqueId) => {
    setToggle10();
    setStudyUniqueId(studyUniqueId);
  };
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = studyDynamicRow?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(studyDynamicRow?.length / itemsPerPage);

  return (
    <>
      <EventHeaderMenu
        setUpdatedType={setUpdatedType}
        updatedType={updatedType}
        filterData={setToggle1}
        tableProperty={handleOpentablePropertyModal}
        cleanForAddNewEventData={cleanForAddNewEventData}
        studyDynamicColumn={studyDynamicColumn}
        setFilter={setFilter}
        filter={filter}
        studyDynamicRow={studyDynamicRow}
        getStudyVisitRowData={getStudyVisitRowData}
        viewTypeData={viewTypeData}
      />
      <div>
        <SplitPane
          split="horizontal"
          className="position-relative"
          style={{
            height: "calc(100vh - 187px)",
          }}
          defaultSize={isSectionVisible ? "40%" : "100%"}
        >
          <SplitPane
            split="vertical"
            minSize={20}
            defaultSize={updatedType ? "30%" : "100%"}
          >
            {StudyMode === "DBP MODE" &&
              updatedType &&
              (updatedType === "addColumn" || updatedType === "editColumn" ? (
                <AddUpdateEventColumn
                  formValues={formValues}
                  addHandleChange={handleChange}
                  updateHandleChange={addUpdateOnChange}
                  addFormFields={addFormFields}
                  removeAddUpdateFields={removeAddUpdateFields}
                  removeFormFields={removeFormFields}
                  handleSubmit0={handleSubmit0}
                  handleSubmit={handleSubmit}
                  loader={loadSpan}
                  statusCode={statusCode}
                  customMessage={customMessage}
                  studyDynamicColumn={studyDynamicColumn}
                />
              ) : (
                (updatedType === "addRow" || updatedType === "editRow") &&
                studyDynamicColumn?.length > 0 && (
                  <AddUpdateEventRows
                    formValues={formValues}
                    updateHandleChange={UpdateRowOnChange}
                    addHandleChange={onChangeInput}
                    addFormFields={addFormFields}
                    visitId={visitId}
                    setVisitId={setVisitId}
                    repeat={repeat}
                    setRepeat={setRepeat}
                    timePointId={timePointId}
                    setTimePointId={setTimePointId}
                    initiate={initiate}
                    loader={loadSpan}
                    statusCode={statusCode}
                    customMessage={customMessage}
                    setInitiate={setInitiate}
                    handleSubmit1={handleSubmit1}
                    handleSubmit2={handleSubmit2}
                    studyDynamicRow={studyDynamicRow}
                    studyDynamicColumnData={updateStudyDynamicRow}
                    studyAddDynamicColumnData={studyDynamicColumn}
                    cleanForAddNewSiteData={cleanForAddNewEventData}
                    studyUniqueId={studyUniqueId}
                    UpdateHistoryOnChange={UpdateHistoryOnChange}
                    onChangeInput={onChangeInput}
                    timePointData={timePointData}
                    setTimePointData={setTimePointData}
                    addTimePoints={addTimePoints}
                    removeTimePoints={removeTimePoints}
                    timePoints={timePoints}
                    setTimePoints={setTimePoints}
                  />
                )
              ))}
            {studyDynamicColumn?.length === 0 ? (
              <NoDataFoundTable MSG="Event not found" />
            ) : (
              <>
                <StudyEventTableData
                  seIdGenerateQuery={seIdGenerateQuery}
                  hideColumn={selectedNames}
                  studyDynamicColumn={studyDynamicColumn}
                  result={result}
                  handleSubmit3={handleSubmit3}
                  DuplicateStudyVisitRow={DuplicateStudyVisitRow}
                  getStudyRightSiteData={setVisitUniqueId}
                  setIsSectionVisible={setIsSectionVisible}
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                  setIdReviewRowTable={setIdReviewRowTable}
                  setIdUpdateRowTable={setIdUpdateRowTable}
                  setUpdatedType={setUpdatedType}
                  studyDynamicRow={studyDynamicRow}
                  setStudyDynamicRow={setStudyDynamicRow}
                  enableDrag={
                    StudyMode === "DBP MODE" && viewTypeData === "ctms"
                  }
                  viewTypeData={viewTypeData}
                  StudyMode={StudyMode}
                />
                <div className="position-sticky bottom-0">
                  <Pagination
                    totalPage={totalPage}
                    pageNumber={pageNumber}
                    itemsPerPage={itemsPerPage}
                    totalItems={studyDynamicRow?.length}
                    setPageNumber={setPageNumber}
                    setItemsPerPage={setItemsPerPage}
                  />
                </div>
              </>
            )}
          </SplitPane>
          {isSectionVisible && studyDynamicColumn?.length > 0 && (
            <EventTabProperties
              viewTypeData={viewTypeData}
              visitUniqueId={visitUniqueId}
              tableRef={tableRef}
              onMouseDown={onMouseDown}
              setIsSectionVisible={setIsSectionVisible}
              getStudyVisitRowData={getStudyVisitRowData}
            />
          )}
        </SplitPane>
      </div>
      <StudyEventTableProperty
        Show={!toggle7}
        Hide={setToggle7}
        allArray={allArray}
        selectedNames={selectedNames}
        setSelectedNames={setSelectedNames}
        studyDynamicColumn={studyDynamicColumn}
        studyId={studyId}
        studyDynamicColumnID={studyDynamicColumnID}
        handleSubmitTableProperty={handleSubmitTableProperty}
        Title={"Table Property"}
      />
      <StudyEventFilter
        Show={!toggle1}
        Hide={setToggle1}
        Title={"Filter"}
        setFilter={setFilter}
        filter={filter}
        setStudyDynamicRow={setStudyDynamicRow}
        getStudyVisitRowData={getStudyVisitRowData}
        studyDynamicColumnID={studyDynamicColumnID}
        viewTypeData={viewTypeData}
      />
      <GenerateStudyQuery
        Show={!toggle9}
        Hide={setToggle9}
        studyUniqueId={studyUniqueId}
        getStudyVisitRowData={getStudyVisitRowData}
        Title={"Generate Query"}
        Submit="Generate"
      />
      <EventSignature
        Show={!toggle10}
        Hide={setToggle10}
        studyUniqueId={studyUniqueId}
        Title={"Signature Required"}
      />
    </>
  );
};
export default StudyIDStudySchedule;
