import { useCallback, useState } from "react";
import { studyOnboardingService } from "./../../../../Services/studyServices/studyVisitsServices";
import moment from "moment";
import UseToggle from "../../../../Hooks/UseToggle";

export const UseAppendInput = (studyDynamicColumn, setStudyDynamicColumn) => {
  const [formValues0, setFormValues0] = useState([]);
  const [formValues, setFormValues] = useState([]);
  const [formValues1, setFormValues1] = useState([]);
  const [formValues2, setFormValues2] = useState([]);
  const [formValues3, setFormValues3] = useState([]);
  const [formValues4, setFormValues4] = useState([]);
  const [formValues5, setFormValues5] = useState([]);
  const [formValues6, setFormValues6] = useState([]);
  const [formValues7, setFormValues7] = useState([]);
  const [formValues8, setFormValues8] = useState([]);
  const [formValues9, setFormValues9] = useState([]);
  const [formValues10, setFormValues10] = useState([]);
  const [formValues11, setFormValues11] = useState([]);
  const [formValues12, setFormValues12] = useState([]);
  const [formValues13, setFormValues13] = useState([]);
  const [formValues14, setFormValues14] = useState([]);
  const [formValues15, setFormValues15] = useState([]);
  const [formValues16, setFormValues16] = useState([]);
  const [formValues17, setFormValues17] = useState([]);
  const [formValues18, setFormValues18] = useState([]);
  const [formValues19, setFormValues19] = useState([]);
  const [formValues20, setFormValues20] = useState([]);
  let randomNumber = Math.random().toString().substr(2, 10);

  //Heading
  const handleChange0 = useCallback((e, i) => {
    const { name, value, checked } = e.target;

    setFormValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[i][name] = value;
      return newValues;
    });
  }, []);

  const addFormFields0 = (type) => {
    setFormValues([...formValues, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields0 = (i) => {
    setFormValues((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };
  
  const duplicateFormFields0 = (i) => {
    setFormValues0((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  //Text
  const handleChange = useCallback((e, i) => {
    const { name, value, checked } = e.target;

    setFormValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[i][name] = value;
      return newValues;
    });
  }, []);

  const addFormFields = (type) => {
    setFormValues([...formValues, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields = (i) => {
    setFormValues((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };
  
  const duplicateFormFields = (i) => {
    setFormValues((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  //Textarea
  const handleChange1 = useCallback((e, i) => {
    const { name, value } = e.target;
    setFormValues1((prevValues) => {
      const newValues = [...prevValues];
      newValues[i][name] = value;
      return newValues;
    });
  }, []);

  const addFormFields1 = (type) => {
    setFormValues1([...formValues1, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields1 = (i) => {
    setFormValues1((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };

  const duplicateFormFields1 = (i) => {
    setFormValues1((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  //Number
  const handleChange2 = useCallback((e, i) => {
    const { name, value } = e.target;
    setFormValues2((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      return newValues;
    });
  }, []);

  const addFormFields2 = (type) => {
    setFormValues2([...formValues2, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields2 = (i) => {
    setFormValues2((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };

  const duplicateFormFields2 = (i) => {
    setFormValues2((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  //Date
  const handleChange3 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues3((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      return newValues;
    });
  }, []);

  const addFormFields3 = (type) => {
    setFormValues3([...formValues3, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields3 = (i) => {
    setFormValues3((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };
  
  const duplicateFormFields3 = (i) => {
    setFormValues3((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  //Time
  const handleChange4 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues4((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      return newValues;
    });
  }, []);

  const addFormFields4 = (type) => {
    setFormValues4([...formValues4, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields4 = (i) => {
    setFormValues4((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };
  
  const duplicateFormFields4 = (i) => {
    setFormValues4((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  //Checkbox
  const handleChange5 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues5((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      return newValues;
    });
  }, []);

  const addFormFields5 = (type) => {
    setFormValues5([...formValues5, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields5 = (i) => {
    setFormValues5((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };
  
  const duplicateFormFields5 = (i) => {
    setFormValues5((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  //Radio
  const handleChange6 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues6((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      return newValues;
    });
  }, [setFormValues6]);


  const addFormFields6 = (type) => {
    setFormValues6([...formValues6, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields6 = (i) => {
    setFormValues6((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };
  
  const duplicateFormFields6 = (i) => {
    setFormValues6((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  //Dropdown
  const handleChange7 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues7((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      return newValues;
    });
  }, [setFormValues7]);

  const addFormFields7 = (type) => {
    setFormValues7([...formValues7, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields7 = (i) => {
    setFormValues7((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };
  
  const duplicateFormFields7 = (i) => {
    setFormValues7((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  //Upload Files
  const handleChange8 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues8((prevValues) => {
      const newValues = [...prevValues];
      newValues[i][name] = value;
      return newValues;
    });
  }, [setFormValues8]);

  const addFormFields8 = (type) => {
    setFormValues8([...formValues8, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields8 = (i) => {
    setFormValues8((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };
  
  const duplicateFormFields8 = (i) => {
    setFormValues8((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  //Scale
  const handleChange9 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues9((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      
      return newValues;
    });
  }, [setFormValues9]);

  const addFormFields9 = (type) => {
    setFormValues9([...formValues9, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields9 = (i) => {
    setFormValues9((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };
  
  const duplicateFormFields9 = (i) => {
    setFormValues9((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  //Score
  const handleChange10 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues10((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      
      return newValues;
    });
  }, [setFormValues10]);

  const addFormFields10 = (type) => {
    setFormValues10([...formValues10, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields10 = (i) => {
    setFormValues10((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };
  
  const duplicateFormFields10 = (i) => {
    setFormValues10((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  //Swallon
  const handleChange11 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues11((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      
      return newValues;
    });
  }, [setFormValues11]);

  const addFormFields11 = (type) => {
    setFormValues11([...formValues11, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields11 = (i) => {
    setFormValues11((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };
  
  const duplicateFormFields11 = (i) => {
    setFormValues11((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  //Tender
  const handleChange12 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues12((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      
      return newValues;
    });
  }, [setFormValues12]);

  const addFormFields12 = (type) => {
    setFormValues12([...formValues12, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields12 = (i) => {
    setFormValues12((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };
  
  const duplicateFormFields12 = (i) => {
    setFormValues12((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  //Swallon & tender
  const handleChange13 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues13((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      
      return newValues;
    });
  }, [setFormValues13]);

  const addFormFields13 = (type) => {
    setFormValues13([...formValues13, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields13 = (i) => {
    setFormValues13((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };
  
  const duplicateFormFields13 = (i) => {
    setFormValues13((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  //Lab Form
  const handleChange14 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues14((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      
      return newValues;
    });
  }, [setFormValues14]);

  const addFormFields14 = (type) => {
    setFormValues14([...formValues14, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields14 = (i) => {
    setFormValues14((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };
  
  const duplicateFormFields14 = (i) => {
    setFormValues14((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  
  //Vital Signs Form
  const handleChange15 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues15((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      
      return newValues;
    });
  }, [setFormValues15]);

  const addFormFields15 = (type) => {
    setFormValues15([...formValues15, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields15 = (i) => {
    setFormValues15((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };
  
  const duplicateFormFields15 = (i) => {
    setFormValues15((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  //Physical Examination Form
  const handleChange16 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues16((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      
      return newValues;
    });
  }, [setFormValues16]);

  const addFormFields16 = (type) => {
    setFormValues16([...formValues16, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields16 = (i) => {
    setFormValues16((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };
  
  const duplicateFormFields16 = (i) => {
    setFormValues16((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  //ECG Form
  const handleChange17 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues17((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      
      return newValues;
    });
  }, [setFormValues17]);

  const addFormFields17 = (type) => {
    setFormValues17([...formValues17, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields17 = (i) => {
    setFormValues17((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };
  
  const duplicateFormFields17 = (i) => {
    setFormValues17((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  //HAQ Form
  const handleChange18 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues18((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      
      return newValues;
    });
  }, [setFormValues18]);

  const addFormFields18 = (type) => {
    setFormValues18([...formValues18, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields18 = (i) => {
    setFormValues18((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };
  
  const duplicateFormFields18 = (i) => {
    setFormValues18((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  //Line Scale Form
  const handleChange19 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues19((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      
      return newValues;
    });
  }, [setFormValues19]);

  const addFormFields19 = (type) => {
    setFormValues19([...formValues19, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields19 = (i) => {
    setFormValues19((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };
  
  const duplicateFormFields19 = (i) => {
    setFormValues19((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  //PASI Form
  const handleChange20 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues20((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      
      return newValues;
    });
  }, [setFormValues20]);

  const addFormFields20 = (type) => {
    setFormValues20([...formValues20, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields20 = (i) => {
    setFormValues20((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };
  
  const duplicateFormFields20 = (i) => {
    setFormValues20((prevValues) => {
      const newValues = [...prevValues];
      const duplicatedField = { ...newValues[i], uniqueId: randomNumber }; 
      newValues.splice(i + 1, 0, duplicatedField);
      return newValues;
    });
  };

  const onChangeInput = (e, i) => {
    const { name, value } = e.target;
    let items1 = [...studyDynamicColumn];
    items1[i][name] = value;

    setStudyDynamicColumn(items1);
  };

  return {
    formValues0,
    handleChange0,
    addFormFields0,
    removeFormFields0,
    duplicateFormFields0,
    setFormValues0,

    formValues,
    handleChange,
    addFormFields,
    removeFormFields,
    duplicateFormFields,
    setFormValues,

    formValues1,
    handleChange1,
    addFormFields1,
    removeFormFields1,
    duplicateFormFields1,
    setFormValues1,

    formValues2,
    handleChange2,
    addFormFields2,
    removeFormFields2,
    duplicateFormFields2,
    setFormValues2,

    formValues3,
    handleChange3,
    addFormFields3,
    removeFormFields3,
    duplicateFormFields3,
    setFormValues3,

    formValues4,
    handleChange4,
    addFormFields4,
    removeFormFields4,
    duplicateFormFields4,
    setFormValues4,

    formValues5,
    handleChange5,
    addFormFields5,
    removeFormFields5,
    duplicateFormFields5,
    setFormValues5,

    formValues6,
    handleChange6,
    addFormFields6,
    removeFormFields6,
    duplicateFormFields6,
    setFormValues6,

    formValues7,
    handleChange7,
    addFormFields7,
    removeFormFields7,
    duplicateFormFields7,
    setFormValues7,

    formValues8,
    handleChange8,
    addFormFields8,
    removeFormFields8,
    duplicateFormFields8,
    setFormValues8,

    formValues9,
    handleChange9,
    addFormFields9,
    removeFormFields9,
    duplicateFormFields9,
    setFormValues9,

    formValues10,
    handleChange10,
    addFormFields10,
    removeFormFields10,
    duplicateFormFields10,
    setFormValues10,

    formValues11,
    handleChange11,
    addFormFields11,
    removeFormFields11,
    duplicateFormFields11,
    setFormValues11,

    formValues12,
    handleChange12,
    addFormFields12,
    removeFormFields12,
    duplicateFormFields12,
    setFormValues12,

    formValues13,
    handleChange13,
    addFormFields13,
    removeFormFields13,
    duplicateFormFields13,
    setFormValues13,

    formValues14,
    handleChange14,
    addFormFields14,
    removeFormFields14,
    duplicateFormFields14,
    setFormValues14,

    formValues15,
    handleChange15,
    addFormFields15,
    removeFormFields15,
    duplicateFormFields15,
    setFormValues15,

    formValues16,
    handleChange16,
    addFormFields16,
    removeFormFields16,
    duplicateFormFields16,
    setFormValues16,

    formValues17,
    handleChange17,
    addFormFields17,
    removeFormFields17,
    duplicateFormFields17,
    setFormValues17,

    formValues18,
    handleChange18,
    addFormFields18,
    removeFormFields18,
    duplicateFormFields18,
    setFormValues18,

    formValues19,
    handleChange19,
    addFormFields19,
    removeFormFields19,
    duplicateFormFields19,
    setFormValues19,

    formValues20,
    handleChange20,
    addFormFields20,
    removeFormFields20,
    duplicateFormFields20,
    setFormValues20,

    onChangeInput
  };
};

export const UseUpdateColumn = (studyDynamicColumn, setStudyDynamicColumn) => {
  const addUpdateOnChange = (e, i) => {
    const { name, value } = e.target;

    let newValues = [...studyDynamicColumn];
    newValues[i][name] = value;

    setStudyDynamicColumn(newValues);
  };

  const addUpdateFields = () =>
    setStudyDynamicColumn([...studyDynamicColumn, {}]);

  const removeAddUpdateFields = (i) => {
    let newFormValues = [...studyDynamicColumn];
    newFormValues.splice(i, 1);

    setStudyDynamicColumn(newFormValues);
  };

  return { addUpdateOnChange, addUpdateFields, removeAddUpdateFields };
};

export const UseSignature = (endPoint, connectUniqueIdData) => {

  const SignFormDetails = {
    userId: "",
    password: "",
    review: "",
    status: "",
  };

  const reviewIdDetails = {
    uniqueId: "",
    study_id: "",
    project_id: "",
    studyUniqueId: "",
    formFieldId: "",
  };

  const { toggle4: signModal, setToggle4: setSignModal } = UseToggle();

  const [signForm, setSignForm] = useState(SignFormDetails);
  const [reviewIds, setReviewIds] = useState(reviewIdDetails);

  const onValueChange = (e, type) =>
    setSignForm({ ...signForm, [type]: e.target.value });

  const setIdReviewRowTable = (uniqueId, study_id, project_id, studyUniqueId, formFieldId) => {
    setSignModal();
    setReviewIds({ ...reviewIds, uniqueId, study_id, project_id, studyUniqueId, formFieldId});
  };

  const studyReview = async (e) => {
    e.preventDefault();
    let data = {
      ...signForm,
      ...reviewIds,
      connectUniqueIdData : connectUniqueIdData
    };

    const res = await studyOnboardingService.reviewStudyVisit(data, endPoint);
    if (res?.statusCode === 200) {
      setSignForm(SignFormDetails);
      setReviewIds(reviewIdDetails);
    }
  };

  const signFormProps = () => ({
    SHOW: !signModal,
    HIDE: setSignModal,
    Title: "Signature Required",
    UpdateRow: "UpdateRow",
    type2: "password",
    Label1: "Username",
    Label2: "Password",
    Radio: "Radio",
    Value1: signForm?.userId,
    Value2: signForm?.password,
    OnChange1: (e) => onValueChange(e, "userId"),
    OnChange2: (e) => onValueChange(e, "password"),
    RadioOnChange: (e) => onValueChange(e, "status"),
    RadioChecked1: signForm?.status === "varified",
    RadioChecked2: signForm?.status === "pending",
    CommentValue: signForm?.review,
    CommentOnChange: (e) => onValueChange(e, "review"),
    SubmitTitle: "Submit",
    SubmitForm: (e) => studyReview(e),
  });

  return {
    signForm,
    setIdReviewRowTable,
    onValueChange,
    setSignModal,
    signFormProps,
  };
};

export const UseAttributeTable = () => {
  // GET ATTRIBUTE TABLE DATA.....................................................

  const attributeFormDetails = {
    attributeDetails: [],
    uploadedBy: [],
    jobCreatedDateTime: [],
    version: [],
    reviewedBy: [],
    reviewUpdatedDateTime: [],
  };

  const [attributeForm, setAttributeForm] = useState(attributeFormDetails);
  let studyMode = JSON.parse(localStorage.getItem("StudyMode"));

  const getStudyVisitAttributeByID = async (e) => {

    let data = await studyOnboardingService.getStudyVisitAttributeByID(e, studyMode);

    setAttributeForm({
      ...attributeForm,
      attributeDetails: data?.data,
      uploadedBy: data?.data?.[0]?.uploadedBy,

      jobCreatedDateTime: moment(data?.data?.[0]?.createdAt).format(
        "DD-MM-YYYY | HH:mm"
      ),

      version: data?.data?.[0]?.version,
      reviewedBy: data?.data?.[0]?.reviewedBy,
      status: data?.data?.[0]?.status,

      reviewUpdatedDateTime: moment(data?.data?.[0]?.updatedAt).format(
        "DD-MM-YYYY | HH:mm"
      ),
    });
  };

  return { attributeForm, getStudyVisitAttributeByID };
};

export const UseHistoryTable = () => {
  const [studyVisitHistoryLists, setStudyVisitHistoryLists] = useState([]);

  // GET HISTORY TABLE DATA?.......................................................

  const getStudyVisitHistoryByID = async (e) => {
    let data = await studyOnboardingService.getStudyVisitHistoryByID(e);
    let initialData = data?.data;
    let finalData = initialData?.sort()?.reverse();
    setStudyVisitHistoryLists(finalData);
  };

  return { studyVisitHistoryLists, getStudyVisitHistoryByID };
};
