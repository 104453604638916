/* eslint-disable react-hooks/exhaustive-deps */
import Swal from "sweetalert2";
import UseToggle from "./UseToggle";
import { studyOnboardingService } from "../Services/studyServices/studyVisitsServices";
import { useState, useEffect } from "react";
import UseHideShowColumn from "./UseHideShowColumn";
import {
  UseAppendInput,
  UseAttributeTable,
  UseHistoryTable,
  UseSignature,
  UseUpdateColumn,
} from "../Components/CommonComponents/DashboardBody/StudyIdData/UseAppendInput";

import { ResizeTableContext } from "../Components/CommonComponents/ResizeTable/ResizeTable";
import { useContext } from "react";
import { useParams } from "react-router";

const UseStudySchedule = () => {
  const resizeTable = useContext(ResizeTableContext);
  const { studyId } = useParams();
  const {
    toggle,
    setToggle,
    toggle1,
    setToggle1,
    toggle2,
    setToggle2,
    toggle3,
    setToggle3,
    toggle4: signModal,
    setToggle4: setSignModal,
    toggle5,
    setToggle5,
    toggle6,
    setToggle6,
    toggle7,
    setToggle7,
    toggle8,
    setToggle8,
    toggle9,
    setToggle9,
    toggle10,
    setToggle10,

    loader,
    setLoader,
  } = UseToggle();

  useEffect(() => {
    getStudyVisitColumnData();
    // getStudyVisitRowData();
  }, []);

  const [id, setId] = useState("");
  const [count, setCount] = useState("");
  const [visitId, setVisitId] = useState("");
  const [repeat, setRepeat] = useState("");
  const [initiate, setInitiate] = useState("");
  const [timePointId, setTimePointId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [studyDynamicColumn, setStudyDynamicColumn] = useState([]);
  const [studyDynamicRow, setStudyDynamicRow] = useState([]);
  const [updateStudyDynamicRow, setUpdateStudyDynamicRow] = useState([]);
  const [metedataComment, setMetedataComment] = useState([]);
  const [studyDynamicColumnID, setStudyDynamicColumnID] = useState([]);
  const [reviewHistoryLists, setReviewHistoryLists] = useState([]);
  const [statusCode, setStatusCode] = useState(0);
  const [customMessage, setCustomMessage] = useState("");
  const [history, setHistory] = useState("");
  const [loadSpan, setLoadSpan] = useState(false);
  const [studyUniqueId, setStudyUniqueId] = useState("");
  const limit = 100;

  const [timePointData, setTimePointData] = useState("");
  const [timePoints, setTimePoints] = useState([{ id: 1, timePoint: "", initiate: "" }]);

  const addTimePoints = () => {
    const newId = timePoints.length ? timePoints[timePoints.length - 1].id + 1 : 1;
    setTimePoints([...timePoints, { id: newId, timePoint: "", initiate: "" }]);
  };

  const removeTimePoints = (id) => {
    setTimePoints(timePoints.filter((timePoint) => timePoint.id !== id));
  };


  setTimeout(() => {
    setStatusCode(0);
    setCustomMessage("");
  }, 5000);

  const {
    handleCheck,
    handleCheck1,
    handleCheck2,
    handleCheck3,
    tableColumn,
    tableColumn1,
    tableColumn2,
    tableColumn3,
    tableColumn4,
    tableColumn5,

    showColumn,
  } = UseHideShowColumn();

  const { addUpdateOnChange, addUpdateFields, removeAddUpdateFields } =
    UseUpdateColumn(studyDynamicColumn, setStudyDynamicColumn);

  const {
    formValues,
    handleChange,
    addFormFields,
    removeFormFields,
    onChangeInput,
  } = UseAppendInput(studyDynamicColumn, setStudyDynamicColumn);

  const { attributeForm, getStudyVisitAttributeByID } = UseAttributeTable();

  const { studyVisitHistoryLists, getStudyVisitHistoryByID } =
    UseHistoryTable();

  const { signForm, onValueChange } = UseSignature();

  const setIdUpdateRowTable = (
    id,
    studyUniqueId,
    dynamicRowData,
    visitIdLabel,
    repeat,
    order,
    initiate,
    timePointId
  ) => {
    setId(id);
    setStudyUniqueId(studyUniqueId);
    setUpdateStudyDynamicRow(Object.values(dynamicRowData));
    getStudyRightSiteData(studyUniqueId);
    setVisitId(visitIdLabel);
    setRepeat(repeat)
    setInitiate(initiate)
    setTimePointId(timePointId)
    setTimePointData("")
    setTimePoints([{ id: 1, timePoint: "", initiate: "" }])
  };

  const setIdReviewRowTable = (studyUniqueId) => {
    setSignModal();
    setStudyUniqueId(studyUniqueId);
  };

  useEffect(() => {
    getStudyVisitRowData();
  }, [studyDynamicColumnID]);

  useEffect(() => {
    resizeTable();
  });

  const studyReview = async (e) => {
    e.preventDefault();
    let datas = {};
    datas.study_id = studyId;
    datas.studyUniqueId = studyUniqueId;
    datas.userId = signForm?.userName;
    datas.password = signForm?.password;
    datas.review = signForm?.comment;
    datas.status = signForm?.selectedOption;
    studyOnboardingService.reviewStudyVisit(datas);
    getStudyVisitRowData();
  };

  const getStudyVisitReviewData = async (id) => {
    let data = await studyOnboardingService.getStudyVisitReviewData(id);
    let initialData = data?.data;
    let finalData = initialData?.sort()?.reverse();
    setReviewHistoryLists(finalData);
  };

  const getStudyVisitColumnData = async () => {
    // setLoader(false)
    let data = await studyOnboardingService.getStudyVisitColumnData(studyId);
    let initialData = data?.data === undefined ? "" : data?.data;
    // setLoader(true);
    if (initialData.length > 0) {
      let finalData = initialData?.sort();
      setStudyDynamicColumn(Object.values(finalData?.[0]?.column));
      setStudyDynamicColumnID(finalData?.[0]._id);
    } else {
      setStudyDynamicColumn("");
      setStudyDynamicColumnID("");
    }
  };

  const getStudyVisitRowData = async (page) => {
    // setLoader(false)
    let currentPage = page ? page : 1
    let data = await studyOnboardingService.getStudyVisitRowData(
      studyId,
      studyDynamicColumnID,
      currentPage,
      limit
    );
    let initialData = Object.values(data.data === undefined ? "" : data?.data);
    let finalData = initialData?.sort()?.reverse();
    setStudyDynamicRow(finalData);
    setCount(Math.ceil(data?.count / limit));
    // setLoader(true);
  };

  const getStudyRightSiteData = (id) => {
    getStudyVisitAttributeByID(id);
    getStudyVisitReviewData(id);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getStudySiteHistory = (id) => {
    getStudyVisitHistoryByID(id);
    handleShow()
  };

  const exportStudyVisitDataInMultiFormat = async (extension) => {
    await studyOnboardingService.exportStudyVisitDataInMultiFormat(
      studyId,
      extension
    );
  };

  const exportStudyVisitsAuditTrialDataInMultiFormat = async (extension) => {
    await studyOnboardingService.exportStudyVisitsAuditTrialDataInMultiFormat(
      studyId,
      extension
    );
  };

  const modifiedData = formValues.map(obj => {
    const { type, ...rest } = obj;  // Destructure to remove 'type'
    return rest;  // Return object without 'type'
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadSpan(true)
    let datas = {};
    datas.studyID = studyId;
    datas.dynamicColumnData = modifiedData;
    let data = await studyOnboardingService.createStudyVisitColumn(datas);
    if (data?.statusCode === 200) {
      getStudyVisitRowData();
      getStudyVisitColumnData();
      setLoadSpan(false)
    } else {
      setLoadSpan(false)
    }
    setStatusCode(data?.statusCode);
    setCustomMessage(data?.customMessage);

  };

  const [selectedNames, setSelectedNames] = useState([]);
  let allArray = ["Visit Id", "Repeat", "Time Point Id", "Initiate", "Query Status", "Query Date | Time"]
  const getTablePropertyData = async () => {
    let data = await studyOnboardingService.getTablePropertyData("studyVisit", studyDynamicColumnID, studyId);
    let columnProperty = data?.data?.columnData
    let finalColumnProperty = columnProperty === undefined ? allArray : columnProperty
    setSelectedNames(finalColumnProperty)
  };

  const handleSubmit0 = async (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Study Visit!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        setLoadSpan(true)
        if (studyDynamicColumn.length === 0) {

          Swal.fire({
            icon: "error",
            title: "Error",
            text: "At least one value is required to update!",
          });
          return;
        }
        let datas = {};
        datas.studyID = studyId;
        datas.dynamicColumnData = studyDynamicColumn;
        let result = await studyOnboardingService.updateStudyVisitColumn(
          studyDynamicColumnID,
          datas
        );
        if (result.statusCode === 200) {
          getTablePropertyData();
          getStudyVisitRowData();
          getStudyVisitColumnData();
          setLoadSpan(false)
        } else {
          setLoadSpan(false)
        }
        setStatusCode(result?.statusCode);
        setCustomMessage(result?.customMessage);
      }
    });
  };

  const handleOpentablePropertyModal = () => {
    getTablePropertyData()
    setToggle7()
  }

  useEffect(() => {
    getTablePropertyData();
  }, [studyDynamicColumnID]);


  const handleSubmit1 = async (e) => {
    e.preventDefault();
    let datas = {};
    if (timePointData === "yes") {
      datas.timePoints = timePoints
    } else {
      datas.initiate = initiate;
    }
    datas.timePointData = timePointData;
    datas.studyID = studyId;
    datas.visitId = visitId;
    datas.repeat = repeat;
    datas.columnId = studyDynamicColumnID;
    datas.dynamicColumnData = studyDynamicColumn;
    setLoadSpan(true)
    let data = await studyOnboardingService.AddStudyVisitRow(datas);
    getStudyVisitRowData();
    setLoadSpan(false)
    setRepeat("")
    setVisitId("")
    setTimePointData("")
    setTimePoints([{ id: 1, timePoint: "", initiate: "" }])
    getStudyVisitColumnData()
    setStatusCode(data?.statusCode);
    setCustomMessage(data?.customMessage);
  };

  const handleSubmit2 = async (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update tudy Visit!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        setLoadSpan(true)
        let datas = {};
        datas.studyID = studyId;
        datas.dynamicColumnData = updateStudyDynamicRow;
        datas.studyUniqueId = studyUniqueId;
        datas.history = metedataComment;
        datas.repeat = repeat;
        datas.initiate = initiate;
        datas.visitIdLabel = visitId;
        datas.timePointId = timePointId;
        datas.timePoints = timePoints;
        datas.timePointData = timePointData;
        let data = await studyOnboardingService.UpdateStudyVisitRow(id, datas);
        getStudyVisitRowData();
        setLoadSpan(false)
        setTimePointData("")
        setTimePoints([{ id: 1, timePoint: "", initiate: "" }])
        setStatusCode(data?.statusCode);
        setCustomMessage(data?.customMessage);
      }
    });
  };

  const handleSubmit3 = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Study Visit!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        let datas = {};
        datas.dynamicColumnData = studyDynamicColumn;
        let data = await studyOnboardingService.deleteStudyVisitRowData(id, datas);
        if (data?.statusCode === 200) {
          getStudyVisitRowData();
          setLoadSpan(false)
        } else {
          setLoadSpan(false)
        }
        setLoadSpan(false)
      }
    });
  };

  const DuplicateStudyVisitRow = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Repeat this Study Visit Form!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, repeat it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        let data = await studyOnboardingService.DuplicateStudyVisitRow(id);
        if (data?.statusCode === 200) {
          getStudyVisitRowData();
          setLoadSpan(false)
        } else {
          setLoadSpan(false)
        }
      }
    });
  };

  const hideColumnAddPopup = () => {
    setToggle();
    getStudyVisitColumnData();
  };

  const hideColumnUpdatePopup = () => {
    setToggle1();
    getStudyVisitColumnData();
  };

  const hideRowAddPopup = () => {
    setStudyDynamicColumn([]);
    getStudyVisitColumnData();
    getStudyVisitRowData();
    setToggle2();
    setVisitId("")
  };

  const hideRowUpdatePopup = () => {
    setToggle3();
    setVisitId("")
    setStudyDynamicColumn([]);
    getStudyVisitColumnData();
    getStudyVisitRowData();
  };

  // UPDATE ROW DATA ONCHANGE ..........................................

  const UpdateRowOnChange = (e, i) => {
    const { name, value } = e.target;

    let newValues = [...updateStudyDynamicRow];
    newValues[i][name] = value;

    setUpdateStudyDynamicRow(newValues);
  };

  const UpdateHistoryOnChange = (e, i) => {
    const { name, value } = e.target;

    let newValues = [...updateStudyDynamicRow];
    newValues[i][name] = value;

    setMetedataComment(newValues);
  };

  // const reviewHistoryProps = {
  //   ResizeID: "resizeMe3",
  //   One: " ",
  //   Two: "Review History",
  //   FieldLabel1: "Version",
  //   FieldLabel2: "Date & Time",
  //   FieldLabel3: "Reviewer ID",
  //   FieldLabel4: "Review Comments",
  //   AttributeDetails: attributeForm?.attributeDetails,
  //   AttributeDetails1: attributeForm?.uploadedBy,
  //   AttributeDetails2: attributeForm?.jobCreatedDateTime,
  //   AttributeDetails3: attributeForm?.version,
  //   AttributeDetails4: attributeForm?.reviewedBy,
  //   hideShowTable: setToggle7,
  //   tableColumn: tableColumn,
  //   tableColumn1: tableColumn1,
  //   tableShow: toggle7,
  // };

  const permission = JSON.parse(localStorage.getItem("permission"));

  const validatorPermission = permission === "validator";

  return {
    timePointData,
    setTimePointData,
    addTimePoints,
    removeTimePoints,
    timePoints,
    setTimePoints,
    customMessage,
    statusCode,
    setInitiate,
    timePointId,
    setTimePointId,
    initiate,
    setRepeat,
    repeat,
    setVisitId,
    visitId,
    toggle,
    hideColumnAddPopup,
    formValues,
    handleChange,
    addFormFields,
    removeFormFields,
    handleSubmit,
    toggle1,
    hideColumnUpdatePopup,
    studyDynamicColumn,
    addUpdateOnChange,
    addUpdateFields,
    removeAddUpdateFields,
    handleSubmit0,
    toggle2,
    hideRowAddPopup,
    onChangeInput,
    handleSubmit1,
    toggle3,
    hideRowUpdatePopup,
    history,
    setHistory,
    UpdateRowOnChange,
    UpdateHistoryOnChange,
    updateStudyDynamicRow,
    setUpdateStudyDynamicRow,
    handleSubmit2,
    studyUniqueId,
    setStudyUniqueId,
    signModal,
    setSignModal,
    signForm,
    onValueChange,
    studyReview,
    handleCheck,
    handleCheck1,
    handleCheck2,
    handleCheck3,
    showColumn,
    setToggle2,
    setToggle,
    setToggle1,
    exportStudyVisitDataInMultiFormat,
    exportStudyVisitsAuditTrialDataInMultiFormat,
    currentPage,
    setCurrentPage,
    getStudyVisitRowData,
    count,
    limit,
    loader,
    tableColumn,
    validatorPermission,
    tableColumn1,
    tableColumn2,
    tableColumn3,
    tableColumn4,
    tableColumn5,
    studyDynamicRow,
    setIdUpdateRowTable,
    setIdReviewRowTable,
    handleSubmit3,
    DuplicateStudyVisitRow,
    getStudyRightSiteData,
    getStudySiteHistory,
    handleClose,
    show,
    attributeForm,
    setToggle5,
    toggle5,
    studyVisitHistoryLists,
    setToggle6,
    toggle6,
    reviewHistoryLists,
    setToggle8,
    toggle8,
    toggle9,
    setToggle9,
    toggle10,
    setToggle10,
    toggle7,
    setToggle7,
    studyDynamicColumnID,
    getStudyVisitColumnData,
    setSelectedNames,
    selectedNames,
    allArray,
    handleOpentablePropertyModal,
    setStudyDynamicRow,
    setLoadSpan,
    loadSpan
  };
};

export default UseStudySchedule;