import React, { useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { cdiscServices } from "../../../../../../../Services/CDISC/cdiscServices";
import { FaTrashCan } from "react-icons/fa6";

const AddColumnToolsWithValue = ({
  studyId,
  derivationId,
  getDomainDerivationList,
}) => {
  let [newColumns, setNewColumns] = useState([]);
  let [uniqueColumnKey, setUniqueColumnKey] = useState([]);
  let [templateIds, setTemplateIds] = useState([]);
  let [columns, setColumns] = useState([]);
  let [primaryDataType, setPrimaryDataType] = useState("");

  const findDomainDerivationData = async () => {
    let result = await cdiscServices.findDomainDerivationData(
      studyId,
      derivationId
    );
    let derivationData = result?.data;
    if (derivationData?.newColumnsCustomValue?.length > 0) {
      setNewColumns(derivationData?.newColumnsCustomValue || []);
    } else if (derivationData?.newColumnsColumnValue?.length > 0) {
      setNewColumns(derivationData?.newColumnsColumnValue || []);
    } else if (derivationData?.newColumnsFunctionValue?.length > 0) {
      setNewColumns(derivationData?.newColumnsFunctionValue || []);
    }
    if (
      derivationData?.primaryDataset ||
      derivationData?.secondaryDataType ||
      derivationData?.cellDataset
    ) {
      setTemplateIds([
        { templateId: derivationData?.primaryDataset || "" },
        { templateId: derivationData?.secondaryDataset || "" },
        { templateId: derivationData?.cellDataset || "" },
      ]);
    }
    setPrimaryDataType(derivationData?.primaryDataType);
    if (derivationData?.primaryDataset || derivationData?.primaryDataType) {
      getDatasetColumList(
        derivationData?.primaryDataset,
        derivationData?.primaryDataType
      );
    }
  }

  const getMultiDataEntryColumList = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.templateIds = templateIds || [];
    let data = await cdiscServices.getMultiDataEntryColumList(userData);
    setUniqueColumnKey(data?.uniqueColumnKey || []);
  };

  const getDatasetColumList = async (primaryDataset, type) => {
    let data = await cdiscServices.getDatasetColumList(
      studyId,
      type,
      primaryDataset
    );
    setColumns(data?.data);
  };

  useEffect(() => {
    if (templateIds?.length > 0) {
      getMultiDataEntryColumList();
    }
  }, [templateIds?.length]);

  const [functionList, setFunctionList] = useState([]);

  const getFunctionList = async () => {
    let data = await cdiscServices.getFunctionList(studyId, derivationId, "no")
    setFunctionList(data?.data)
  }

  useEffect(() => {
    getFunctionList()
    findDomainDerivationData();
  }, [derivationId]);

  const derivationCRFData = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.derivationId = derivationId;
    userData.newColumnsCustomValue = newColumns?.filter(item => item.valueType === "addCustomValue") || [];
    userData.newColumnsColumnValue = newColumns?.filter(item => item.valueType === "addColumnValue") || [];
    userData.newColumnsFunctionValue = newColumns?.filter(item => item.valueType === "addValueByFunction") || [];;
    await cdiscServices.derivationSaveCRFData(userData);
    getDomainDerivationList();
  };

  const handleAddNewColumn = () => {
    setNewColumns([
      ...newColumns,
      {
        id: newColumns.length + 1,
        name: "",
        label: "",
        dataType: "",
        valueType: "",
        permissionType: "permissible",
        value: "",
      },
    ]);
  };

  const handleRemoveNewColumn = (id) => {
    const updatedSections = newColumns.filter((section) => section.id !== id);
    setNewColumns(updatedSections);
  };

  const handleSelectNewColoumnChange = (id, field, value) => {
    const updatedSections = newColumns.map((section) =>
      section.id === id ? { ...section, [field]: value } : section
    );
    setNewColumns(updatedSections);
  };

  return (
    <div>
      <div className="p-2 border-bottom hstack justify-content-between position-sticky top-0 z-1 bg-white">
        <button onClick={handleAddNewColumn} className="updateProfile">
          Add New Column
        </button>
        <button onClick={derivationCRFData} className="updateProfile">
          Save
        </button>
      </div>
      <div className="py-2 px-3">
        <Row>
          {newColumns?.length > 0 ? (
            newColumns?.map((data, index) => (
              <Form.Group as={Col} md={6}>
                <div className="position-relative shadow-sm border mb-3">
                  <div className="bg-light border-bottom p-1 px-3 hstack justify-content-between">
                    <Form.Label className="mb-1 fw-bold">
                      {data?.name || `New Column ${index + 1}`}{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <FaTrashCan
                      onClick={() => handleRemoveNewColumn(data?.id)}
                      style={{ fontSize: 12 }}
                      className="CP text-danger"
                      title="Remove Statement"
                    />
                  </div>
                  <div className="p-3">
                    <div className="w-100">
                      <div key={index} className="hstack gap-1 w-100 mb-2">
                        <Form.Group className="w-100">
                          <Form.Control
                            value={data.name}
                            onChange={(e) =>
                              handleSelectNewColoumnChange(
                                data.id,
                                "name",
                                e.target.value
                              )
                            }
                            type="text"
                            placeholder="Enter Column Name"
                          />
                        </Form.Group>
                        <Form.Group className="w-100">
                          <Form.Control
                            value={data.label}
                            onChange={(e) =>
                              handleSelectNewColoumnChange(
                                data.id,
                                "label",
                                e.target.value
                              )
                            }
                            type="text"
                            placeholder="Enter Column Label"
                          />
                        </Form.Group>
                      </div>
                      <div className="hstack gap-1 w-100 mb-2">
                        <Form.Group className="w-100">
                          <Form.Select
                            onChange={(e) =>
                              handleSelectNewColoumnChange(
                                data.id,
                                "dataType",
                                e.target.value
                              )
                            }
                          >
                            <option value="">
                              Select Column Data Type
                            </option>
                            <option
                              selected={data?.dataType === "text"}
                              value="text"
                            >
                              Text
                            </option>
                            <option
                              selected={data?.dataType === "number"}
                              value="number"
                            >
                              Number
                            </option>
                            <option
                              selected={data?.dataType === "date"}
                              value="date"
                            >
                              Date
                            </option>
                            <option
                              selected={data?.dataType === "time"}
                              value="time"
                            >
                              Time
                            </option>
                          </Form.Select>
                        </Form.Group>
                        <Form.Group className="w-100">
                          <Form.Select
                            onChange={(e) => handleSelectNewColoumnChange(
                              data.id,
                              "valueType",
                              e.target.value
                            )
                            }
                          >
                            <option value="">Select Value Type</option>
                            <option selected={data?.valueType === "addCustomValue"} value="addCustomValue">Custom Value</option>
                            <option selected={data?.valueType === "addColumnValue"} value="addColumnValue">Column Value</option>
                            <option selected={data?.valueType === "addValueByFunction"} value="addValueByFunction">Function Value</option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                      {data?.valueType &&
                        <div className="w-100 mb-2">
                          <div className="my-2">
                            <InputGroup className="justify-content-center">
                              <InputGroup.Text
                                style={{
                                  padding: "5px 9.7px",
                                  fontSize: 10,
                                  borderRadius: 3,
                                }}
                              >
                                =
                              </InputGroup.Text>
                            </InputGroup>
                          </div>
                          <Form.Group className="w-100">
                            {data?.valueType === "addCustomValue" ?
                              <Form.Control
                                value={data.value}
                                onChange={(e) =>
                                  handleSelectNewColoumnChange(
                                    data.id,
                                    "value",
                                    e.target.value
                                  )
                                }
                                type="text"
                                placeholder="Enter Value"
                              />
                              : data?.valueType === "addColumnValue" ?
                                <Form.Select
                                  onChange={(e) =>
                                    handleSelectNewColoumnChange(
                                      data.id,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select Column for Value</option>
                                  {(primaryDataType === "Merge" || primaryDataType === "Append"
                                    ? uniqueColumnKey
                                    : columns
                                  )?.map((option, index) => (
                                    <option
                                      selected={data?.value === option.name}
                                      key={index}
                                      value={option?.name}
                                    >
                                      {option.name}
                                    </option>
                                  ))}
                                </Form.Select>
                                : data?.valueType === "addValueByFunction" &&
                                <Form.Select
                                  onChange={(e) =>
                                    handleSelectNewColoumnChange(
                                      data.id,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select Function</option>
                                  {functionList?.map((option, index) => (
                                    <option
                                      selected={data?.value === option._id}
                                      key={index}
                                      value={option?._id}
                                      className="text-capitalize"
                                    >
                                      {option.name} {`(${option.type} Function) `}
                                    </option>
                                  ))}
                                </Form.Select>
                            }
                          </Form.Group>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </Form.Group>
            ))
          ) : (
            <div className="hstack justify-content-center">No Columns</div>
          )}
        </Row>
      </div>
    </div>
  );
};

export default AddColumnToolsWithValue;
