import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { FaEye } from "react-icons/fa6";
import SplitPane from "react-split-pane";
import { useParams } from "react-router";
import UseToggle from "../../../../../Hooks/UseToggle";
import TrailDesignFilterData from "./TrailDesignFilterData";
import AddTrialDesignDetasets from "./AddTrialDesignDetasets";
import Pagination from "../../../../ETMF/Pagination/Pagination";
import { Col, Container, Row, Spinner, Table } from "react-bootstrap";
import TrailDesignTableProperties from "./TrailDesignTableProperties";
import { cdrgStudyServices } from "../../../../../Services/CDISC/cdrgStudyServices";

const TrialDesignDatasets = ({ onMouseDown, tableRef }) => {
  const { studyId } = useParams();
  const [title, setTitle] = useState("");
  const [loader, setLoader] = useState(false);
  const [columnData, setColumnData] = useState([]);
  const [trailDesign, setTrailDesign] = useState([]);
  const [description, setDescription] = useState("");
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [trailDesignId, setTrailDesignId] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [isOpenProperty, setIsOpenProperty] = useState(false);
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [fields, setFields] = useState([{ name: "", label: "" }]);
  const [isColumnDataVisible, setIsColumnDataVisible] = useState(false);

  const handleColumnDataClick = (columns) => {
    setColumnData(columns);
    setIsColumnDataVisible(true);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const getStudyTrailDesignDatasetData = async () => {
    let data = await cdrgStudyServices.getStudyTrailDesignDatasetData(studyId);
    setTrailDesign(data?.data);
  };

  const updateData = (item) => {
    setTrailDesignId(item);
    setIsFormOpen(true);
  };

  const deleteStudyTrailDesignDatasets = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Trails Design?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await cdrgStudyServices.deleteStudyTrailDesignDatasets(
          id,
          studyId
        );
        if (data?.statusCode === 200) {
          getStudyTrailDesignDatasetData();
        }
      }
    });
  };

  const cleanInputBox = () => {
    setTitle("");
    setTrailDesignId("");
    setDescription("");
    setFields([{ name: "", label: "" }]);
  };

  useEffect(() => {
    getStudyTrailDesignDatasetData();
  }, []);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = trailDesign?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(trailDesign?.length / itemsPerPage);

  return (
    <>
      <div
        className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}
      >
        <div
          className="hstack justify-content-between w-100 border-bottom toolbar"
          style={{ padding: "4.6px 9px" }}
        >
          <div className="hstack gap-2">
            <i
              title={`${isFormOpen ? "Close Form" : "Add New Metadata"}`}
              class={`fa-solid ${
                isFormOpen ? "fa-square-caret-left" : "fa-square-plus"
              } CP p-1 fontSize12`}
              onClick={() => setIsFormOpen(!isFormOpen)}
              style={{ borderRadius: 3 }}
            ></i>
            <div className="vr"></div>
            <i
              title="Table Properties"
              class="fa-solid fa-table-list CP p-1 fontSize12"
              onClick={setToggle}
              style={{ borderRadius: 3 }}
            ></i>
            <div className="vr"></div>
            <i
              title="Filter Table Data"
              class="fa-solid fa-filter CP p-1 fontSize12"
              onClick={setToggle1}
              style={{ borderRadius: 3 }}
            ></i>
          </div>
          <i
            class={`fa-solid ${isFullScreen ? "fa-minimize" : "fa-maximize"}`}
            title={!isFullScreen ? "Full Mode" : "Original Mode"}
            onClick={toggleFullScreen}
          ></i>
        </div>
        <div className="position-relative">
          <SplitPane
            split="horizontal"
            style={{
              height: isFullScreen
                ? "calc(100vh - 35px)"
                : "calc(100vh - 187px)",
            }}
            defaultSize={isOpenProperty ? "40%" : "100%"}
          >
            <SplitPane
              split="vertical"
              minSize={20}
              defaultSize={isFormOpen ? "20%" : "100%"}
            >
              {isFormOpen && (
                <AddTrialDesignDetasets
                  Show={!toggle}
                  Hide={setToggle}
                  Size={"md"}
                  studyId={studyId}
                  trailDesignId={trailDesignId}
                  setTrailDesignId={setTrailDesignId}
                  cleanInputBox={cleanInputBox}
                  fields={fields}
                  setFields={setFields}
                  title={title}
                  setTitle={setTitle}
                  description={description}
                  setDescription={setDescription}
                  getStudyTrailDesignDatasetData={
                    getStudyTrailDesignDatasetData
                  }
                />
              )}
              <>
                {loader ? (
                  <div className="hstack justify-content-center h-100">
                    <Spinner />
                  </div>
                ) : result?.length === 0 ? (
                  <div className="hstack justify-content-center h-100">
                    Data not found!
                  </div>
                ) : (
                  <>
                    <Container fluid>
                      <Row>
                        <Col
                          md={isColumnDataVisible ? 8 : 12}
                          className="p-0 overflow-auto"
                          style={{ height: "calc(100vh - 187px)" }}
                        >
                          <Table
                            id="resizable-table"
                            ref={tableRef}
                            striped
                            className="m-0 custom-table"
                          >
                            <thead className="thead-sticky">
                              <tr>
                                <th
                                  onMouseDown={(e) => onMouseDown(e, 0)}
                                  style={{ width: 40 }}
                                  className="text-center"
                                >
                                  Sr.
                                </th>
                                <th
                                  onMouseDown={(e) => onMouseDown(e, 1)}
                                  style={{ width: 150 }}
                                >
                                  Title
                                </th>
                                <th onMouseDown={(e) => onMouseDown(e, 2)}>
                                  Description
                                </th>
                                <th
                                  onMouseDown={(e) => onMouseDown(e, 3)}
                                  style={{ width: 75 }}
                                  className="text-center"
                                >
                                  Columns
                                </th>
                                <th
                                  onMouseDown={(e) => onMouseDown(e, 4)}
                                  style={{ width: 80 }}
                                  className="text-center"
                                >
                                  Update
                                </th>
                                <th
                                  onMouseDown={(e) => onMouseDown(e, 5)}
                                  style={{ width: 80 }}
                                  className="text-center"
                                >
                                  Delete
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {result?.map((item, index) => (
                                <tr
                                  key={index}
                                  onClick={() => {
                                    setHighlightedRow(index);
                                  }}
                                  className={`position-relative cursor-pointer ${
                                    highlightedRow === index && "activeMenu"
                                  }`}
                                >
                                  <td className="text-center">
                                    {index + startIndex + 1}
                                  </td>
                                  <td>{item.title}</td>
                                  <td className="text-wrap">
                                    {item.description}
                                  </td>
                                  <td
                                    className="cursor-pointer text-center"
                                    title="Update Acronys"
                                    onClick={() =>
                                      handleColumnDataClick(item?.columns)
                                    }
                                  >
                                    {<FaEye />}
                                  </td>
                                  <td className="text-center">
                                    <button
                                      onClick={() => updateData(item)}
                                      title="Update Trail Design"
                                      className="text-white border-success bg-success border-0"
                                      style={{ fontSize: 10, borderRadius: 3 }}
                                    >
                                      Update
                                    </button>
                                  </td>
                                  <td className="cursor-pointer text-center">
                                    <button
                                      className="text-white border-danger bg-danger border-0"
                                      style={{ fontSize: 10, borderRadius: 3 }}
                                      title="Delete Trail Design"
                                      onClick={() =>
                                        deleteStudyTrailDesignDatasets(item._id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          <div className="position-sticky bottom-0">
                            <Pagination
                              totalPage={totalPage}
                              pageNumber={pageNumber}
                              itemsPerPage={itemsPerPage}
                              totalItems={trailDesign?.length}
                              setPageNumber={setPageNumber}
                              setItemsPerPage={setItemsPerPage}
                            />
                          </div>
                        </Col>
                        {isColumnDataVisible && (
                          <Col
                            md={4}
                            className="p-0 overflow-auto border-start"
                            style={{ height: "calc(100vh - 187px)" }}
                          >
                            <Table
                              id="resizable-table"
                              ref={tableRef}
                              striped
                              className="m-0 custom-table"
                            >
                              <thead className="thead-sticky">
                                <tr>
                                  <th onMouseDown={(e) => onMouseDown(e, 0)}>
                                    Name
                                  </th>
                                  <th onMouseDown={(e) => onMouseDown(e, 1)}>
                                    Label
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {columnData?.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.name ? item.name : "-"}</td>
                                    <td>{item.label ? item.label : "-"}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Col>
                        )}
                      </Row>
                    </Container>
                  </>
                )}
              </>
            </SplitPane>
          </SplitPane>
        </div>
      </div>
      <TrailDesignTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
      <TrailDesignFilterData
        Show={!toggle1}
        Hide={setToggle1}
        Title={"Filter Trail design"}
      />
    </>
  );
};

export default TrialDesignDatasets;
