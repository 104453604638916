import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { cdrgStudyServices } from "../../../../../Services/CDISC/cdrgStudyServices";

const AddAppendixData = ({ version, setVersion, iETESTCD, setIETESTCD,  setCategory, category, setCriterion, criterion, cleanInputBox, studyId,
    getStudyAppendixIncExcData, appendixDataId, setAppendixDataId }) => {
    const [loader, setLoader] = useState(false);

    const addNewStudyAppendixIncExc = async () => {
        setLoader(true);
        let userData = {};
        userData.studyId = studyId
        userData.version = version;
        userData.category = category;
        userData.criterion = criterion;
        userData.IETESTCD = iETESTCD;
        let data = await cdrgStudyServices.addNewStudyAppendixIncExc(userData);
        if (data?.statusCode === 200) {
            getStudyAppendixIncExcData();
            cleanInputBox("");
            setLoader(false);
        }
        setLoader(false);
    };

    const handleUpdate = async () => {
        setLoader(true);
        let userData = {};
        userData.studyId = studyId
        userData.id = appendixDataId?._id
        userData.version = version;
        userData.category = category;
        userData.criterion = criterion;
        let data = await cdrgStudyServices.updateStudyAppendixIncExcData(userData);
        if (data?.statusCode === 200) {
            getStudyAppendixIncExcData();
            setAppendixDataId("");
            cleanInputBox("");
            setLoader(false);
        }
        setLoader(false);
    };

    useEffect(() => {
        if (appendixDataId) {
            setVersion(appendixDataId.version)
            setCategory(appendixDataId.category)
            setCriterion(appendixDataId.criterion)
            setIETESTCD(appendixDataId.IETESTCD)
        }
    }, [appendixDataId]);

    return (
        <div className="p-2">
            <Form.Group className="mb-2">
                <Form.Label className="fw-bold mb-1">
                    Version <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter Version"
                    value={version}
                    onChange={(e) => setVersion(e.target.value)}
                />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label className="fw-bold mb-1">
                    Category <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter Category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label className="fw-bold mb-1">
                IETESTCD <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter IETESTCD"
                    value={iETESTCD}
                    onChange={(e) => setIETESTCD(e.target.value)}
                />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label className="fw-bold mb-1">
                Criterion <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter Criterion"
                    value={criterion}
                    onChange={(e) => setCriterion(e.target.value)}
                />
            </Form.Group>
            <div className="pt-2 px-2">
                <div className="text-end footer_button">
                    <div className="text-end footer_button">
                        <button className="submitbutton px-4"
                            disabled={!version || !category || !iETESTCD || !criterion}
                            onClick={appendixDataId ? handleUpdate : addNewStudyAppendixIncExc}>
                            {loader ? (
                                <Spinner
                                    animation="border"
                                    style={{ width: 15, height: 15 }}
                                    className="me-2"
                                />
                            ) : null}
                            {appendixDataId ? "Update" : "Add"} Acronyms
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAppendixData;
