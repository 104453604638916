import React from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";

const AppendixTableProperties = ({
  Show,
  Hide,
  Title,
  selectAll,
  handleSelectAll,
  selectedNames,
  handleCheckboxChange,
  handleSubmitTableProperty,
  totalColumnLength,
}) => {
  return (
    <>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>{Title} <span className="fs-10">-[Check for hide]</span></Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form.Check
            type="checkbox"
            checked={selectAll || totalColumnLength === selectedNames?.length}
            onChange={handleSelectAll}
            label="Select All"
          />
          <Form.Check
            type="checkbox"
            name="Acronyms"
            checked={selectedNames?.includes("Acronyms")}
            onChange={() => handleCheckboxChange("Acronyms")}
            id="edit_label"
            label="Acronyms"
          />
           <Form.Check
            type="checkbox"
            name="Translation"
            checked={selectedNames?.includes("Translation")}
            onChange={() => handleCheckboxChange("Translation")}
            id="edit_label"
            label="Translation"
          />
          <Form.Check
            type="checkbox"
            name="Status"
            checked={selectedNames?.includes("Status")}
            onChange={() => handleCheckboxChange("Status")}
            id="edit_label"
            label="Status"
          />
          <div className="border-top text-end my-2 pt-2">
            <button
              onClick={() => handleSubmitTableProperty()}
              className="updateProfile"
            >
              <FaCheck /> Apply
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AppendixTableProperties;
