import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import humanBodyImage from "../../../../../img/humanbodyMan2.png";
import UseToggle from "../../../../../Hooks/UseToggle";

const jointData = [
  {
    id: 1,
    x: 124,
    y: 34.5,
    label: "Left Temporomandibular",
    value: "left_temporomandibular",
  },
  {
    id: 2,
    x: 165,
    y: 34.4,
    label: "Right Temporomandibular",
    value: "right_temporomandibular",
  },
  {
    id: 3,
    x: 104.5,
    y: 74,
    label: "Left Acromioclavicular",
    value: "left_acromioclavicular",
  },
  {
    id: 4,
    x: 186,
    y: 74,
    label: "Right Acromioclavicular",
    value: "right_acromioclavicular",
  },
  {
    id: 5,
    x: 127.5,
    y: 78.5,
    label: "Left Sternoclavicular",
    value: "left_sternoclavicular",
  },
  {
    id: 6,
    x: 157,
    y: 78.5,
    label: "Right Sternoclavicular",
    value: "right_sternoclavicular",
  },
  {
    id: 7,
    x: 92,
    y: 86,
    label: "Left Shoulder",
    value: "left_shoulder",
  },
  {
    id: 8,
    x: 188,
    y: 87,
    label: "Right Shoulder",
    value: "right_shoulder",
  },
  {
    id: 9,
    x: 88,
    y: 142,
    label: "Left Elbow",
    value: "left_elbow",
  },
  {
    id: 10,
    x: 197,
    y: 142,
    label: "Right Elbow",
    value: "right_elbow",
  },
  {
    id: 11,
    x: 32,
    y: 201,
    label: "Left Hand Wrist",
    value: "left_hand_wrist",
  },
  {
    id: 12,
    x: 245,
    y: 201,
    label: "Right Hand Wrist",
    value: "right_hand_wrist",
  },
  {
    id: 13,
    x: 119,
    y: 281.5,
    label: "Left Leg Knee",
    value: "left_leg_knee",
  },
  {
    id: 14,
    x: 165,
    y: 281,
    label: "Right Leg Knee",
    value: "right_leg_knee",
  },
  {
    id: 15,
    x: 102,
    y: 329,
    label: "Left Leg Ankle",
    value: "left_leg_ankle",
  },
  {
    id: 16,
    x: 183,
    y: 326,
    label: "Right Leg Ankle",
    value: "right_leg_ankle",
  },
  {
    id: 17,
    x: 100,
    y: 371,
    label: "Left Leg Tarsus",
    value: "left_leg_tarsus",
  },
  {
    id: 18,
    x: 186,
    y: 369,
    label: "Right Leg Tarsus",
    value: "right_leg_tarsus",
  },
  {
    id: 19,
    x: 71,
    y: 226,
    label: "Left Hand Metacarpophalangeal - I",
    value: "left_hand_metacarpophalangeal_i",
  },
  {
    id: 20,
    x: 54,
    y: 250,
    label: "Left Hand Metacarpophalangeal - II",
    value: "left_hand_metacarpophalangeal_ii",
  },
  {
    id: 21,
    x: 40,
    y: 250,
    label: "Left Hand Metacarpophalangeal - III",
    value: "left_hand_metacarpophalangeal_iii",
  },
  {
    id: 22,
    x: 27,
    y: 245,
    label: "Left Hand Metacarpophalangeal - IV",
    value: "left_hand_metacarpophalangeal_iv",
  },
  {
    id: 23,
    x: 13,
    y: 241,
    label: "Left Hand Metacarpophalangeal - V",
    value: "left_hand_metacarpophalangeal_v",
  },
  {
    id: 24,
    x: 211,
    y: 226,
    label: "Right Hand Metacarpophalangeal - I",
    value: "right_hand_metacarpophalangeal_i",
  },
  {
    id: 25,
    x: 228,
    y: 249,
    label: "Right Hand Metacarpophalangeal - II",
    value: "right_hand_metacarpophalangeal_ii",
  },
  {
    id: 26,
    x: 243,
    y: 250,
    label: "Right Hand Metacarpophalangeal - III",
    value: "right_hand_metacarpophalangeal_iii",
  },
  {
    id: 27,
    x: 255,
    y: 245,
    label: "Right Hand Metacarpophalangeal - IV",
    value: "right_hand_metacarpophalangeal_iv",
  },
  {
    id: 28,
    x: 268,
    y: 241,
    label: "Right Hand Metacarpophalangeal - V",
    value: "right_hand_metacarpophalangeal_v",
  },
  {
    id: 29,
    x: 81,
    y: 239,
    label: "Left Hand Proximal Interphalangeal - I",
    value: "left_hand_proximal_interphalangeal_i",
  },
  {
    id: 30,
    x: 59,
    y: 266,
    label: "Left Hand Proximal Interphalangeal - II",
    value: "left_hand_proximal_interphalangeal_ii",
  },
  {
    id: 31,
    x: 40,
    y: 270,
    label: "Left Hand Proximal Interphalangeal - III",
    value: "left_hand_proximal_interphalangeal_iii",
  },
  {
    id: 32,
    x: 25,
    y: 266,
    label: "Left Hand Proximal Interphalangeal - IV",
    value: "left_hand_proximal_interphalangeal_iv",
  },
  {
    id: 33,
    x: 9,
    y: 254,
    label: "Left Hand Proximal Interphalangeal - V",
    value: "left_hand_proximal_interphalangeal_v",
  },
  {
    id: 34,
    x: 201,
    y: 238,
    label: "Right Hand Proximal Interphalangeal - I",
    value: "right_hand_proximal_interphalangeal_i",
  },
  {
    id: 35,
    x: 224,
    y: 265,
    label: "Right Hand Proximal Interphalangeal - II",
    value: "right_hand_proximal_interphalangeal_ii",
  },
  {
    id: 36,
    x: 243,
    y: 269,
    label: "Right Hand Proximal Interphalangeal - III",
    value: "right_hand_proximal_interphalangeal_iii",
  },
  {
    id: 37,
    x: 257,
    y: 265,
    label: "Right Hand Proximal Interphalangeal - IV",
    value: "right_hand_proximal_interphalangeal_iv",
  },
  {
    id: 38,
    x: 273,
    y: 254,
    label: "Right Hand Proximal Interphalangeal - V",
    value: "right_hand_proximal_interphalangeal_v",
  },
  {
    id: 39,
    x: 60,
    y: 280,
    label: "Left Hand Distal Interphalangeal - I",
    value: "left_hand_distal_interphalangeal_i",
  },
  {
    id: 40,
    x: 38,
    y: 286,
    label: "Left Hand Distal Interphalangeal - II",
    value: "left_hand_distal_interphalangeal_ii",
  },
  {
    id: 41,
    x: 24,
    y: 277,
    label: "Left Hand Distal Interphalangeal - III",
    value: "left_hand_distal_interphalangeal_iii",
  },
  {
    id: 42,
    x: 7,
    y: 265,
    label: "Left Hand Distal Interphalangeal - IV",
    value: "left_hand_distal_interphalangeal_iv",
  },
  {
    id: 43,
    x: 223,
    y: 280,
    label: "Right Hand Distal Interphalangeal - I",
    value: "right_hand_distal_interphalangeal_i",
  },
  {
    id: 44,
    x: 244,
    y: 286,
    label: "Right Hand Distal Interphalangeal - II",
    value: "right_hand_distal_interphalangeal_ii",
  },
  {
    id: 45,
    x: 259,
    y: 277,
    label: "Right Hand Distal Interphalangeal - III",
    value: "right_hand_distal_interphalangeal_iii",
  },
  {
    id: 46,
    x: 275,
    y: 265,
    label: "Right Hand Distal Interphalangeal - IV",
    value: "right_hand_distal_interphalangeal_iv",
  },
  {
    id: 47,
    x: 125,
    y: 461,
    label: "Left Leg Toe proximal interphalangeal - I",
    value: "left_leg_toe_proximal_interphalangeal_i",
  },
  {
    id: 48,
    x: 106,
    y: 460,
    label: "Left Leg Toe proximal interphalangeal - II",
    value: "left_leg_toe_proximal_interphalangeal_ii",
  },
  {
    id: 49,
    x: 93,
    y: 455,
    label: "Left Leg Toe proximal interphalangeal - III",
    value: "left_leg_toe_proximal_interphalangeal_iii",
  },
  {
    id: 50,
    x: 81,
    y: 453,
    label: "Left Leg Toe proximal interphalangeal - IV",
    value: "left_leg_toe_proximal_interphalangeal_iv",
  },
  {
    id: 51,
    x: 69,
    y: 449,
    label: "Left Leg Toe proximal interphalangeal - V",
    value: "left_leg_toe_proximal_interphalangeal_v",
  },
  {
    id: 52,
    x: 165,
    y: 458,
    label: "Right Leg Toe proximal interphalangeal - I",
    value: "right_leg_toe_proximal_interphalangeal_i",
  },
  {
    id: 53,
    x: 184,
    y: 458,
    label: "Right Leg Toe proximal interphalangeal - II",
    value: "right_leg_toe_proximal_interphalangeal_ii",
  },
  {
    id: 54,
    x: 197,
    y: 452,
    label: "Right Leg Toe proximal interphalangeal - III",
    value: "right_leg_toe_proximal_interphalangeal_iii",
  },
  {
    id: 55,
    x: 210,
    y: 449,
    label: "Right Leg Toe proximal interphalangeal - IV",
    value: "right_leg_toe_proximal_interphalangeal_iv",
  },
  {
    id: 56,
    x: 222,
    y: 446,
    label: "Right Leg Toe proximal interphalangeal - V",
    value: "right_leg_toe_proximal_interphalangeal_v",
  },
  {
    id: 57,
    x: 126,
    y: 448,
    label: "Left leg metatarsophalangeal - I",
    value: "left_leg_metatarsophalangeal_i",
  },
  {
    id: 58,
    x: 106,
    y: 443,
    label: "Left leg metatarsophalangeal - II",
    value: "left_leg_metatarsophalangeal_ii",
  },
  {
    id: 59,
    x: 93,
    y: 440,
    label: "Left leg metatarsophalangeal - III",
    value: "left_leg_metatarsophalangeal_iii",
  },
  {
    id: 60,
    x: 80,
    y: 437,
    label: "Left leg metatarsophalangeal - IV",
    value: "left_leg_metatarsophalangeal_iv",
  },
  {
    id: 61,
    x: 67,
    y: 436,
    label: "Left leg metatarsophalangeal - V",
    value: "left_leg_metatarsophalangeal_v",
  },
  {
    id: 62,
    x: 165,
    y: 445,
    label: "Right leg metatarsophalangeal - I",
    value: "right_leg_metatarsophalangeal_i",
  },
  {
    id: 63,
    x: 185,
    y: 440,
    label: "Right leg metatarsophalangeal - II",
    value: "right_leg_metatarsophalangeal_ii",
  },
  {
    id: 64,
    x: 198,
    y: 437,
    label: "Right leg metatarsophalangeal - III",
    value: "right_leg_metatarsophalangeal_iii",
  },
  {
    id: 65,
    x: 210,
    y: 434,
    label: "Right leg metatarsophalangeal - IV",
    value: "right_leg_metatarsophalangeal_iv",
  },
  {
    id: 66,
    x: 223,
    y: 434,
    label: "Right leg metatarsophalangeal - V",
    value: "right_leg_metatarsophalangeal_v",
  },
];

const HumanBodyData = ({ swallonJoint }) => {
  const { toggle, setToggle } = UseToggle();
  const [showReason, setShowReason] = useState(false);
  const [selectedJoint, setSelectedJoint] = useState(null);

  const handleOptionChange = (e) => {
    if (e.target.id === "inline-radio-Not Done") {
      setShowReason(true);
    } else {
      setShowReason(false);
    }
  };

  const openModal = (joint) => {
    setSelectedJoint(joint);
    setToggle();
  };

  const closeModal = () => {
    setSelectedJoint(null);
    setShowReason(false);
    setToggle(false);
  };

  const swallonJointList = swallonJoint?.map((item) => item.value);

  return (
    <div style={{ position: "relative" }}>
      <img src={humanBodyImage} alt="Human Body" height={500} width={300} />
      {jointData?.map((joint) => (
        swallonJointList?.includes(joint?.value) ? "" :
          <div
            key={joint.id}
            onClick={() => openModal(joint)}
            style={{
              position: "absolute",
              top: `${joint.y}px`,
              left: `${joint.x}px`,
              width:
                joint.id === 1 || joint.id === 2
                  ? "17px"
                  : joint.id === 5 ||
                    joint.id === 6 ||
                    joint.id === 13 ||
                    joint.id === 14 ||
                    joint.id === 15 ||
                    joint.id === 16 ||
                    joint.id === 17 ||
                    joint.id === 18
                    ? "20px"
                    : joint.id === 7 || joint.id === 8
                      ? "24.4px"
                      : joint.id === 9 || joint.id === 10
                        ? "19px"
                        : joint.id === 11 || joint.id === 12
                          ? "21px"
                          : "15.5px",
              height:
                joint.id === 1 || joint.id === 2
                  ? "17px"
                  : joint.id === 5 || joint.id === 6
                    ? "18px"
                    : joint.id === 7 || joint.id === 8
                      ? "23px"
                      : joint.id === 9 ||
                        joint.id === 10 ||
                        joint.id === 17 ||
                        joint.id === 18
                        ? "19px"
                        : joint.id === 11 ||
                          joint.id === 12 ||
                          joint.id === 13 ||
                          joint.id === 14 ||
                          joint.id === 15 ||
                          joint.id === 16
                          ? "20px"
                          : "15.5px",
              borderRadius: "50%",
              cursor: "pointer",
              backgroundColor: "var(--blue)",
            }}
            title={joint.label}
          ></div>
      ))}

      {selectedJoint && (
        <Modal show={toggle} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title> {selectedJoint?.label}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: 8 }}>
            <div className="p-1">
              <p className="m-1" style={{ fontWeight: 500, fontSize: 14 }}>
                Information About {selectedJoint?.label}
              </p>
              <Form>
                {["radio"]?.map((type) => (
                  <div key={`inline-${type}`} className="mb-1 radioMap">
                    <Form.Check
                      label="Present"
                      name="group1"
                      type={type}
                      id={`inline-${type}-Present`}
                      onChange={handleOptionChange}
                    />
                    <Form.Check
                      label="Absent"
                      name="group1"
                      type={type}
                      id={`inline-${type}-Absent`}
                      onChange={handleOptionChange}
                    />
                    <Form.Check
                      label="Not Done"
                      name="group1"
                      type={type}
                      id={`inline-${type}-Not Done`}
                      onChange={handleOptionChange}
                    />
                    <Form.Check
                      label="Not Applicable"
                      name="group1"
                      type={type}
                      id={`inline-${type}-Not Applicable`}
                      onChange={handleOptionChange}
                    />
                  </div>
                ))}
                {showReason && (
                  <div className="mt-1">
                    <Form.Label style={{ fontSize: 14, fontWeight: 500 }}>
                      Reason For Not Done
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Please specify the reason..."
                    />
                  </div>
                )}
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn submitbutton me-2"
              // onClick={closeModal}
              onClick={() => setToggle(false)}
              style={{ padding: "3px 20px 3px" }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default HumanBodyData;
