import React, { useState } from "react";
import Moment from "react-moment";
import { useParams } from "react-router";
import { Button, Table } from "react-bootstrap";
import { ContextMenuTrigger } from "react-contextmenu";
import UseAddTabs from "../../../../../Hooks/UseAddTabs";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { UseCrfViewContext } from "../../../../../Contexts/UseCrfViewContext";
import { UseStudySubjectContext } from "../../../../../Contexts/UseStudySubjectContext";
import { studyOnboardingService } from "../../../../../Services/studyServices/studySitesServices";
import { clientLevelFormServices } from "../../../../../Services/formServices/clientLevelFormServices";

const StudyEventTableData = ({
  hideColumn,
  studyDynamicColumn,
  result,
  handleSubmit3,
  seIdGenerateQuery,
  setIdReviewRowTable,
  getStudyRightSiteData,
  setIdUpdateRowTable,
  setUpdatedType,
  setIsSectionVisible,
  onMouseDown,
  tableRef,
  totalItems,
  studyDynamicRow,
  setStudyDynamicRow,
  enableDrag,
  viewTypeData,
}) => {
  const { studyId } = useParams();
  let StudyMode = JSON.parse(localStorage.getItem("StudyMode"));

  const { addVisitsCrfViewTab, addStudyVisitsFormTab } = UseAddTabs();

  const getStatusColor = (status) => {
    switch (status) {
      case "open":
        return "red";
      case "cancel":
        return "grey";
      case "closed":
        return "green";
      case "inprogress":
        return "orange";
      default:
        return "black";
    }
  };
  const {
    setSelectedStudyVisitsId,
    setSelectedStudyVisitsInitiateId,
    setSelectedStudyVisitsForm,
  } = UseStudySubjectContext();

  const {
    setStudyUniqueId,
    currentPage,
    setCount,
    limit,
    setFormType,
    setFormUniqueId,
    setVisitFormType,
    setDataEntryFormMode,
    setQueryGenerateEndpoint,
    setGetTicketsEndPoint,
    setGetQueryListEndPoint,
    setReviewHistoryEndpoint,
    setFormFieldHistoryEndpoint,
    setReviewFormFieldEndpoint,
    setQueryReplyEndpoint,
    setQueryCommentEndpoint,
    setUpdateTicketStatusEndPoint,
    setDataEntryLockFreezeFormByIdEndPoint,
    setGetGlobalQueryStatusEndPoint,
    setDataEnteriesValueShow,
    setStudyDynamicForm,
    setRemoveNevigation,
    setStudyVisitFormStateId,
  } = UseCrfViewContext();

  const addCrfViewTabs = () => {
    addVisitsCrfViewTab();
    setDataEntryFormMode("initiateMode");
    setFormType("initiateVisitsForm");
    setDataEntryLockFreezeFormByIdEndPoint(
      "getVisitsDataEntryLockFreezeFormByFormField"
    );
    setDataEnteriesValueShow("getdataEntryFormById");
    setQueryGenerateEndpoint("DataEntryQueryGenerator");
    setGetTicketsEndPoint("getDataEntryTickets");
    setGetQueryListEndPoint("getDataEntryQuery");
    setQueryReplyEndpoint("DataEntryQueryReply");
    setQueryCommentEndpoint("DataEntryQueryComment");
    setReviewHistoryEndpoint("getDataEntryFormFieldReviewHistoryByID");
    setReviewFormFieldEndpoint("reviewDataEntryFormField");
    setUpdateTicketStatusEndPoint("DataEntryUpdateTicketStatus");
    setGetGlobalQueryStatusEndPoint("getDataEntryTicketStatus");

    setFormFieldHistoryEndpoint("getDataEntryFormFieldValueHistoryById");
  };

  const getStudyVisitInitiateForm = async () => {
    let data;
    data = await clientLevelFormServices.getStudyLevelFormData(
      studyId,
      currentPage,
      limit
    );
    let finalData = data?.data?.sort().reverse();
    setStudyDynamicForm(finalData);
    setCount(Math.ceil(data?.count / limit));
  };

  const viewStudyVisitForms = (item) => {
    addStudyVisitsFormTab();
    setStudyVisitFormStateId(item?.studyUniqueId);
    setSelectedStudyVisitsId(item?.visitIdLabel);
    setSelectedStudyVisitsInitiateId("");
  };

  const handleClickData = (e, id, studyUniqueId, visitId) => {
    setFormUniqueId(id);
    setVisitFormType("regular");
    setFormType("initiateVisitsForm");
    setStudyUniqueId(studyUniqueId);
    getStudyVisitInitiateForm();
    setSelectedStudyVisitsInitiateId(visitId);
    setSelectedStudyVisitsId("");
    setSelectedStudyVisitsForm("");
    setRemoveNevigation(false);
  };

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (index, item) => {
    setSelectedRow(index);
  };

  const studyPermissionData = JSON.parse(
    localStorage.getItem("studyPermissionData")
  );

  const isHighlightable = () =>
    studyPermissionData?.createDatabaseSites ||
    studyPermissionData?.viewMetaDataVerification ||
    studyPermissionData?.createDatabaseViewSiteManagement ||
    studyPermissionData?.createDatabaseVerifySiteManagement ||
    studyPermissionData?.dataCollectionDBPMode ||
    studyPermissionData?.dataCollectioninDVMode ||
    studyPermissionData?.siteIdForLive?.length > 0 ||
    studyPermissionData?.siteIdForQC?.length > 0 ||
    studyPermissionData?.siteIdForSDVLive?.length > 0 ||
    studyPermissionData?.siteIdForSDVQC?.length > 0;

  let totalLength = studyDynamicColumn?.length || 0;

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(studyDynamicRow);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const convertedData = items.map((item, index) => ({
      visitId: item.uniqueId,
      index: index,
    }));

    let userData = {};
    userData.indexing = convertedData;
    userData.moduleName = "studyevent";
    userData.studyId = studyId;
    await studyOnboardingService.addModuleOrder(userData);

    setStudyDynamicRow(items);
  };

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="table">
          {(provided) => (
            <Table
              ref={(element) => {
                provided.innerRef(element);
                if (tableRef) {
                  tableRef.current = element;
                }
              }}
              {...provided.droppableProps}
              className="m-0 custom-table"
              hover
              striped
            >
              <thead className="position-sticky z-1">
                <tr>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 0)}
                    style={{ width: 45 }}
                  >
                    S No.
                  </th>
                  {hideColumn?.includes("Visit Id") && (
                    <th onMouseDown={(e) => onMouseDown(e, 1)}>Visit Id</th>
                  )}
                  {studyDynamicColumn &&
                    studyDynamicColumn?.map(({ name }, i) => (
                      <th onMouseDown={(e) => onMouseDown(e, i + 2)}>{name}</th>
                    ))}
                  {hideColumn?.includes("Repeat") && (
                    <th onMouseDown={(e) => onMouseDown(e, totalLength + 2)}>
                      Repeat
                    </th>
                  )}
                  {hideColumn?.includes("Time Point Id") && (
                    <th onMouseDown={(e) => onMouseDown(e, totalLength + 2)}>
                      Time Point Id
                    </th>
                  )}
                  {hideColumn?.includes("Repeat Number") && (
                    <th onMouseDown={(e) => onMouseDown(e, totalLength + 2)}>
                      Repeat Number
                    </th>
                  )}
                  {hideColumn?.includes("Initiate") && (
                    <th onMouseDown={(e) => onMouseDown(e, totalLength + 2)}>
                      Initiate
                    </th>
                  )}
                  {hideColumn?.includes("Initiate Status") && (
                    <th onMouseDown={(e) => onMouseDown(e, totalLength + 2)}>
                      Initiate Status
                    </th>
                  )}
                  {hideColumn?.includes("Status") && (
                    <th onMouseDown={(e) => onMouseDown(e, totalLength + 2)}>
                      Status
                    </th>
                  )}
                  {hideColumn?.includes("Initiation") && (
                    <th onMouseDown={(e) => onMouseDown(e, totalLength + 2)}>
                      Initiation
                    </th>
                  )}
                  {hideColumn?.includes("Event Form") && (
                    <th onMouseDown={(e) => onMouseDown(e, totalLength + 2)}>
                      Event Form
                    </th>
                  )}
                  {hideColumn?.includes("Query Status") &&
                    viewTypeData === "ctms" && (
                      <th onMouseDown={(e) => onMouseDown(e, totalItems + 2)}>
                        Query Status
                      </th>
                    )}
                  {hideColumn?.includes("Query Date | Time") &&
                    viewTypeData === "ctms" && (
                      <th onMouseDown={(e) => onMouseDown(e, totalItems + 2)}>
                        Query Date | Time
                      </th>
                    )}
                  {viewTypeData === "ctms" && enableDrag && (
                    <th
                      className="text-center"
                      style={{ width: 80 }}
                      onMouseDown={(e) => onMouseDown(e, totalItems + 2)}
                    >
                      Update
                    </th>
                  )}
                  {viewTypeData === "ctms" && enableDrag && (
                    <th
                      className="text-center"
                      style={{ width: 80 }}
                      onMouseDown={(e) => onMouseDown(e, totalItems + 2)}
                    >
                      Delete
                    </th>
                  )}
                  {viewTypeData === "ctms" && StudyMode === "DV Mode" && (
                    <th
                      className="text-center"
                      style={{ width: 80 }}
                      onMouseDown={(e) => onMouseDown(e, totalItems + 2)}
                    >
                      Siganture
                    </th>
                  )}
                  {viewTypeData === "ctms" && StudyMode === "DV Mode" && (
                    <th
                      className="text-center"
                      style={{ width: 120 }}
                      onMouseDown={(e) => onMouseDown(e, totalItems + 2)}
                    >
                      Generate Query
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) =>
                  enableDrag ? (
                    <Draggable
                      key={item.studyUniqueId}
                      draggableId={item.studyUniqueId}
                      index={index}
                    >
                      {(provided) => (
                        <tr
                          key={index}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          style={{
                            cursor: "grab",
                            ...provided.draggableProps.style,
                            background: selectedRow === index ? "#ccd6dd" : "",
                          }}
                          className={
                            isHighlightable() &&
                            selectedRow === item.studyUniqueId &&
                            "highlighted"
                          }
                          onClick={() => {
                            handleRowClick(index, item?.items);
                            if (isHighlightable() || viewTypeData === "ctms") {
                              getStudyRightSiteData(item.studyUniqueId);
                            }
                          }}
                        >
                          <td onClick={() => setIsSectionVisible(true)}>
                            {index + 1}
                          </td>
                          {hideColumn?.includes("Visit Id") && (
                            <td onClick={() => setIsSectionVisible(true)}>
                              <ContextMenuTrigger
                                id={`eventForm_${index}`}
                                collect={() => ({
                                  id: `eventForm_${index}` + 1,
                                })}
                              >
                                {item.visitIdLabel}
                              </ContextMenuTrigger>
                            </td>
                          )}
                          {Object?.values(item?.row).map((data, index) => (
                            <td
                              onClick={() => {
                                if (
                                  isHighlightable() ||
                                  viewTypeData === "ctms"
                                ) {
                                  getStudyRightSiteData(item.studyUniqueId);
                                  setIsSectionVisible(true);
                                } else {
                                  setIsSectionVisible(true);
                                }
                              }}
                              key={index}
                            >
                              <ContextMenuTrigger
                                id={`eventForm_${index}`}
                                collect={() => ({
                                  id: `eventForm_${index}` + 1,
                                })}
                              >
                                {data.valueData}
                              </ContextMenuTrigger>
                            </td>
                          ))}
                          {hideColumn?.includes("Repeat") && (
                            <td
                              className="text-capitalize"
                              onClick={() => setIsSectionVisible(true)}
                            >
                              <ContextMenuTrigger
                                id={`eventForm_${index}`}
                                collect={() => ({
                                  id: `eventForm_${index}` + 1,
                                })}
                              >
                                {item.repeat}
                              </ContextMenuTrigger>
                            </td>
                          )}
                          {hideColumn?.includes("Time Point Id") && (
                            <td onClick={() => setIsSectionVisible(true)}>
                              <ContextMenuTrigger
                                id={`eventForm_${index}`}
                                collect={() => ({
                                  id: `eventForm_${index}` + 1,
                                })}
                              >
                                {item.timePointId}
                              </ContextMenuTrigger>
                            </td>
                          )}
                          {hideColumn?.includes("Repeat Number") && (
                            <td>
                              <ContextMenuTrigger
                                id={`eventForm_${index}`}
                                collect={() => ({
                                  id: `eventForm_${index}` + 1,
                                })}
                              >
                                {item.repeatNumber || 0}
                              </ContextMenuTrigger>
                            </td>
                          )}
                          {hideColumn?.includes("Initiate") && (
                            <td onClick={() => setIsSectionVisible(true)}>
                              <ContextMenuTrigger
                                id={`eventForm_${index}`}
                                collect={() => ({
                                  id: `eventForm_${index}` + 1,
                                })}
                              >
                                {item?.initiate === "afterFirstVisits"
                                  ? "After First Visit"
                                  : item?.initiate === "anyTime"
                                  ? "Any Time"
                                  : item?.initiate}
                              </ContextMenuTrigger>
                            </td>
                          )}
                          {hideColumn?.includes("Initiate Status") && (
                            <td>
                              <ContextMenuTrigger
                                id={`eventForm_${index}`}
                                collect={() => ({
                                  id: `eventForm_${index}` + 1,
                                })}
                              >
                                {item?.initiated ? (
                                  <div>Initiated</div>
                                ) : (
                                  <div>Not Initiate</div>
                                )}
                              </ContextMenuTrigger>
                            </td>
                          )}
                          {hideColumn?.includes("Status") && (
                            <td>
                              <ContextMenuTrigger
                                id={`eventForm_${index}`}
                                collect={() => ({
                                  id: `eventForm_${index}` + 1,
                                })}
                              >
                                {item?.status ? "Completed" : "Ongoing"}
                              </ContextMenuTrigger>
                            </td>
                          )}
                          {hideColumn?.includes("Initiation") && (
                            <td>
                              <ContextMenuTrigger
                                id={`eventForm_${index}`}
                                collect={() => ({
                                  id: `eventForm_${index}` + 1,
                                })}
                              >
                                {item?.initiated ? (
                                  <Button
                                    onClick={(e) => {
                                      handleClickData(
                                        e,
                                        item?.formUniqueId,
                                        item?.studyUniqueId,
                                        item?.visitIdLabel
                                      );
                                      addCrfViewTabs();
                                    }}
                                    style={{
                                      fontSize: 10,
                                      borderRadius: 3,
                                      padding: "2px 8px",
                                    }}
                                    className="text-white border-success bg-success border"
                                  >
                                    View Initiated Event
                                  </Button>
                                ) : (
                                  <Button
                                    disabled={!item?.formEnable}
                                    onClick={(e) => {
                                      handleClickData(
                                        e,
                                        item?.formUniqueId,
                                        item?.studyUniqueId,
                                        item?.visitIdLabel
                                      );
                                      addCrfViewTabs();
                                    }}
                                    style={{
                                      fontSize: 10,
                                      borderRadius: 3,
                                      padding: "2px 8px",
                                    }}
                                    className={`${
                                      item?.formEnable
                                        ? "text-muted border-warning bg-warning"
                                        : "text-muted border-secondary bg-light"
                                    }`}
                                  >
                                    Initiate Event
                                  </Button>
                                )}
                              </ContextMenuTrigger>
                            </td>
                          )}
                          {hideColumn?.includes("Event Form") && (
                            <td>
                              <ContextMenuTrigger
                                id={`eventForm_${index}`}
                                collect={() => ({
                                  id: `eventForm_${index}` + 1,
                                })}
                              >
                                <Button
                                  disabled={!item?.initiated}
                                  onClick={(e) => viewStudyVisitForms(item)}
                                  style={{
                                    fontSize: 10,
                                    borderRadius: 3,
                                    padding: "2px 8px",
                                  }}
                                  className={
                                    item?.initiated
                                      ? "text-white border-success bg-success border"
                                      : "text-muted border-secondary bg-light"
                                  }
                                >
                                  View Event Form
                                </Button>
                              </ContextMenuTrigger>
                            </td>
                          )}
                          {hideColumn?.includes("Query Status") &&
                            viewTypeData === "ctms" && (
                              <td onClick={() => setIsSectionVisible(true)}>
                                <ContextMenuTrigger
                                  id={`eventForm_${index}`}
                                  collect={() => ({
                                    id: `eventForm_${index}` + 1,
                                  })}
                                >
                                  {item?.queryStatus ? (
                                    <div
                                      className="text-capitalize"
                                      style={{
                                        color: getStatusColor(
                                          item?.queryStatus
                                        ),
                                      }}
                                    >
                                      {item?.queryStatus}
                                    </div>
                                  ) : (
                                    "No Query"
                                  )}
                                </ContextMenuTrigger>
                              </td>
                            )}
                          {hideColumn?.includes("Query Date | Time") &&
                            viewTypeData === "ctms" && (
                              <td onClick={() => setIsSectionVisible(true)}>
                                <ContextMenuTrigger
                                  id={`eventForm_${index}`}
                                  collect={() => ({
                                    id: `eventForm_${index}` + 1,
                                  })}
                                >
                                  {item?.queryStatus === "open" ? (
                                    <Moment
                                      format="DD MMM YYYY | HH:mm"
                                      withTitle
                                    >
                                      {item?.queryDateTime}
                                    </Moment>
                                  ) : (
                                    "N/A"
                                  )}
                                </ContextMenuTrigger>
                              </td>
                            )}
                          {viewTypeData === "ctms" &&
                            StudyMode === "DBP MODE" && (
                              <>
                                <td className="text-center">
                                  <button
                                    onClick={() => {
                                      setIdUpdateRowTable(
                                        item?._id,
                                        item?.studyUniqueId,
                                        item?.row,
                                        item?.visitIdLabel,
                                        item?.repeat,
                                        item?.order,
                                        item?.initiate,
                                        item?.timePointId
                                      );
                                      setUpdatedType("editRow");
                                    }}
                                    className="text-white border-success bg-success border"
                                    style={{
                                      fontSize: 10,
                                      borderRadius: 3,
                                      padding: "2px 8px",
                                    }}
                                  >
                                    Update
                                  </button>
                                </td>
                                <td className="text-center">
                                  <button
                                    onClick={(e) => handleSubmit3(e, item?._id)}
                                    className="text-white border-danger bg-danger border"
                                    style={{
                                      fontSize: 10,
                                      borderRadius: 3,
                                      padding: "2px 8px",
                                    }}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </>
                            )}
                        </tr>
                      )}
                    </Draggable>
                  ) : (
                    <tr
                      key={index}
                      onClick={(e) => {
                        handleRowClick(index, item?.items);
                      }}
                      className="CP"
                      style={{
                        background: selectedRow === index ? "#ccd6dd" : "",
                      }}
                    >
                      <td
                        onClick={() => {
                          if (isHighlightable() || viewTypeData === "ctms") {
                            getStudyRightSiteData(item.studyUniqueId);
                            setIsSectionVisible(true);
                          } else {
                            setIsSectionVisible(true);
                          }
                        }}
                      >
                        {index + 1}
                      </td>
                      {hideColumn?.includes("Visit Id") && (
                        <td
                          onClick={() => {
                            if (isHighlightable() || viewTypeData === "ctms") {
                              getStudyRightSiteData(item.studyUniqueId);
                              setIsSectionVisible(true);
                            } else {
                              setIsSectionVisible(true);
                            }
                          }}
                        >
                          {item.visitIdLabel}
                        </td>
                      )}
                      {Object?.values(item?.row).map((data, index) => (
                        <td
                          onClick={() => {
                            if (isHighlightable() || viewTypeData === "ctms") {
                              getStudyRightSiteData(item.studyUniqueId);
                              setIsSectionVisible(true);
                            } else {
                              setIsSectionVisible(true);
                            }
                          }}
                          key={index}
                        >
                          {data.valueData}
                        </td>
                      ))}
                      {hideColumn?.includes("Repeat") && (
                        <td
                          onClick={() => {
                            if (isHighlightable() || viewTypeData === "ctms") {
                              getStudyRightSiteData(item.studyUniqueId);
                              setIsSectionVisible(true);
                            } else {
                              setIsSectionVisible(true);
                            }
                          }}
                          className="text-capitalize"
                        >
                          {item.repeat}
                        </td>
                      )}
                      {hideColumn?.includes("Time Point Id") && (
                        <td
                          onClick={() => {
                            if (isHighlightable() || viewTypeData === "ctms") {
                              getStudyRightSiteData(item.studyUniqueId);
                              setIsSectionVisible(true);
                            } else {
                              setIsSectionVisible(true);
                            }
                          }}
                        >
                          {item.timePointId}
                        </td>
                      )}
                      {hideColumn?.includes("Repeat Number") && (
                        <td
                          onClick={() => {
                            if (isHighlightable() || viewTypeData === "ctms") {
                              getStudyRightSiteData(item.studyUniqueId);
                              setIsSectionVisible(true);
                            } else {
                              setIsSectionVisible(true);
                            }
                          }}
                        >
                          {item.repeatNumber || 0}
                        </td>
                      )}
                      {hideColumn?.includes("Initiate") && (
                        <td
                          onClick={() => {
                            if (isHighlightable() || viewTypeData === "ctms") {
                              getStudyRightSiteData(item.studyUniqueId);
                              setIsSectionVisible(true);
                            } else {
                              setIsSectionVisible(true);
                            }
                          }}
                        >
                          {item?.initiate === "afterFirstVisits"
                            ? "After First Visit"
                            : item?.initiate === "anyTime"
                            ? "Any Time"
                            : item?.initiate}
                        </td>
                      )}
                      {hideColumn?.includes("Initiate Status") && (
                        <td
                          onClick={() => {
                            if (isHighlightable() || viewTypeData === "ctms") {
                              getStudyRightSiteData(item.studyUniqueId);
                              setIsSectionVisible(true);
                            } else {
                              setIsSectionVisible(true);
                            }
                          }}
                        >
                          {item?.initiated ? (
                            <div>Initiated</div>
                          ) : (
                            <div>Not Initiate</div>
                          )}
                        </td>
                      )}
                      {hideColumn?.includes("Status") && (
                        <td
                          onClick={() => {
                            if (isHighlightable() || viewTypeData === "ctms") {
                              getStudyRightSiteData(item.studyUniqueId);
                              setIsSectionVisible(true);
                            } else {
                              setIsSectionVisible(true);
                            }
                          }}
                        >
                          {item?.status ? "Completed" : "Ongoing"}
                        </td>
                      )}
                      {hideColumn?.includes("Initiation") && (
                        <td>
                          {item?.initiated ? (
                            <Button
                              onClick={(e) => {
                                handleClickData(
                                  e,
                                  item?.formUniqueId,
                                  item?.studyUniqueId,
                                  item?.visitIdLabel
                                );
                                addCrfViewTabs();
                              }}
                              style={{
                                fontSize: 10,
                                borderRadius: 3,
                                padding: "2px 8px",
                              }}
                              className="text-white border-success bg-success border"
                            >
                              View Initiated Event
                            </Button>
                          ) : (
                            <Button
                              disabled={!item?.formEnable}
                              onClick={(e) => {
                                handleClickData(
                                  e,
                                  item?.formUniqueId,
                                  item?.studyUniqueId,
                                  item?.visitIdLabel
                                );
                                addCrfViewTabs();
                              }}
                              style={{
                                fontSize: 10,
                                borderRadius: 3,
                                padding: "2px 8px",
                              }}
                              className={`${
                                item?.formEnable
                                  ? "text-muted border-warning bg-warning"
                                  : "text-muted border-secondary bg-light"
                              }`}
                            >
                              Initiate Event
                            </Button>
                          )}
                        </td>
                      )}
                      {hideColumn?.includes("Event Form") && (
                        <td>
                          <Button
                            disabled={!item?.initiated}
                            onClick={(e) => viewStudyVisitForms(item)}
                            style={{
                              fontSize: 10,
                              borderRadius: 3,
                              padding: "2px 8px",
                            }}
                            className={
                              item?.initiated
                                ? "text-white border-success bg-success border"
                                : "text-muted border-secondary bg-light"
                            }
                          >
                            View Event Form
                          </Button>
                        </td>
                      )}
                      {hideColumn?.includes("Query Status") &&
                        viewTypeData === "ctms" && (
                          <td
                            onClick={() => {
                              if (
                                isHighlightable() ||
                                viewTypeData === "ctms"
                              ) {
                                getStudyRightSiteData(item.studyUniqueId);
                                setIsSectionVisible(true);
                              } else {
                                setIsSectionVisible(true);
                              }
                            }}
                          >
                            {item?.queryStatus ? (
                              <div
                                className="text-capitalize"
                                style={{
                                  color: getStatusColor(item?.queryStatus),
                                }}
                              >
                                {item?.queryStatus}
                              </div>
                            ) : (
                              "No Query"
                            )}
                          </td>
                        )}
                      {hideColumn?.includes("Query Date | Time") &&
                        viewTypeData === "ctms" && (
                          <td
                            onClick={() => {
                              if (
                                isHighlightable() ||
                                viewTypeData === "ctms"
                              ) {
                                getStudyRightSiteData(item.studyUniqueId);
                                setIsSectionVisible(true);
                              } else {
                                setIsSectionVisible(true);
                              }
                            }}
                          >
                            {item?.queryStatus === "open" ? (
                              <Moment format="DD MMM YYYY | HH:mm" withTitle>
                                {item?.queryDateTime}
                              </Moment>
                            ) : (
                              "N/A"
                            )}
                          </td>
                        )}
                      {viewTypeData === "ctms" && StudyMode === "DV Mode" && (
                        <>
                          <td className="text-center">
                            <button
                              onClick={() =>
                                setIdReviewRowTable(item?.studyUniqueId)
                              }
                              className="text-white border-success bg-success border"
                              style={{
                                fontSize: 10,
                                borderRadius: 3,
                                padding: "2px 8px",
                              }}
                            >
                              Siganture
                            </button>
                          </td>
                          <td className="text-center">
                            <button
                              onClick={(e) =>
                                seIdGenerateQuery(item?.studyUniqueId)
                              }
                              className="text-white border-danger bg-danger border"
                              style={{
                                fontSize: 10,
                                borderRadius: 3,
                                padding: "2px 8px",
                              }}
                            >
                              Generate Query
                            </button>
                          </td>
                        </>
                      )}
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default StudyEventTableData;
